<template>
    <div :class="isMobile ? 'restaurant-session-mobile' : 'restaurant-session'" :style="'background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(\'' + modal.mainImage + '\') no-repeat; background-size: cover; background-position: center;'">
        <DefaultHeader/>
        <div v-if="!isMobile" :style="{height: isMobile ? '150px' : '200px'}"></div>
        <div :class="isMobile ? 'center-title-mobile' : 'center-title'">
            <transition appear name="fade"
                enter-active-class="fade-enter-active"
                enter-class="fade-enter-from"
                enter-to-class="fade-enter-to">
                <DefaultSession :topic_title="modal.sectionName" :title="modal.title">
                    <h6 class="home-subtitle" v-if="isMobile"> {{ modal.subtitle }} </h6>
                    <h4 class="home-subtitle" v-else-if="isMedium"> {{ modal.subtitle }} </h4>
                    <h3 class="home-subtitle" v-else> {{ modal.subtitle }} </h3>
                </DefaultSession>
            </transition>
        </div>
    </div>
</template>

<script>
import DefaultHeader from '@/components/shared/DefaultHeader.vue'
import DefaultSession from '@/components/shared/DefaultSession.vue'
export default {
    name: "RestaurantSession",
    components: {
        DefaultHeader,
        DefaultSession,
    },
    props: {
        modalProp: Object,
        didRequest: Boolean,
        loading: Boolean,
    },
    data(){
        return {
            isMobile: false,
            isMedium: false,
            modal: null,
        }
    },
    created(){
        this.modal=this.modalProp;
        const intervalId = setInterval(()=>{
            if(this.didRequest){
                clearInterval(intervalId);
                this.modal=this.modalProp;
            }
        }, 250)
        setInterval(() => {
            this.verifyMobile(); 
        }, 0);
        setInterval(() => {
            this.verifyMedium(); 
        }, 0);
    },
    methods: {
        verifyMobile(){
            this.isMobile = window.innerWidth <= 1000;
            return this.isMobile;
        },
        verifyMedium(){
            this.isMedium = (window.innerWidth < 1400) && (window.innerWidth > 1000);
            return this.isMedium;
        },
    }
}
</script>

<style scoped>
.restaurant-session{
    /* background-image: url(@/assets/images/restaurant/restaurants_background.png); */
    width: 100vw; 
    height: 100vh;
    min-height: 700px;
    background-size: cover;
    background-position: center;
    color: white;
}
.restaurant-session-mobile{
    /* background-image: url(@/assets/images/restaurant/restaurants_background.png); */
    width: 100vw; height: 400px;
    background-size: cover;
    background-position: center;
    color: white;
}
.center-title{
    /* height: 400px;  */
    height: 60%; 
    display:flex; 
    align-items:center;
}
.center-title-mobile{
    height: 55%; 
    display:flex; 
    align-items:center;
}
</style>