<template>
    <b-container fluid="xl" class="m-auto container">
        <div :class="isMobile ? 'content-mobile' : 'content'">
            
            <b-row class="font-nunito">
                <h6 class="topic-title mx-auto" v-if="isMobile"> {{ topic_title }} </h6>
                <h4 class="topic-title mx-auto" v-else> {{ topic_title }} </h4>
            </b-row>

            <b-row class="font-alice">
                <h1 class="display-6 mx-auto" style="font-size:30px; font-weight:400" v-if="isMobile"> {{ title }} </h1>
                <h1 class="display-3 mx-auto" style="font-weight:400" v-else-if="isMedium"> {{ title }} </h1>
                <h1 class="display-1 mx-auto" style="font-weight:400" v-else> {{ title }} </h1>
            </b-row>
            
            <b-container :class="isMobile ? 'slot-mobile' : 'slot'">
                <slot></slot>
            </b-container>

        </div>
    </b-container>
</template>


<script>
export default {
    name: "DefaultSession",
    props: {
        topic_title: String,
        title: String,
    },
    data(){
        return {
            isMobile: false,
            isMedium: false,
        }
    },
    created(){
        setInterval(() => {
            this.verifyMobile(); 
        }, 0);
        setInterval(() => {
            this.verifyMedium(); 
        }, 0);
    },
    methods: {
        verifyMobile(){
            this.isMobile = window.innerWidth <= 1000;
            return this.isMobile;
        },
        verifyMedium(){
            this.isMedium = (window.innerWidth < 1400) && (window.innerWidth > 1000);
            return this.isMedium;
        }
    },
    
}
</script>


<style scoped>
.container{
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.content-mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.topic-title{
    border-bottom: 3px solid white;
    font-weight: 300;
}
.title{
    margin: 0px;
}
.slot{
    padding: 15px 0px;
}
.slot-mobile{
    padding: 15px 0px;
    /* min-height: 100px; */
}
</style>
