<template>
    <div>
        <!-- MODAL DO CARDÁPIO -->
        <b-modal v-if="!isMobile" class="menu-card-modal" body-class="p-0" modal-class="modal-class" title-class="modal-title-class" centered size="xl" ref="menuItemModal" hide-footer hide-header>
            <b-row class="font-nunito">
                <b-col cols="7" :style="isMedium ? 'width:50%' : 'width:60%'">
                    <div class="image-col" :style="'background-image: url(\''+ image +'\');'">
                        <b-row class="mx-auto space-between-row px-3">
                            <p :class="isMobile ? 'h4' : 'h2'">
                                <b-icon icon="arrow-left-circle" class="modal-arrow-button" @click="previousItemModal" :style="canPassLeftModal ? '' : 'opacity:0; cursor:default;'"></b-icon>
                            </p>
                            <!-- <p :class="isMobile ? 'h4' : 'h2'">
                                <b-icon icon="arrow-right-circle" class="modal-arrow-button" @click="nextItemModal" :style="canPassRightModal ? '' : 'opacity:0; cursor:default;'"></b-icon>
                            </p> -->
                        </b-row>
                    </div>
                </b-col>
                <b-col class="mx-4 space-between-col">
                    <b-col>
                        <!-- CLOSE BUTTON -->
                        <b-row class="align-end-row" style="margin: 30px 0px 0px 0px;" :style="isMedium ? 'width:150%;' : 'width:125%;'">
                            <p class="h3">
                                <b-icon @click="hideModal" icon="x-circle" class="modal-close-button"></b-icon>
                            </p>
                        </b-row>

                        <!-- ITEM INFOS -->
                        <b-row>
                            <b-col>
                                <b-row> <h2 style="font-weight:bold; color:#520000;" class="font-alice">  {{ name }}  </h2></b-row>
                                <b-row> {{ description }} </b-row>
                                <b-row>
                                    <img class="menu-icon tag-image-bg" style="width:30px; height:30px;" :src="e.image" alt="" v-for="(e, idx) in listTags" :key="idx">
                                </b-row>
                            </b-col>
                        </b-row>

                        <!-- VALORES -->
                        <b-row class="my-4">
                            <b-col>
                                <b-row> <h4 style="font-weight:bold"> Valores </h4> </b-row>
                                <b-row class="modal-price-row my-1" v-for="(item, idx) in prices" :key="idx">
                                    <h5> {{ item.name }} </h5>
                                    <!-- <h5> Broto </h5> -->
                                    <h5 style="font-weight:bold"> R$ {{ (item.value/100).toFixed(2).replace(".", ",") }} </h5>
                                    <!-- <h5 style="font-weight:bold">  R$  </h5> -->
                                </b-row>
                            </b-col>
                        </b-row>
                        
                        <!-- DETALHES -->
                        <b-row v-if="menuItem.details">
                            <b-col>
                                <b-row> <h4 style="font-weight:bold">  Detalhes  </h4> </b-row>
                                <b-row style="color: #5C5F60"> {{ details || '---' }} </b-row>
                            </b-col>
                        </b-row>
                    </b-col>

                    <!-- FOOTER -->
                    <b-row class="center-row py-4">
                        {{ getHourString() }}
                        <!-- Horário da pizzaria de 18:00 às 22:00 -->
                    </b-row>

                </b-col>
                <b-col cols="0" align-self="center" class="mr-4">
                    <p :class="isMobile ? 'h4' : 'h2'">
                        <b-icon icon="arrow-right-circle" class="modal-arrow-button" @click="nextItemModal" :style="canPassRightModal ? '' : 'opacity:0; cursor:default;'"></b-icon>
                    </p>
                </b-col>
            </b-row>
        </b-modal>


        <b-modal v-else class="menu-card-modal" modal-class="modal-class" title-class="modal-title-class" centered size="md" ref="menuItemModal" hide-footer hide-header>
            <b-col class="font-nunito">
                <!-- CLOSE BUTTON -->
                <b-row class="align-end-row">
                    <p class="h3">
                        <b-icon @click="hideModal" icon="x-circle" class="modal-close-button"></b-icon>
                    </p>
                </b-row>
                <b-row>
                    <div :style="'background-image: url(\''+ image +'\');'" class="image-col-mobile mt-3">
                        <b-row class="mx-auto space-between-row-end" style="height:100%;">
                            <p :class="isMobile ? 'h4' : 'h2'">
                                <b-icon icon="arrow-left-circle" class="modal-arrow-button" @click="previousItemModal" :style="canPassLeftModal ? '' : 'opacity:0; cursor:default;'"></b-icon>
                            </p>
                            <p :class="isMobile ? 'h4' : 'h2'">
                                <b-icon icon="arrow-right-circle" class="modal-arrow-button" @click="nextItemModal" :style="canPassRightModal ? '' : 'opacity:0; cursor:default;'"></b-icon>
                            </p>
                        </b-row>
                    </div>
                </b-row>
                <b-row class="mx-1 space-between-col" style="margin-top: 15px;">
                    <b-col>

                        <!-- ITEM INFOS -->
                        <b-row>
                            <b-col>
                                <b-row> <h3 style="font-weight:bold; color:#520000" class="font-alice">  {{ name }}  </h3></b-row>
                                <b-row class="modal-text-mobile"> {{ description }} </b-row>
                                <b-row>
                                    <img class="menu-icon tag-image-bg" style="width:30px; height:30px;" :src="e.image" alt="" v-for="(e, idx) in listTags" :key="idx">
                                </b-row>
                            </b-col>
                        </b-row>

                        <!-- VALORES -->
                        <b-row class="my-4">
                            <b-col>
                                <b-row> <h5 style="font-weight:bold"> Valores </h5> </b-row>
                                <b-row class="modal-price-row-mobile my-1" v-for="(item, idx) in prices" :key="idx">
                                    <h6> {{ item.name }} </h6>
                                    <!-- <h6> Broto </h6> -->
                                    <h6 style="font-weight:bold"> R$ {{ (item.value/100).toFixed(2).replace(".", ",") }} </h6>
                                    <!-- <h6 style="font-weight:bold"> R$  </h6> -->
                                </b-row>
                            </b-col>
                        </b-row>
                        
                        <!-- DETALHES -->
                        <b-row>
                            <b-col>
                                <b-row> <h5 style="font-weight:bold">  Detalhes  </h5> </b-row>
                                <b-row class="modal-text-mobile" style="color:#5C5F60"> {{ details || '---' }} </b-row>
                            </b-col>
                        </b-row>
                    </b-col>

                    <!-- FOOTER -->
                    <b-row class="center-row py-4" style="text-align: center;">
                        {{ getHourString() }}
                        <!-- Horário da pizzaria de 18:00 às 22:00 -->
                    </b-row>

                </b-row>
                
            </b-col>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'MenuItemModal',
    props:{
        idx: Number,
        isMobile: Boolean,
        isMedium: Boolean,
        canPassLeftModal: Boolean,
        canPassRightModal: Boolean,
        image: String,
        name: String,
        description: String,
        listTags: Array,
        prices: Array,
        details: String,
    },
    computed: {
        ...mapState("restaurant", {
            restaurantState: (state) => state.restaurant
        }),
    },
    methods:{
        showModal(){
            this.$refs.menuItemModal.show();
        },
        hideModal(){
            this.$refs.menuItemModal.hide();
        },
        previousItemModal(){
            this.$emit('previous', this.idx);
        },
        nextItemModal(){
            this.$emit('next', this.idx);
        },
        getHourString(){
            var daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'HOLIDAY'];
            var now = new Date;
            var dayWeek = daysOfWeek[now.getDay()];
            var todayList = [];
            if(this.restaurantState.times==null || this.restaurantState.times.length==0) return;
            
            this.restaurantState.times.forEach(time => {
                if(time.name.toLowerCase()=="jantar"){
                    if(time.sequential){
                        var tempFirstDay = time.firstDay;
                        var tempLastDay = time.lastDay.replace('/HOLIDAY', '');
                        var first = daysOfWeek.indexOf(tempFirstDay);
                        var last = daysOfWeek.indexOf(tempLastDay);
                        if((now.getDay() >= first && now.getDay() <= last) || (first>last)){
                            todayList.push(time);
                        }
                    }
                    else {
                        var firstDays = time.firstDay.split(', ');
                        var lastDay = time.lastDay.replace('/HOLIDAY', '');
                        firstDays.forEach(fd => {
                            if(fd == dayWeek){
                                todayList.push(time);
                            }
                        })
                        if(lastDay == dayWeek){
                            todayList.push(time);
                        }
                    }
                }
            });

            // var str = "";

            // todayList.forEach(today => {
            //     if(today.name.toLowerCase() == "jantar"){
            //         if(today.closed){
            //             str = "Fechado hoje";
            //         }
            //         else{
            //             str = "Horário de jantar das " + today.opensAt + " às " + today.closesAt;
            //         }
            //     }else{
            //         str = "Fechado hoje";
            //     }
            // })

            var str = "Fechado hoje";

            todayList.forEach(today => {
                if(today.name.toLowerCase() == "jantar"){
                    if(!today.closed){
                        str = "Horário de jantar das " + today.opensAt + " às " + today.closesAt;
                    }
                }
            })

            this.hourString = str;
            // return str;
        }
    },
}
</script>

<style scoped>

.menu-icon{
    width: 23px;
    height: 23px;
    margin: 5px;
    object-fit: contain;
    border-radius: 5px;
}

.center-row{
    display: flex;
    justify-content: center;
    align-items: center;
}
.space-between-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
}
.space-between-col{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.modal-arrow-button{
    transition: 0.25s;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    width: 50px;
    height: 50px;
    padding: 10px;
    color: white;
}
.modal-arrow-button:hover{
    background-color: rgba(0, 0, 0, 1);
    cursor: pointer;
}
.image-col{
    width: 100%;
    min-height: 600px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    scale: 1.0025;
}
.image-col-mobile{
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 20px;
}
.align-end-row{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.modal-close-button{
    cursor: pointer;
    transition: 0.25s;    
}
.modal-close-button:hover{
    opacity: 0.5;
}
.modal-price-row{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.modal-price-row-mobile{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.modal-text-mobile{
    font-size: 14px;
}
.space-between-row-end{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 95%;
}
.black-image{
    filter: invert(100%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(103%) contrast(100%);
}
</style>