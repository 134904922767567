<template>
  <b-col :class="isMobile ? 'lunch-session-mobile' : 'lunch-session'" :style="imageProp.length!=3 ? 'min-height:0px !important;' : ''">

    <div>
        <b-row class="font-nunito">
            <h6 v-if="isMobile" class="topic-title mx-auto">Almoço</h6>
            <h4 v-else class="topic-title mx-auto">Almoço</h4>
        </b-row>

        <b-row class="font-alice">
            <h1 class="display-6 subtitle mx-auto" style="font-size:20px" v-if="isMobile"> {{ hourString }} </h1>
            <h1 class="display-6 subtitle mx-auto" style="font-size:30px" v-else> {{ hourString }} </h1>
            <!-- <h1 class="display-6 subtitle mx-auto" style="font-size:20px" v-if="isMobile"> Horário do almoço das 11:00 às 15:00 </h1>
            <h1 class="display-6 subtitle mx-auto" style="font-size:30px" v-else> Horário do almoço das 11:00 às 15:00 </h1> -->
        </b-row>
    </div>

    <transition name="opacity"
                    enter-active-class="opacity-enter-active"
                    enter-class="opacity-enter-from"

                    leave-active-class="opacity-leave-active"
                    leave-to-class="opacity-leave-to"
                    >
    <b-row v-show="!isPassing && imageProp.length==3" class="roulette mx-auto font-nunito" :style="isMobile ? 'align-items:flex-start;' : 'align-items:center'">
        <!-- <b-col class="btn-col">
            <p :class="isMobile ? 'h3' : 'h1'">
                <b-icon @click="previousItem" icon="arrow-left-circle" class="arrow-button"></b-icon>
            </p>
        </b-col> -->
        <b-col class="roulette-item">
            <b-row v-if="isMobile"><img class="roulette-item-image-mobile mx-auto" :src="imageProp.find(e=>e.lunchImagePosition=='LEFT')?.imageUrl" alt=""></b-row>
            <b-row v-else><img class="roulette-item-image mx-auto" :src="imageProp.find(e=>e.lunchImagePosition=='LEFT')?.imageUrl" alt=""></b-row>
            <b-row v-if="isMobile" class="roulette-item-title-mobile mx-auto"> {{ imageProp.find(e=>e.lunchImagePosition=='LEFT')?.description }} </b-row>
            <b-row v-else class="roulette-item-title mx-auto"> {{ imageProp.find(e=>e.lunchImagePosition=='LEFT')?.description }} </b-row>
        </b-col>
        <b-col class="roulette-item">
            <b-row v-if="isMobile"><img class="roulette-item-image-center-mobile mx-auto" :src="imageProp.find(e=>e.lunchImagePosition=='CENTER')?.imageUrl" alt=""></b-row>
            <b-row v-else><img class="roulette-item-image-center mx-auto" :src="imageProp.find(e=>e.lunchImagePosition=='CENTER')?.imageUrl" alt=""></b-row>
            <b-row v-if="isMobile" class="roulette-item-title-mobile mx-auto"> {{ imageProp.find(e=>e.lunchImagePosition=='CENTER')?.description }} </b-row>
            <b-row v-else class="roulette-item-title mx-auto"> {{ imageProp.find(e=>e.lunchImagePosition=='CENTER')?.description }} </b-row>
        </b-col>
        <b-col class="roulette-item">
            <b-row v-if="isMobile"><img class="roulette-item-image-mobile mx-auto" :src="imageProp.find(e=>e.lunchImagePosition=='RIGHT')?.imageUrl" alt=""></b-row>
            <b-row v-else><img class="roulette-item-image mx-auto" :src="imageProp.find(e=>e.lunchImagePosition=='RIGHT')?.imageUrl" alt=""></b-row>
            <b-row v-if="isMobile" class="roulette-item-title-mobile mx-auto"> {{ imageProp.find(e=>e.lunchImagePosition=='RIGHT')?.description }} </b-row>
            <b-row v-else class="roulette-item-title mx-auto"> {{ imageProp.find(e=>e.lunchImagePosition=='RIGHT')?.description }} </b-row>
        </b-col>
        <!-- <b-col class="btn-col">
            <p :class="isMobile ? 'h3' : 'h1'">
                <b-icon @click="nextItem" icon="arrow-right-circle" class="arrow-button"></b-icon>
            </p>
        </b-col> -->
    </b-row>
    </transition>

    <div class="font-nunito">
        <b-row :class="(isMobile ? 'price-information-item-mobile' : 'price-information-item') + ' mx-auto mt-2'">
            <b-col style="text-align: start; min-width:55vw">
                • {{ this.modalOne.name }}: {{ this.modalOne.weekDays }}
            </b-col>
            <b-col style="text-align: end;">
                R$ {{ this.modalOne.pricePerKg }}/Kg
            </b-col>
        </b-row>
        <b-row :class="(isMobile ? 'price-information-item-mobile' : 'price-information-item') + ' mx-auto mt-2'">
            <b-col style="text-align: start; min-width:55vw">
                • {{ this.modalTwo.name }}: {{ this.modalTwo.weekDays }}
            </b-col>
            <b-col style="text-align: end;">
                R$ {{ this.modalTwo.pricePerKg }}/Kg
            </b-col>
        </b-row>
        <b-row :class="(isMobile ? 'price-information-item-mobile' : 'price-information-item') + ' mx-auto mt-2'">
            <b-col style="text-align: start; min-width:55vw">
                • {{ this.modalThree.name }}: {{ this.modalThree.weekDays }}
            </b-col>
            <b-col style="text-align: end;">
                R$ {{ this.modalThree.pricePerKg }}/Kg
            </b-col>
        </b-row>
    </div>
  </b-col>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: "LunchSession",
    props: {
        modalProp: Array,
        imageProp: Array,
        didRequest: Boolean,
        loading: Boolean,
    },
    data(){
        return {
            isMobile: false,
            isMedium: false,
            index: 0,
            modalOne: {
                name: '',
                weekDays: '',
                pricePerKg: '',
            },
            modalTwo: {
                name: '',
                weekDays: '',
                pricePerKg: '',
            },
            modalThree: {
                name: '',
                weekDays: '',
                pricePerKg: '',
            },
            isPassing: false,
            hourString: "",
            stepIndex: 3,
            stepList: [],
            topics:[
                {
                    image: require("@/assets/images/menu/lunch/types_meat_card.png"),
                    name: "Vários tipos de carne",
                },
                {
                    image: require("@/assets/images/menu/lunch/hot_food.png"),
                    name: "Pratos quentes",
                },
                {
                    image: require("@/assets/images/menu/lunch/varied_salads.png"),
                    name: "Saladas variadas",
                },
                {
                    image: require("@/assets/images/menu/lunch/varied_salads.png"),
                    name: "Saladas variadas",
                },
                {
                    image: require("@/assets/images/menu/lunch/hot_food.png"),
                    name: "Pratos quentes",
                },
                {
                    image: require("@/assets/images/menu/lunch/types_meat_card.png"),
                    name: "Vários tipos de carne",
                },
            ]
        }
    },
    created(){
        // this.modal = this.modalProp;
        const intervalId = setInterval(()=>{
            if(this.hourString!=null && this.didRequest){
                clearInterval(intervalId);
                this.getHourString();
                // this.modal = this.modalProp;
                this.modalOne = this.modalProp[0];
                var lengthOne = String(this.modalOne.pricePerKg).length;
                this.modalOne.pricePerKg = String(this.modalOne.pricePerKg).substring(0, lengthOne-2) + ',' + String(this.modalOne.pricePerKg).substring(lengthOne-2, lengthOne)
                
                this.modalTwo = this.modalProp[1];
                var lengthTwo = String(this.modalTwo.pricePerKg).length;
                this.modalTwo.pricePerKg = String(this.modalTwo.pricePerKg).substring(0, lengthTwo-2) + ',' + String(this.modalTwo.pricePerKg).substring(lengthTwo-2, lengthTwo)
                
                this.modalThree = this.modalProp[2];
                var lengthThree = String(this.modalThree.pricePerKg).length;
                this.modalThree.pricePerKg = String(this.modalThree.pricePerKg).substring(0, lengthThree-2) + ',' + String(this.modalThree.pricePerKg).substring(lengthThree-2, lengthThree)
                // this.modal.forEach(e=>{
                //     var length = String(e.pricePerKg).length;
                //     e.pricePerKg = String(e.pricePerKg).substring(0, length-2) + ',' + String(e.pricePerKg).substring(length-2, length)
                // })
            }
        }, 250);

        setInterval(() => {
            this.verifyMobile(); 
        }, 0);
        setInterval(() => {
            this.verifyMedium(); 
        }, 0);
        this.stepList = this.topics.slice(0,3);
    },
    computed: {
        ...mapState("restaurant", {
            restaurantState: (state) => state.restaurant
        }),
    },
    methods: {
        verifyMobile(){
            this.isMobile = window.innerWidth <= 1000;
            return this.isMobile;
        },
        verifyMedium(){
            this.isMedium = (window.innerWidth < 1400) && (window.innerWidth > 1000);
            return this.isMedium;
        },
        nextItem(){
            this.isPassing=true;
            setTimeout(() => {
                if(this.index+this.stepIndex >= this.topics.length)
                    this.index = 0;
                else
                    this.index+=this.stepIndex;
                this.stepList = this.topics.slice(this.index, this.index+this.stepIndex);
                this.isPassing=false;
            }, 250);
        },
        previousItem(){
            this.isPassing=true;
            setTimeout(() => {
                if(this.index-this.stepIndex < 0) 
                    this.index = this.topics.length-this.stepIndex;
                else this.index-=this.stepIndex;
                this.stepList = this.topics.slice(this.index, this.index+this.stepIndex);
                
                this.isPassing=false;
            }, 250);
        },
        getHourString(){
            var daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'HOLIDAY'];
            var now = new Date;
            var dayWeek = daysOfWeek[now.getDay()];
            
            var todayList = [];
            
            if(this.restaurantState.times==null || this.restaurantState.times.length==0) return;
            
            this.restaurantState.times.forEach(time => {
                if(time.name.toLowerCase()=="almoço"){
                    if(time.sequential){
                        var tempFirstDay = time.firstDay;
                        var tempLastDay = time.lastDay.replace('/HOLIDAY', '');
                        var first = daysOfWeek.indexOf(tempFirstDay);
                        var last = daysOfWeek.indexOf(tempLastDay);
                        if((now.getDay() >= first && now.getDay() <= last) || (first>last)){
                            todayList.push(time);
                        }
                    } 
                    else{
                        var firstDays = time.firstDay.split(', ');
                        var lastDay = time.lastDay.replace('/HOLIDAY', '');
                        firstDays.forEach(fd => {
                            if(fd == dayWeek){
                                todayList.push(time);
                            }
                        })
                        if(lastDay == dayWeek){
                            todayList.push(time);
                        }
                    }
                }
            });

            // var str = "";

            // todayList.forEach(today => {
            //     if(today.name.toLowerCase() == "almoço"){
            //         if(today.closed){
            //             str = "Fechado hoje";
            //         }
            //         else{
            //             str = "Horário de almoço das " + today.opensAt + " às " + today.closesAt;
            //         }
            //     }else{
            //         str = "Fechado hoje";
            //     }
            // })

            var str = "Fechado hoje";

            todayList.forEach(today => {
                if(today.name.toLowerCase() == "almoço"){
                    if(!today.closed){
                        str = "Horário de almoço das " + today.opensAt + " às " + today.closesAt;
                    }
                }
            })

            this.hourString = str;
            // return str;
        }
    }
}
</script>

<style scoped>
.opacity-enter-from{
  opacity: 0;
}
.opacity-enter-active{
  transition: all .25s ease;
}
.opacity-leave-to{
  opacity: 0;
}
.opacity-leave-active{
  transition: all .25s ease-in-out;
}

.lunch-session{
    background-color: #EEEEEE;
    width: 100vw; 
    /* height: 100vh; */
    min-height: 700px;
    padding: 50px;
    color: #2E3132;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.lunch-session-mobile{
    background-color: #EEEEEE;
    width: 100vw; min-height: 400px;
    padding: 20px;
    color: #2E3132;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.content-row{
    display: flex;
    justify-content: center;
}
.topic-title{
    font-weight: 300;
    border-bottom: 3px solid #2E3132;
}
.subtitle{
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
}
.roulette{
    display: flex;
}
.roulette-item{
    width: 32vw;
    display: flex;
    flex-direction: column;
    justify-items: center;
}
.roulette-item-image{
    width: 100%;
    height: 225px;
    max-width: 18vw;
    min-width: 225px;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 5px 5px 10px #CCCCCC;
}
.roulette-item-image-mobile{
    width: 80%;
    min-height: 80px;
    height: 90px;
    max-width: 150px;
    min-width: 80px;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 5px 5px 10px #CCCCCC;
}
.roulette-item-image-center{
    width: 100%;
    height: 245px;
    max-width: 18vw;
    min-width: 225px;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 5px 5px 10px #CCCCCC;
}
.roulette-item-image-center-mobile{
    width: 90%;
    min-height: 80px;
    height: 100px;
    max-width: 150px;
    min-width: 80px;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 5px 5px 10px #CCCCCC;
}
.roulette-item-title{
    font-size: 22px;
    font-weight: 600;
    margin: 10px 0px;
}
.roulette-item-title-mobile{
    font-size: 12px;
    font-weight: bold;
    line-height: 12px;
    margin: 10px 0px;
}
.btn-col{
    max-width:50px;
}
.price-information-item{
    border-bottom: 1px solid #c1cacf;
    width: 80vw;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.price-information-item-mobile{
    border-bottom: 1px solid #c1cacf;
    width: 90vw;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>