<template>
  <div class="food-item" @click="click(item)" :key="item.id">
    <b-row>
        <b-col v-if="!item.additional">
            <b-overlay :show="isLoading" bg-color="transparent" style="z-index: 1 !important;">
                <img ref="imageElement" @load="onImageLoad" class="image" :src="item.image" :alt="item.name">
            </b-overlay>
        </b-col>
        <b-col :class="item.additional ? 'ml-5' : ''" :cols="item.additional ? '7' : '4'">
            <b-row class="align-start-row">
                <h4 class="name font-alice"> {{ item.name }} </h4>
            </b-row>
            <b-row style="min-height:60px; max-height:60px">
                <p class="ingredients font-nunito"> {{ item.description }} </p>
            </b-row>
            <b-row>
                <img class="menu-icon" :src="e.image" v-for="(e, idx) in item.listTags" :key="idx" :title="e.name" :alt="e.name">
                <!-- <img class="menu-icon" src="@/assets/images/menu/dinner/icons/sem_gluten_icon.png" alt=""> -->
            </b-row>
        </b-col>
        <b-col>
            <b-row style="display:flex; width:100%; justify-content:flex-end;">
                <!-- <div style="display:flex; width:100%; justify-content:flex-end;"> -->
                    <b-col style="max-width:60%" v-for="(e, idx) in item.prices" :key="idx">
                        <b-row style="display:flex; justify-content:center;">
                            <h5 class="price-title mx-auto font-alice"> {{ e.name }} </h5>
                        </b-row>
                        <b-row style="display:flex; justify-content:center;">
                            <h6 style="min-width:70px" class="price mx-auto font-nunito"><b> R$ {{ (e.value/100).toFixed(2).replace(".", ",") }} </b></h6>
                        </b-row>
                    </b-col>
                <!-- </div> -->
            </b-row>
        </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
    name: "FoodItem",
    props: {
        item: {},
        click: Function,
    },
    mounted() {
        this.observeImage();
    },
    data(){
        return {
            isLoading: true,
            observer: null,
        }
    },
    methods: {
        onImageLoad() {
            setTimeout(()=>{
                this.isLoading = false;
            }, 250);
        },
        observeImage() {
            if ("IntersectionObserver" in window) {
                this.observer = new IntersectionObserver((entries) => {
                    const entry = entries[0];
                    if (entry.isIntersecting) {
                        setTimeout(()=>{
                            this.isLoading = false;
                            this.observer.disconnect();
                        }, 250);
                    }
                });

                this.observer.observe(this.$refs.imageElement);
            }
        },
    },
    destroyed() {
        if (this.observer) {
            this.observer.disconnect();
        }
    },
}
</script>

<style scoped>
.food-item{
    transition: 0.25s;
    margin-top: 20px;
    margin-bottom: 30px;
    min-height: 125px;
    max-height: 125px;
}
.food-item:hover{
    transform: translateX(10px);
    color: #FFB000;
    cursor: pointer;
}
.align-start-row{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.centered-y{
    display: flex;
    align-items: center;
}
.centered-y-col{
    border:1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.image{
    max-height: 130px;
    max-width: 130px;
    min-height: 130px;
    min-width: 130px;
    border-radius: 10px;
    object-fit: contain;
}
.name{
    text-align: start;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
            line-clamp: 2; 
    -webkit-box-orient: vertical;
    font-weight: 400;
}
.ingredients{
    font-size: 13px;
    text-align: start;
    font-weight: 300;
    margin: 0px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
            line-clamp: 3; 
    -webkit-box-orient: vertical;
}
.menu-icon{
    width: 23px;
    height: 23px;
    border-radius: 5px;
    margin: 5px;
    object-fit: contain;
}
.price-title{
    font-size: 18px;
    text-align: center;
    font-weight: 400;
}
.price{
    font-size: 16px;
    text-align: center;
    font-weight: 400;
}
</style>