import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/HomeView.vue'
import MenuView from '@/views/menu/MenuView.vue'
import RestaurantView from '@/views/restaurant/RestaurantView.vue'
import AboutView from '@/views/about/AboutView.vue'
import ContactView from '@/views/contact/ContactView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  }, { path: '/home', name: 'home', component: HomeView },
  {
    path: '/cardapio',
    name: 'menu',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/menu/MenuView.vue')
    component: MenuView
  },
  {
    path: '/restaurante',
    name: 'restaurante',
    component: RestaurantView
  },
  {
    path: '/sobre',
    name: 'sobre',
    component: AboutView
  },
  {
    path: '/contato',
    name: 'contato',
    component: ContactView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
