<template>
  <div class="food-item pt-2 pb-4" @click="click(item)" :style="item.additional ? 'min-height: 100px; max-height: 150px;' : ''">
    <b-row>
        <b-col v-if="!item.additional">
            <b-overlay :show="isLoading" bg-color="transparent" style="z-index: 1 !important;">
                <img ref="imageElement" @load="onImageLoad" class="image" :src="item.image" :alt="item.name">
            </b-overlay>
        </b-col>
        <b-col :class="item.additional ? 'ml-5' : ''" :cols="item.additional ? '7' : '4'">
            <b-row class="align-start-row">
                <h5 class="name font-alice"> {{ item.name }} </h5>
            </b-row>
            <b-row v-if="!item.additional" style="min-height:60px; max-height:60px;" class="align-start-row">
                <p class="ingredients font-nunito"> {{ item.description }} </p>
            </b-row>
            <b-row v-if="!item.additional" class="align-start-row">
                <img class="menu-icon" :src="e.image" v-for="(e, idx) in item.listTags" :key="idx" :title="e.name" :alt="e.name">
                <!-- <img class="menu-icon" src="@/assets/images/menu/dinner/icons/sem_gluten_icon.png" alt=""> -->
            </b-row>
        </b-col>
        <b-col v-if="!item.additional">
            <div class="py-1" v-for="(e, idx) in item.prices" :key="idx">
                <b-row>
                    <h6 class="price-title mx-auto font-alice"> {{ e.name }} </h6>
                </b-row>
                <b-row>
                    <h6 class="price mx-auto font-nunito"><b> R$ {{ (e.value/100).toFixed(2).replace(".", ",") }} </b></h6>
                </b-row>
            </div>
        </b-col>
        <b-col v-else style="min-width:150px">
            <b-row>
                <b-col class="py-1" v-for="(e, idx) in item.prices" :key="idx">
                    <b-row>
                        <h6 class="price-title mx-auto font-alice"> {{ e.name }} </h6>
                    </b-row>
                    <b-row>
                        <h6 class="price mx-auto font-nunito"><b> R$ {{ (e.value/100).toFixed(2).replace(".", ",") }} </b></h6>
                    </b-row>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
    name: "FoodItemMobile",
    props: {
        item: {},
        click: Function,
    },
    mounted() {
        this.observeImage();
    },
    data(){
        return {
            isLoading: true,
            observer: null,
        }
    },
    methods: {
        onImageLoad() {
            setTimeout(()=>{
                this.isLoading = false;
            }, 250);
        },
        observeImage() {
            if ("IntersectionObserver" in window) {
                this.observer = new IntersectionObserver((entries) => {
                    const entry = entries[0];
                    if (entry.isIntersecting) {
                        setTimeout(()=>{
                            this.isLoading = false;
                            this.observer.disconnect();
                        }, 250);
                    }
                });

                this.observer.observe(this.$refs.imageElement);
            }
        },
    },
    destroyed() {
        if (this.observer) {
            this.observer.disconnect();
        }
    },
}
</script>

<style scoped>
.food-item{
    transition: 0.25s;
    margin: 20px 0px;
    min-height: 150px;
    
}
.food-item:hover{
    transform: translateX(10px);
    color: #FFB000;
    cursor: pointer;
}
.centered-y{
    display: flex;
    align-items: center;
}
.centered-y-col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.align-start-row{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.align-center-row{
    display: flex;
    justify-content: center;
    align-items: center;
}
.image{
    max-height: 80px;
    max-width: 80px;
    min-height: 80px;
    min-width: 80px;
    border-radius: 10px;
    object-fit: contain;
}
.name{
    text-align: start;
}
.ingredients{
    /* font-size: 12px;
    text-align: start;
    margin: 0px; */
    font-size: 13px;
    text-align: start;
    font-weight: 300;
    margin: 0px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
            line-clamp: 3; 
    -webkit-box-orient: vertical;
}
.menu-icon{
    width: 23px;
    height: 23px;
    margin: 5px;
    object-fit: contain;
    border-radius: 5px;
}
.price-title{
    text-align: center;
    font-size:17px;
}
.price{
    text-align: center;
    font-size:16px;
}
</style>