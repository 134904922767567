<template>
    <div class="restaurant">
        
        <MaintenanceModal ref="maintenance"/>
        
        <LoadingView v-show="loadingState"/>

        <RestaurantSession v-if="showFirst" :modalProp="firstSection" :loading="loading" :didRequest="didRequest" />

        <ListRestaurantSession v-if="showRestaurants" @hideSession="hideListRestaurants"/>

        <DefaultFooter/>
    </div>
</template>

<script>
import RestaurantSession from '@/views/restaurant/sessions/RestaurantSession.vue'
import ListRestaurantSession from './sessions/ListRestaurantSession.vue'
import DefaultFooter from '@/components/shared/DefaultFooter.vue'
import LoadingView from '@/components/shared/LoadingView.vue'
import MaintenanceModal from '@/components/shared/MaintenanceModal.vue'
import { mapState } from 'vuex'

export default {
    name: "RestaurantView.vue",
    components:{
        RestaurantSession,
        ListRestaurantSession,
        DefaultFooter,
        LoadingView,
        MaintenanceModal,
    },
    computed: {
        ...mapState("loading", {
            loadingState: (state) => state.loading
        }),
    },
    async mounted(){
        try{
            this.$store.commit("loading/STORE_LOADING");
            window.scrollTop=0;
            document.body.style.overflow = 'hidden'
            await this.getMainSection();
            await this.getSection();
            this.verifyMaintenance();
            this.showFirst=true;
            setTimeout(()=>{
                document.body.style.overflow = ''
                this.$store.commit("loading/STORE_LOADED");
            }, 500)
        }
        catch(error){
            this.$refs.maintenance.show();
            error;
        }
    },
    data(){
        return {
            showFirst: false,
            showRestaurants: true,
            sectionId: null,
            modalSections: null,
            firstSection: {
                mainImage: '',
                sectionName: '',
                title: '',
                subtitle: '',
                active: false,
            },
            loading: false,
            didRequest: false,
        }
    },
    methods: {
        verifyMaintenance(){
            if(
                !this.firstSection
            ) throw 'First section not found';
        },
        hideListRestaurants(){this.showRestaurants=false},
        async getMainSection(){
            this.loading=true;
            await this.$axios.get("main_sections")
            .then(response => {
                this.sectionId = response.data.data.find(e=>e.name=="Restaurantes").id;
            }).finally(()=>{
                this.loading=false;
            })
        },
        async getSection(){
            await this.$axios.get("main_section/" + this.sectionId + "/sub_section")
            .then(response => {
                this.modalSections = response.data.data;
                this.firstSection = this.modalSections.find(e=>e.type=="HEADER");
            }).finally(()=>{
                this.loading=false;
                this.didRequest=true;
            });
        },
    },
}
</script>

<style scoped>
    
</style>