<template>
    <div class="session" :style="isMobile ? 'padding: 20px;' : 'padding: 50px;'">
        <b-col class="font-nunito">
            <b-row :class="!isMobile && !isMedium  ? 'content-row' : 'content-row-mobile'">
                <b-col class="info-col">
                    <b-row :class="(!isMobile && !isMedium  ? 'about-title font-alice' : 'about-title-mobile font-alice') + ' pb-4 '">
                        {{ modal.title }}
                    </b-row>
                    <b-row style="white-space: pre-wrap;" :class="!isMobile && !isMedium  ? 'about-text-one' : 'about-text-one-mobile'">{{ modal.subtitle }}</b-row>
                    <!-- <b-row :class="!isMobile && !isMedium  ? 'about-text-one' : 'about-text-one-mobile'">
                        Um restaurante moderno com um cardápio que vai dar água na boca. Servindo comida deliciosa há mais de 10 anos. Desfrute do nosso menu e experimente a beleza da naturalidade. Temos como missão oferecer uma experiência culinária autêntica e inesquecível. 
                    </b-row>
                    <b-row :class="!isMobile && !isMedium  ? 'about-text-two' : 'about-text-two-mobile'">
                        Buscamos proporcionar não apenas uma refeição, mas um momento de celebração e conexão. Com ingredientes de alta qualidade, hospitalidade calorosa e um toque de inovação, nossos restaurantes são um destino onde os clientes podem saborear a excelência da gastronomia, sempre com um serviço excepcional e um compromisso com a sustentabilidade e o crescimento profissional.
                    </b-row> -->
                </b-col>
                <b-col :class="!isMobile && !isMedium  ? 'image-col' : 'image-col-mobile'">
                    <div :class="!isMobile && !isMedium  ? 'image' : 'image-mobile'">
                        <!-- <img src="@/assets/images/restaurant/units/campolim_restaurant.png" style="object-fit:cover; border-radius:20px; width:100%; max-height:500px; max-width:700px" alt=""> -->
                        <img :src="modal.mainImage" style="object-fit:cover; border-radius:20px; width:100%; height:100%; max-width:700px" alt="">
                    </div>
                </b-col>
            </b-row>

            <div
            v-if=" modal.ordersPerDay
                || modal.customersServed
                || modal.citiesServed"
             style="height:6vh"></div>

            <b-row class="mx-auto" :class="!isMobile && !isMedium  ? '' : 'indicators'">
                <b-col v-if="modal.ordersPerDay">
                    <b-row class="bottom-item-row"><p :class="'bottom-title font-alice mx-auto' + (!isMobile && !isMedium ? ' py-4 ' : '')" style="font-weight:600"> +{{ modal.ordersPerDay }} </p></b-row>
                    <b-row class="bottom-item-row"><p class="bottom-subtitle mx-auto"> Pedidos por dia </p></b-row>
                </b-col>
                <b-col v-if="modal.customersServed">
                    <b-row class="bottom-item-row"><p :class="'bottom-title font-alice mx-auto' + (!isMobile && !isMedium ? ' py-4 ' : '')" style="font-weight:600"> +{{ modal.customersServed }} </p></b-row>
                    <b-row class="bottom-item-row"><p class="bottom-subtitle mx-auto"> Clientes atendidos </p></b-row>
                </b-col>
                <b-col v-if="modal.citiesServed">
                    <b-row class="bottom-item-row"><p :class="'bottom-title font-alice mx-auto' + (!isMobile && !isMedium ? ' py-4 ' : '')" style="font-weight:600"> +{{ modal.citiesServed }} </p></b-row>
                    <b-row class="bottom-item-row"><p class="bottom-subtitle mx-auto"> Cidades </p></b-row>
                </b-col>
            </b-row>
        </b-col>
    </div>
</template>

<script>
export default {
    name: "AboutUsSession",
    props: {
        modalProp: Object,
        didRequest: Boolean,
        loading: Boolean,
    },
    data(){
        return {
            isMobile: false,
            isMedium: false,
            modal: null,
        }
    },
    created(){
        this.modal=this.modalProp;
        const intervalId = setInterval(()=>{
            if(this.didRequest){
                clearInterval(intervalId);
                this.modal=this.modalProp;
            }
        }, 250)
        setInterval(() => {
            this.verifyMobile(); 
        }, 0);
        setInterval(() => {
            this.verifyMedium(); 
        }, 0);
    },
    methods: {
        verifyMobile(){
            this.isMobile = window.innerWidth <= 1000;
            return this.isMobile;
        },
        verifyMedium(){
            this.isMedium = (window.innerWidth < 1400) && (window.innerWidth > 1000);
            return this.isMedium;
        },
    }
}
</script>

<style scoped>
.session{
    background-color: #EEEEEE;
    color: #191C1D;
    /* padding: 50px; */
}
.content-row{
    display: flex;
    justify-content: center;
    align-items: center;
}
.content-row-mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.info-col{
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.image-col{
    display: flex;
    justify-content: center;
}
.image-col-mobile{
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}
.image{
    /* width: 35vw; height: 70vh; */
    max-height:600px; max-width:700px;
    width: 50vw;
    padding:20px;
    border:2px solid #FFB000;
    border-radius: 20px;
}

.image-mobile {
    max-height:600px; max-width:800px;
    width: 100%;
    height: 100%;
    padding:20px;
    border:2px solid #FFB000;
    border-radius: 20px;
}
.about-title{
    font-size: 60px;
    line-height: 60px;
    width: 30vw;
}
.about-title-mobile{
    font-size: 30px;
    line-height: 40px;
    width: 100%;
}
.about-text-one{
    width: 30vw;
    font-size: 20px;
}
.about-text-one-mobile{
    width: 100%;
    font-size: 18px;
}
.about-text-two{
    width: 30vw;
    font-size: 20px;
}
.about-text-two-mobile{
    width: 100%;
    font-size: 18px;
}
.bottom-item-row{
    line-height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.bottom-title{
    text-align: center;
    font-size: 80px;
}
.bottom-subtitle{
    text-align: center;
    font-size: 30px;
}
.indicators{
    display: flex;
    flex-direction: column;
}
.indicators > div {
    margin-top: 1rem;
}
.indicators .bottom-title {
    font-size: 2rem;
}
.indicators .bottom-subtitle{
    font-size: 1.375rem;
}
</style>