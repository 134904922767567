import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/style.css';
import store from './store'
import * as VueGoogleMaps from 'vue2-google-maps'
import VeeValidate from 'vee-validate'
import VueTheMask from 'vue-the-mask'
import '@/plugins/axios'
import '@/plugins/filters'

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API,
    libraries: 'places', // Adicione outras bibliotecas do Google Maps, se necessário
  },
});

VeeValidate.Validator.extend('phone', {
  validate: value => {
    const phone = value.replace(/[^\d]/g, '');

    if (phone.length !== 10 && phone.length !== 11) {
        return false;
    }

    return true;
  }
});

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: "veeFields",
  errorBagName: "veeErrors"
});

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueTheMask)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
