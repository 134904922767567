<template>
    <b-modal id="maintenance" centered hide-footer hide-header hide-header-close no-close-on-backdrop no-close-on-esc>
        <b-col class="px-4 pt-4 pb-4">
            <b-row class="mb-4">
                <b-col class="text-center" style="color: #FFB000; font-size:50px">
                    <img style="max-width:300px" src="@/assets/images/shared/logo_black.png" alt="">
                    <!-- <b-icon icon="exclamation-circle"></b-icon> -->
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-center font-nunito" style="font-size: 35px; font-weight: 600;">
                    <span>
                        Em manutenção
                        <!-- <b-icon icon="tools"></b-icon> -->
                    </span>
                </b-col>
            </b-row>
            <!-- <b-row>
                <b-col class="text-center mt-2" style="font-size: 22px; font-weight: 400;">
                    No momento, estamos em manutenção
                </b-col>
            </b-row> -->
        </b-col>
    </b-modal>
</template>

<script>
export default {
    name: 'MaintenanceModal',
    methods: {
        show(){
            this.$bvModal.show('maintenance');
        },
    }
}
</script>

<style scoped>

* /deep/ #maintenance___BV_modal_body_{
    z-index: 1040 !important;
}
* /deep/ .modal-content{
    border-radius: 40px !important;
}
</style>