<template>
    <transition name="loading">
        <div class="loading-view">
            <div class="loading"><img height="100vh" src="@/assets/images/shared/logo_white.png" alt=""></div>
            <div class="p-3"></div>
            <div class="loading"><b-spinner style="color:white"></b-spinner></div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "LoadingView"
}
</script>

<style scoped>
/* .preload-fade-enter-active 
.preload-fade-leave-active{
    transition: opacity 1s;
    animation-name: scaleOut;
    animation-duration: 1s;
}
.preload-fade-enter-from
.preload-fade-leave-to{
    transition: opacity 1s;
    opacity: 0;
} */

.loading-enter-from{
  opacity: 0;
}
.loading-enter-active{
  transition: all .25s ease;
}
.loading-leave-to{
  opacity: 0;
}
.loading-leave-active{
  transition: all .25s ease-in-out;
}

.loading-view{
    background-color: black;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1039 !important;
    position: fixed;
    overflow: hidden;
}
</style>