<template>
  <div>
    <b-button id="show-btn" size="sm" variant="outline-light" class="px-3 mx-1 toggle-icon"  @click="showModal">
      <b-row class="center">
        <p class="h6 my-auto"><b-icon icon="geo-alt"></b-icon></p>
      </b-row>
    </b-button>

    <b-modal class="card-modal" modal-class="modal-class" title-class="modal-title-class font-alice" centered size="md" ref="cardModalRefMobile" hide-footer title="Selecione o restaurante desejado">
      <b-row  class="row-items">
        <b-col :style="'background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url('+restaurant.image+'); background-size: cover; background-position:center; min-width: 175px'" v-for="(restaurant, idx) in restaurants" :key="idx" @click="changeRestaurant(idx)" class="card-item font-nunito">
            <p><b>{{ restaurant.name }}</b></p>
            <p>{{ restaurant.address.city }}, {{ restaurant.address.state }}</p>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: "CardModalMobile",
    props: {
      restaurants: []
    },
    computed: {
      ...mapState("restaurant", {
        restaurantState: (state) => state.restaurant
      })
    },
    methods: {
      showModal() {
        this.$refs.cardModalRefMobile.show()
      },
      changeRestaurant(idx){
        this.$store.commit("restaurant/STORE_RESTAURANT", this.restaurants[idx]); 
        this.hideModal();
        this.$router.go();
      },
      hideModal() {
        this.$refs.cardModalRefMobile.hide()
      },
    },
}
</script>

<style scoped>
.toggle-icon{
  color: white;
  border: 0px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s;
}
.toggle-icon:hover{
  color: black;
}

.center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.row-items{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.card-item{
    color: white;
    font-size: 18px;
    max-width: 200px;
    max-height: 200px;
    margin: 10px;
    padding: 20px;
    background-color: black;
    border-radius: 20px;
    transition: 0.2s;
}
.card-item:hover{
    transition: 0.2s;
    cursor: pointer;
    transform: translateX(5px);
    transform: translatey(-5px);
    box-shadow: 5px 5px 10px #AAAAAA;
}
</style>