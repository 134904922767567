<template>
  <div v-if="didRequest" :class="isMobile ? 'home-session-mobile' : 'home-session'" :style="'background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(\'' + modal[index].mainImage + '\') no-repeat; background-size: cover; background-position: center;'">
    
    <DefaultHeader/>
    <div v-if="!isMobile" :style="{height: isMobile ? '130px' : '200px'}"></div>

    <div :class="isMobile ? 'home-content-mobile' : 'home-content'">
        <b-row :class="isMobile ? 'home-content-col' : 'home-content-row'">

      <transition appear name="fade"
        enter-active-class="fade-enter-active"
        enter-class="fade-enter-from"
        enter-to-class="fade-enter-to"
        leave-active-class="fade-leave-active"
        leave-class="fade-leave-from"
        leave-to-class="fade-leave-to"
      >
          <DefaultSession v-if="!isPassing" :topic_title="modal[index].sectionName" :title="modal[index].title">
            <div :class="isMobile ? 'slot-col-mobile' : 'slot-col'" :style="!modal[index].buttonActive ? 'max-height:25vh' : (modal[index].subtitle=='' || modal[index].subtitle==null) ? 'height:15vh' : ''">
              <b-row v-if="modal[index].subtitle!='' && modal[index].subtitle!=null" class="font-nunito">
                <h6 class="home-subtitle" v-if="isMobile"> {{ modal[index].subtitle }} </h6>
                <h4 class="home-subtitle" v-else-if="isMedium"> {{ modal[index].subtitle }} </h4>
                <h3 class="home-subtitle" v-else> {{ modal[index].subtitle }} </h3>
              </b-row>
              <b-row v-if="modal[index].buttonActive" class="font-alice">
                <b-button v-if="isMobile" :href="modal[index].buttonUrl" variant="outline-light" size="md" class="btn-home">{{ modal[index].buttonText }}</b-button>
                <b-button v-else :href="modal[index].buttonUrl" variant="outline-light" size="lg" class="btn-home">{{ modal[index].buttonText }}</b-button>
              </b-row>
              <b-row v-if="!isMobile" style="display:flex; justify-content:center;">
                <a :href="goTo">
                  <p class="h1">
                    <b-icon icon="arrow-down-circle" class="arrow-button"></b-icon>
                  </p>
                </a>
              </b-row>
            </div>
          </DefaultSession>


      </transition>

          <div v-if="isMobile" class="carousel-index-list-mobile" style="width:100vw; margin-bottom:10px">
            <div style="display:flex; flex-direction:row; justify-content:center;">
              <div @click="changeCarouselIndex(i)" v-for="(e, i) in length" v-show="length!=0" :key="i" :class="i==index ? 'carousel-index-fill-mobile' : 'carousel-index-mobile'"></div>
            </div>
          </div>
          <div v-else class="carousel-index-list" style="display: flex; flex-direction:column; justify-content:center;">
            <div>
              <div @click="changeCarouselIndex(i)" v-for="(e, i) in length" v-show="length!=0" :key="i" :class="i==index ? 'carousel-index-fill' : 'carousel-index'"></div>
            </div>
          </div>
        </b-row>
    </div>
    
    <b-modal v-if="isMobile" class="card-modal" modal-class="modal-class" title-class="modal-title-class font-alice" centered size="md" ref="cardModalRef" hide-footer title="Selecione o restaurante desejado">
      <b-row cols="1" class="row-items font-nunito">
        <b-col :style="'background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url('+restaurant.image+'); background-size: cover; background-position:center; min-width: 175px; max-width: 180px; margin:10px 0px'" v-for="(restaurant, idx) in restaurants" :key="idx" @click="changeRestaurant(idx)" class="card-item">
            <p><b>{{ restaurant.name }}</b></p>
            <p>{{ restaurant.address.city }}, {{ restaurant.address.state }}</p>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-else class="card-modal" modal-class="modal-class" title-class="modal-title-class font-alice" centered size="xl" ref="cardModalRef" hide-footer title="Selecione o restaurante desejado">
      <b-row cols="5" class="row-items font-nunito">
        <b-col :style="'background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url('+restaurant.image+'); background-size: cover; background-position:center; min-width: 175px'" v-for="(restaurant, idx) in restaurants" :key="idx" @click="changeRestaurant(idx)" class="card-item">
            <p><b>{{ restaurant.name }}</b></p>
            <p>{{ restaurant.address.city }}, {{ restaurant.address.state }}</p>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import DefaultHeader from '@/components/shared/DefaultHeader.vue'
import DefaultSession from '@/components/shared/DefaultSession.vue'

export default {
    name: "HomeSession",
    components: {
        DefaultHeader,
        DefaultSession,
    },
    props: {
        goTo: String,
        modalProp: Array,
        didRequest: Boolean,
        loading: Boolean,
    },
    async mounted(){
      await this.loadRestaurants();
      this.restaurants.sort((a, b) => a.name.localeCompare(b.name));
      
      var localState = JSON.parse(localStorage.getItem('store'));
      if(localState==null || !localState.hasSelected){
        const intervalId = setInterval(()=>{
          if(this.$refs.cardModalRef){
            clearInterval(intervalId);
            this.showModal();
          }
        }, 250)
      }
    },
    created(){
      this.modal=this.modalProp;
      const intervalId = setInterval(()=>{
          if(this.didRequest){
            clearInterval(intervalId);
              this.modal=this.modalProp;
              this.length = this.modal.length;
              if(this.length>1)
                this.startCarousel();
          }
      }, 250)
      setInterval(() => {
        this.verifyMobile(); 
      }, 0);
      setInterval(() => {
        this.verifyMedium(); 
      }, 0);
    },
    methods: {
        navigate(value){
          this.$router.push({name: value}).catch(()=>{});
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        },
        verifyMobile(){
            this.isMobile = window.innerWidth <= 1000;
            return this.isMobile;
        },
        verifyMedium(){
            this.isMedium = (window.innerWidth < 1400) && (window.innerWidth > 1000);
            return this.isMedium;
        },
        startCarousel(){
          setInterval(() => {
                this.isPassing=true;
                setTimeout(()=>{
                  this.incrementCarouselIndex();
                  this.isPassing=false;
                }, 250)
                  
            }, 8000);
        },
        changeCarouselIndex(value){
          if(value==this.index || this.isPassing) return;
          this.isPassing=true;
          setTimeout(()=>{
            this.index = value;
            this.isPassing=false;
          }, 250)
        },
        incrementCarouselIndex(){
            if(this.index+1 >= this.length)
                this.index=0;
            else this.index++;
        },
        showModal() {
          this.$refs.cardModalRef.show();
        },
        changeRestaurant(idx){
          this.$store.commit("restaurant/STORE_RESTAURANT", this.restaurants[idx]);
          this.hideModal();
          this.$router.go();
        },
        hideModal() {
          this.$refs.cardModalRef.hide();
        },
        async loadRestaurants() {
            await this.$axios.get('/restaurant/detailed').then((response) => {
                this.restaurants = response.data.data;
            });
        },
    },
    data(){
      return{
        index: 0,
        length: 0,
        isMobile: false,
        isMedium: false,
        modal: null,
        isPassing: false,
        restaurants: [],
      }
    }
}
</script>

<style>
.fade-enter-from{
  opacity: 0;
  transform: translateY(50px);
}
.fade-leave-to{
  opacity: 0;
  transform: translateY(50px);
}
.fade-leave-active{
  transition: all 1s ease;
}
.fade-enter-active{
  transition: all 1s ease;
}


.home-session{
  /* background-image: url(../../../assets/images/home/home_background.png); */
  width: 100vw;
  height: 100vh;
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
}
.home-session-mobile{
  /* background-image: url(../../../assets/images/home/home_background.png); */
  width: 100vw;
  /* height: 45vh; */
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
}


.home-content{
  width: 100vw;
  height: 70vh;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-content-mobile{
  width: 100vw;
  /* height: 10vh; */
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-content-row{
  width: 100vw;
  display: flex;
  justify-content: center;
}
.home-content-column{
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.slot-col{
  height: 18vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.slot-col-mobile{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.home-subtitle{
  font-weight: 300;
  letter-spacing: 1px;
  max-width: 80vw;
}
.btn-home{
  margin: 2vw;
}
.arrow-button{
  color: #FFD14D;
  cursor: pointer;
  transition: 0.25s;
}
.arrow-button:hover{
  transition: 0.25s;
  cursor: pointer;
  opacity: 0.5;
}
.carousel-index-list{
    position: relative;
    top: -10vh;
    right: 5vw;
}
.carousel-index{
    cursor: pointer;
    border: 1px solid #FFB000;
    border-radius: 100%;
    width: 15px;
    height: 15px;
    margin: 5px 0px;
    transition: .5s;
}
.carousel-index-fill{
    background-color: #FFB000;
    border: 1px solid #FFB000;
    border-radius: 100%;
    width: 15px;
    height: 15px;
    margin: 5px 0px;
    transition: .5s;
}
.carousel-index-list-mobile{
    display: flex;
    justify-content: center;
    align-items: center;
}
.carousel-index-mobile{
    border: 1px solid #FFB000;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    margin: 5px 5px;
    transition: .5s;
}
.carousel-index-fill-mobile{
    background-color: #FFB000;
    border: 1px solid #FFB000;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    margin: 5px 5px;
    transition: .5s;
}
</style>