import Vue from 'vue';
import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.headers['Accept'] = 'application/json';

axios.interceptors.request.use(function(config) {
    const token = process.env.VUE_APP_TOKEN_API;

    if (token) {
        config.headers['Authorization'] = token;
    }

    return config;
});

Vue.prototype.$axios = axios;