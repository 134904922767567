<template>
    <div class="mapSession">
        <GmapMap :center="position" :zoom="12" style="width: 100%; height: 400px">
            <GmapMarker :position="position" />
        </GmapMap>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "MapSession",
    data(){
        return {
            isMobile: false,
            isMedium: false,
            position: {
                lat: -22.2259923,
                lng: -49.9336633
            },
        }
    },
    created(){
        setInterval(() => {
            this.verifyMobile(); 
        }, 0);
        setInterval(() => {
            this.verifyMedium(); 
        }, 0);
    },
    mounted(){
        this.getGeo();
    },
    methods: {  
        getGeo(){
            var address = JSON.parse(localStorage.getItem('store')).restaurant.address;
            var strAddress = address.street + ', ' + address.number + ' ' + address.city + ' - ' + address.state;
            const tokenAPI = process.env.VUE_APP_GEOCODE_API;
            const axiosInstance = axios.create({
                baseURL: 'https://geocode.maps.co',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            var query = `street=${address.number} ${address.street}&city=${address.city}&state=${address.state}&postalcode=${address.zipCode.replace(/\D/g, '')}`
            axiosInstance.get(`search?${query}&api_key=${tokenAPI}`)
            .then(response => {
                if(response.data && response.data.length>0)
                    this.position = {
                        lat: parseFloat(response.data[0].lat),
                        lng: parseFloat(response.data[0].lon),
                    }
                else{
                    setTimeout(()=>{
                        axiosInstance.get(`search?q=${strAddress}&api_key=${tokenAPI}`)
                        .then(response => {
                        if(response.data && response.data.length>0)
                            this.position = {
                                lat: parseFloat(response.data[0].lat),
                                lng: parseFloat(response.data[0].lon),
                            }
                        })
                        .catch(error=>{
                            error;
                        });
                    }, 1000)
                }
            })
            .catch(error=>{
                error;
            });
        },
        verifyMobile(){
            this.isMobile = window.innerWidth <= 1000;
            return this.isMobile;
        },
        verifyMedium(){
            this.isMedium = (window.innerWidth < 1400) && (window.innerWidth > 1000);
            return this.isMedium;
        },
    }
}
</script>

<style scoped>
.mapSession{
    background-color: #EEEEEE;
    color: #191C1D;
}
</style>