export default {
    namespaced: true,

    state: () => ({
        loading: true,
    }),

    mutations: {
        STORE_LOADING(state) {
            state.loading = true;
        },
        STORE_LOADED(state) {
            state.loading = false;
        },
    },

    actions: {},
};