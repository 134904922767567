<template>
  <b-col :class="isMobile ? 'dinner-session-mobile' : 'dinner-session'">
    <b-row class="font-nunito">
        <h6 v-if="isMobile" class="topic-title mx-auto">Jantar</h6>
        <h5 v-else class="topic-title mx-auto">Jantar</h5>
    </b-row>

    <b-row class="font-alice">
        <h1 class="display-6 title mx-auto" style="font-size:18px" v-if="isMobile"> {{ hourString }} </h1>
        <h1 class="display-6 title mx-auto" style="font-size:22px" v-else> {{ hourString }} </h1>
        <!-- <h1 class="display-6 title mx-auto" style="font-size:18px" v-if="isMobile"> Horário do jantar das 18:00 às 22:00 </h1>
        <h1 class="display-6 title mx-auto" style="font-size:22px" v-else> Horário do jantar das 18:00 às 22:00 </h1> -->
    </b-row>
    
    <b-row v-if="isMobile" class="font-nunito">
        <div style="display:flex; align-items:end; font-size:25px">
            <b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" no-caret style="z-index:2;">
                <template #button-content>
                    <h6 class="h6 menu-item" style="color:white"><b-icon class="mx-1" icon="list"></b-icon>Categorias</h6>
                </template>
                <b-dropdown-item class="p-2" v-for="(item, idx) in categoria_options" :key="idx" @click="loadMenuFiltered(item.value, legenda_selected || null)">{{ item.text }}</b-dropdown-item>
            </b-dropdown>
        </div>
        <b-row @click="showFilterModal" class="menu-item px-4 center-row">
            <img class="small-menu-icon" src="@/assets/images/menu/dinner/icons/filter_icon.png" alt=""> <span>Filtro</span>
        </b-row>
        <b-dropdown v-if="isMobile && legenda_options.length>0" size="lg" variant="link" toggle-class="text-decoration-none" no-caret style="z-index:2;">
            <template #button-content>
                <div class="menu-item">
                    <img class="small-menu-icon" src="@/assets/images/menu/dinner/icons/legenda_icon.png" alt=""> <span style="font-size:14px; color:white;">Legendas</span>
                </div>
            </template>
            <b-dropdown-text style="min-width:250px" class="p-2" v-for="(item, idx) in legenda_options" :key="idx">
                <b-row>
                    <!-- <b-col cols="0">
                        <img class="menu-icon p-1" :src="item.image" alt=""
                        style="background-color:#00000095; border-radius:5px; width:30px;height:30px;"> 
                    </b-col> -->
                    <b-col cols="0">
                        <img class="menu-icon tag-image-bg" style="width:30px; height:30px;" :src="item.image" :title="item.name" :alt="item.name"> 
                    </b-col>
                    <b-col style="font-weight:500; font-size:15px" class="p-1 pl-2" cols="8" align-self="center">
                        {{ item.text }}
                    </b-col>
                </b-row>
            </b-dropdown-text>
            <!-- <b-dropdown-text style="width:200px" disabled class="p-2">
                <b-row>
                    <b-col cols="0">
                        <img class="menu-icon vegetarian-color" src="@/assets/images/menu/dinner/icons/vegetariano_icon.png" alt=""> 
                    </b-col>
                    <b-col class="p-1" cols="8">
                        Vegetariano
                    </b-col>
                </b-row>
            </b-dropdown-text>
            <b-dropdown-text style="width:200px" disabled class="p-2">
                <b-row>
                    <b-col cols="0">
                        <img class="menu-icon lac-free-color" src="@/assets/images/menu/dinner/icons/lacfree_icon.png" alt="">
                    </b-col>
                    <b-col class="p-1" cols="8">
                        Lac Free
                    </b-col>
                </b-row>
            </b-dropdown-text>
            <b-dropdown-text style="width:200px" disabled class="p-2">
                <b-row>
                    <b-col cols="0">
                        <img class="menu-icon sem-gluten-color" src="@/assets/images/menu/dinner/icons/sem_gluten_icon.png" alt="">
                    </b-col>
                    <b-col class="p-1" cols="8">
                        Sem glúten
                    </b-col>
                </b-row>
            </b-dropdown-text>
            <b-dropdown-text style="width:200px" disabled class="p-2">
                <b-row>
                    <b-col cols="0">
                        <img class="menu-icon apimentado-color" src="@/assets/images/menu/dinner/icons/apimentado_icon.png" alt=""> 
                    </b-col>
                    <b-col class="p-1" cols="8">
                        Apimentado
                    </b-col>
                </b-row>
            </b-dropdown-text> -->
        </b-dropdown>
    </b-row>

    <b-row v-else class="font-nunito">
        <b-col :cols="isMobile ? '8' : '2'">
            <b-row class="start-row">
                <b-row @click="showFilterModal" class="menu-item px-4 center-row font-alice">
                    <img class="small-menu-icon" src="@/assets/images/menu/dinner/icons/filter_icon.png" alt=""> <span style="font-size:18px">Filtro</span>
                </b-row>
            </b-row>
        </b-col>
        <b-col v-if="!isMobile && legenda_options.length>0">
            <b-row v-if="!isMobile" class="center-row">
                <div class="mx-3 menu-item-off">
                    <img class="small-menu-icon" src="@/assets/images/menu/dinner/icons/legenda_icon.png" alt=""> <b>Legenda:</b>
                </div>
                <div class="mx-3 menu-item-off" v-for="(item, idx) in legenda_options" :key="idx">
                    <img class="menu-icon" :src="item.image" :title="item.name" :alt="item.name"> {{ item.text }}
                </div>
                <!-- <div class="mx-3 menu-item-off">
                    <img class="menu-icon" src="@/assets/images/menu/dinner/icons/apimentado_icon.png" alt=""> Apimentado
                </div>
                <div class="mx-3 menu-item-off">
                    <img class="menu-icon" src="@/assets/images/menu/dinner/icons/vegetariano_icon.png" alt=""> Vegetariano
                </div>
                <div class="mx-3 menu-item-off">
                    <img class="menu-icon" src="@/assets/images/menu/dinner/icons/lacfree_icon.png" alt=""> Lac Free
                </div>
                <div class="mx-3 menu-item-off">
                    <img class="menu-icon" src="@/assets/images/menu/dinner/icons/sem_gluten_icon.png" alt=""> Sem glúten
                </div> -->
                <div class="mx-3 menu-item-off">
                    <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" no-caret style="z-index:2">
                        <template #button-content>
                            <div class="menu">
                                <p class="h6 menu-item" style="font-size: 12px"><b-icon icon="chevron-down"></b-icon></p>
                            </div>
                        </template>
                        <b-dropdown-text style="min-width:250px" disabled class="p-2" v-for="(item, idx) in legenda_options" :key="idx">
                            <b-row>
                                <b-col cols="0" align-self="center">
                                    <img class="menu-icon tag-image-bg" style="width:30px; height:30px;" :src="item.image" :title="item.name" :alt="item.name"> 
                                </b-col>
                                <b-col style="font-weight:500" class="p-1 pl-2" align-self="center">
                                    {{ item.text }}
                                </b-col>
                            </b-row>
                        </b-dropdown-text>
                        <!-- <b-dropdown-text style="width:200px" disabled class="p-2">
                            <b-row>
                                <b-col cols="0">
                                    <img class="menu-icon vegetarian-color" src="@/assets/images/menu/dinner/icons/vegetariano_icon.png" alt=""> 
                                </b-col>
                                <b-col class="p-1" cols="8">
                                    Vegetariano
                                </b-col>
                            </b-row>
                        </b-dropdown-text>
                        <b-dropdown-text style="width:200px" disabled class="p-2">
                            <b-row>
                                <b-col cols="0">
                                    <img class="menu-icon lac-free-color" src="@/assets/images/menu/dinner/icons/lacfree_icon.png" alt="">
                                </b-col>
                                <b-col class="p-1" cols="8">
                                    Lac Free
                                </b-col>
                            </b-row>
                        </b-dropdown-text>
                        <b-dropdown-text style="width:200px" disabled class="p-2">
                            <b-row>
                                <b-col cols="0">
                                    <img class="menu-icon sem-gluten-color" src="@/assets/images/menu/dinner/icons/sem_gluten_icon.png" alt="">
                                </b-col>
                                <b-col class="p-1" cols="8">
                                    Sem glúten
                                </b-col>
                            </b-row>
                        </b-dropdown-text>
                        <b-dropdown-text style="width:200px" disabled class="p-2">
                            <b-row>
                                <b-col cols="0">
                                    <img class="menu-icon apimentado-color" src="@/assets/images/menu/dinner/icons/apimentado_icon.png" alt=""> 
                                </b-col>
                                <b-col class="p-1" cols="8">
                                    Apimentado
                                </b-col>
                            </b-row>
                        </b-dropdown-text> -->
                    </b-dropdown>
                </div>
            </b-row>
            <b-row v-else class="mx-3 center-row">
                <b-row>
                    <div class="px-1">
                        <img class="small-menu-icon" src="@/assets/images/menu/dinner/icons/legenda_icon.png" alt=""> 
                    </div>
                    <div>
                        Legendas
                    </div>
                </b-row>
            </b-row>
        </b-col>
        <b-col v-if="!isMobile" cols="2"></b-col>
    </b-row>

    <div :class="isMobile ? 'types-row-mobile' : 'types-row'">
        <p v-for="(item, idx) in categoria_options" :key="idx" class="p-0 font-nunito">
            <span class="p-1" v-if="item.value!=null" @click="loadMenuFiltered(item.value, legenda_selected || null)" :style="categorySelected==item.value ? 'border-bottom: 3px solid #FFB000; font-weight:500' : 'font-weight:300'">
                {{ item.text }}
            </span>
        </p>
    </div>

    <!-- <div v-if="!!categorySelected && listMenuItemsAdicional.length>=1" class="types-row font-nunito" style="width:100%; display:flex; justify-content:center; border: 0px solid white">
        <p @click="showAdicionalItems" style="font-size:18px;" :style="showAdicional ? 'border-bottom: 3px solid #FFB000' : '' ">Ver adicionais</p>
    </div> -->
    <div class="types-row font-nunito" style="width:100%; display:flex; justify-content:center; border: 0px solid white">
        <p v-if="!!categorySelected && listMenuItemsAdicional.length>=1" @click="showAdicionalItems" style="font-size:18px;" :style="showAdicional ? 'border-bottom: 3px solid #FFB000' : '' ">Ver adicionais</p>
    </div>


    <!-- <div v-if="listMenuItems.length==0" class="items-div font-nunito" 
    style="display:flex; justify-content:center; align-items:center; font-size:25px;"> 
        Nenhum item encontrado! </div> -->
    
    <div class="items-div">
        <transition name="opacity"
            enter-active-class="opacity-enter-active"
            enter-class="opacity-enter-from"

            leave-active-class="opacity-leave-active"
            leave-to-class="opacity-leave-to"
            >
            <div v-show="!isPassing">
                <b-row v-if="isMobile" class="items">
                    <b-col>
                        <div v-if="stepList.length==0" class="items-div font-nunito" 
                        style="display:flex; justify-content:center; align-items:center; font-size:25px;"> 
                            Nenhum item encontrado! </div>

                        <b-col v-for="(item, idx) in stepList" :key="idx">
                            <FoodItemMobile style="border-bottom: 1px solid #FFB000; padding: 10px 0px"
                                :click="showModal"
                                :item="item"
                            />
                        </b-col>
                    </b-col>
                </b-row>
                
                <b-row v-else class="items">
                    <b-col style="max-width:fit-content;" align-self="center">
                        <p class="h1">
                            <b-icon icon="arrow-left-circle" @click="previousItem" class="arrow-button" :style="canPassLeft ? '' : 'opacity:0.5; cursor:default;'"></b-icon>
                        </p>
                    </b-col>

                    <b-col v-if="listMenuItems.length==0">
                        <div class="items-div font-nunito" 
                        style="display:flex; justify-content:center; align-items:center; font-size:25px;"> 
                            Nenhum item encontrado! </div>
                    </b-col>

                    <b-col v-if="listMenuItems.length!=0">
                        <!-- <b-col v-for="(item, idx) in stepList.slice(0, 4)" :key="idx"> -->
                        <b-col v-for="(item, idx) in leftList" :key="idx">
                            <FoodItem 
                                :click="showModal"
                                :item="item"
                            />
                        </b-col>
                    </b-col>
                    <b-col v-if="listMenuItems.length!=0" cols="0">
                        <div style="height:95%; border:1px solid white; margin: 10px 0px"></div>
                    </b-col>
                    <b-col v-if="listMenuItems.length!=0">
                        <!-- <b-col v-for="(item, idx) in stepList.slice(4, 8)" :key="idx"> -->
                        <b-col v-for="(item, idx) in rightList" :key="idx">
                            <FoodItem 
                                :click="showModal"
                                :item="item"
                            />
                        </b-col>
                    </b-col>

                    <b-col style="max-width:fit-content" align-self="center">
                        <p class="h1">
                            <b-icon icon="arrow-right-circle" @click="nextItem" class="arrow-button" :style="canPassRight ? '' : 'opacity:0.5; cursor:default;'"></b-icon>
                        </p>
                    </b-col>
                </b-row>
            </div>
        </transition>
    </div>

    <!-- ICONES DE PAGINAÇÃO EM BAIXO -->
    <b-row v-if="isMobile" class="mx-auto">
        <p :class="isMobile ? 'h4' : 'h2'">
            <b-icon icon="arrow-left-circle" @click="previousItem" class="arrow-button" :style="canPassLeft ? '' : 'opacity:0.5; cursor:default;'"></b-icon>
        </p>
        <div class="mx-3"></div>
        <p :class="isMobile ? 'h4' : 'h2'">
            <b-icon icon="arrow-right-circle" @click="nextItem" class="arrow-button" :style="canPassRight ? '' : 'opacity:0.5; cursor:default;'"></b-icon>
        </p>
    </b-row>
    

    <!-- MODAL DO CARDÁPIO -->
    <b-modal v-if="!isMobile" class="menu-card-modal" body-class="p-0" modal-class="modal-class" title-class="modal-title-class" centered size="xl" ref="menuCardModalRef" hide-footer hide-header>
        <b-row class="font-nunito">
            <b-col cols="7" :style="isMedium ? 'width:50%' : 'width:60%'">
                <div class="image-col" :style="'background-image: url('+ image +');'">
                    <b-row class="mx-auto space-between-row px-3">
                        <p :class="isMobile ? 'h4' : 'h2'">
                            <b-icon icon="arrow-left-circle" class="modal-arrow-button" @click="previousItemModal" :style="canPassLeftModal ? '' : 'opacity:0; cursor:default;'"></b-icon>
                        </p>
                        <!-- <p :class="isMobile ? 'h4' : 'h2'">
                            <b-icon icon="arrow-right-circle" class="modal-arrow-button" @click="nextItemModal" :style="canPassRightModal ? '' : 'opacity:0; cursor:default;'"></b-icon>
                        </p> -->
                    </b-row>
                </div>
            </b-col>
            <b-col class="mx-4 space-between-col">
                <b-col>
                    <!-- CLOSE BUTTON -->
                    <b-row class="align-end-row" style="margin: 30px 0px 0px 0px;" :style="isMedium ? 'width:150%;' : 'width:125%;'">
                        <p class="h3">
                            <b-icon @click="hideModal" icon="x-circle" class="modal-close-button"></b-icon>
                        </p>
                    </b-row>

                    <!-- ITEM INFOS -->
                    <b-row>
                        <b-col>
                            <b-row> <h2 style="font-weight:bold; color:#520000;" class="font-alice">  {{ menuItem.name }}  </h2></b-row>
                            <b-row> {{ menuItem.description }} </b-row>
                            <b-row>
                                <img class="menu-icon tag-image-bg" style="width:30px; height:30px;" :src="e.image" :title="e.name" :alt="e.name" v-for="(e, idx) in menuItem.listTags" :key="idx">
                            </b-row>
                        </b-col>
                    </b-row>

                    <!-- VALORES -->
                    <b-row class="my-4">
                        <b-col>
                            <b-row> <h4 style="font-weight:bold"> Valores </h4> </b-row>
                            <b-row class="modal-price-row my-1" v-for="(item, idx) in menuItem.prices" :key="idx">
                                <h5> {{ item.name }} </h5>
                                <!-- <h5> Broto </h5> -->
                                <h5 style="font-weight:bold"> R$ {{ (item.value/100).toFixed(2).replace(".", ",") }} </h5>
                                <!-- <h5 style="font-weight:bold">  R$  </h5> -->
                            </b-row>
                        </b-col>
                    </b-row>
                    
                    <!-- DETALHES -->
                    <b-row v-if="menuItem.details">
                        <b-col>
                            <b-row> <h4 style="font-weight:bold">  Detalhes  </h4> </b-row>
                            <b-row v-if="!menuItem.detais" style="color: #5C5F60"> {{ menuItem.details || '---' }} </b-row>
                            <b-row v-else style="color: #5C5F60"> - </b-row>
                        </b-col>
                    </b-row>
                </b-col>

                <!-- FOOTER -->
                <b-row class="center-row py-4">
                    {{ getHourString() }}
                    <!-- Horário da pizzaria de 18:00 às 22:00 -->
                </b-row>

            </b-col>
            <b-col cols="0" align-self="center" class="mr-4">
                <p :class="isMobile ? 'h4' : 'h2'">
                    <b-icon icon="arrow-right-circle" class="modal-arrow-button" @click="nextItemModal" :style="canPassRightModal ? '' : 'opacity:0; cursor:default;'"></b-icon>
                </p>
            </b-col>
        </b-row>
    </b-modal>


    <b-modal v-else class="menu-card-modal" modal-class="modal-class" title-class="modal-title-class" centered size="md" ref="menuCardModalRef" hide-footer hide-header>
        <b-col class="font-nunito">
            <!-- CLOSE BUTTON -->
            <b-row class="align-end-row">
                <p class="h3">
                    <b-icon @click="hideModal" icon="x-circle" class="modal-close-button"></b-icon>
                </p>
            </b-row>
            <b-row>
                <div :style="'background-image: url('+ image +');'" class="image-col-mobile mt-3">
                    <b-row class="mx-auto space-between-row-end" style="height:100%;">
                        <p :class="isMobile ? 'h4' : 'h2'">
                            <b-icon icon="arrow-left-circle" class="modal-arrow-button" @click="previousItemModal" :style="canPassLeftModal ? '' : 'opacity:0; cursor:default;'"></b-icon>
                        </p>
                        <p :class="isMobile ? 'h4' : 'h2'">
                            <b-icon icon="arrow-right-circle" class="modal-arrow-button" @click="nextItemModal" :style="canPassRightModal ? '' : 'opacity:0; cursor:default;'"></b-icon>
                        </p>
                    </b-row>
                </div>
            </b-row>
            <b-row class="mx-1 space-between-col" style="margin-top: 15px;">
                <b-col>

                    <!-- ITEM INFOS -->
                    <b-row>
                        <b-col>
                            <b-row> <h3 style="font-weight:bold; color:#520000" class="font-alice">  {{ menuItem.name }}  </h3></b-row>
                            <b-row class="modal-text-mobile"> {{ menuItem.description }} </b-row>
                            <b-row>
                                <img class="menu-icon tag-image-bg" style="width:30px; height:30px;" :src="e.image" :title="e.name" :alt="e.name" v-for="(e, idx) in menuItem.listTags" :key="idx">
                            </b-row>
                        </b-col>
                    </b-row>

                    <!-- VALORES -->
                    <b-row class="my-4">
                        <b-col>
                            <b-row> <h5 style="font-weight:bold"> Valores </h5> </b-row>
                            <b-row class="modal-price-row-mobile my-1" v-for="(item, idx) in menuItem.prices" :key="idx">
                                <h6> {{ item.name }} </h6>
                                <!-- <h6> Broto </h6> -->
                                <h6 style="font-weight:bold"> R$ {{ (item.value/100).toFixed(2).replace(".", ",") }} </h6>
                                <!-- <h6 style="font-weight:bold"> R$  </h6> -->
                            </b-row>
                        </b-col>
                    </b-row>
                    
                    <!-- DETALHES -->
                    <b-row v-if="menuItem.details">
                        <b-col>
                            <b-row> <h5 style="font-weight:bold">  Detalhes  </h5> </b-row>
                            <b-row class="modal-text-mobile" style="color:#5C5F60"> {{ menuItem.details || '---' }} </b-row>
                        </b-col>
                    </b-row>
                </b-col>

                <!-- FOOTER -->
                <b-row class="center-row py-4" style="text-align: center;">
                    {{ getHourString() }}
                    <!-- Horário da pizzaria de 18:00 às 22:00 -->
                </b-row>

            </b-row>
            
        </b-col>
    </b-modal>


    <!-- FILTER MODAL -->
    <b-modal class="filter-card-modal" modal-class="modal-class" title-class="modal-title-class" centered size="md" ref="filterCardModalRef" hide-footer hide-header>
        <b-col style="width: 100%" class="font-nunito">
            <b-row style="display:flex; justify-content:space-between; align-items:center">
                <div><h5 style="color: #520000; font-size: 25px" class="font-alice"><b>Filtro</b></h5></div>
                <div>
                    <p class="h3">
                        <b-icon @click="hideFilterModal" icon="x-circle" class="modal-close-button"></b-icon>
                    </p>
                </div>
            </b-row>
            <b-row class="my-3 space-between-row">
                <b-col cols="4">Legenda</b-col>
                <b-col>
                    <b-form-select v-model="legenda_selected">
                        <b-form-select-option :value="null">Todos</b-form-select-option>
                        <b-form-select-option v-for="(item, idx) in legenda_options" :key="idx" :value="item.value">{{item.text}}</b-form-select-option>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row class="my-3 space-between-row">
                <b-col cols="4">Categoria</b-col>
                <b-col>
                    <b-form-select v-model="categoria_selected">
                        <b-form-select-option :value="null">Todos</b-form-select-option>
                        <b-form-select-option v-for="(item, idx) in categoria_options" :key="idx" :value="item.value">{{item.text}}</b-form-select-option>
                    </b-form-select>
                </b-col>
            </b-row>
            <div class="my-4"></div>
            <b-row class="align-end-row">
                <b-button class="mx-2" variant="outlined-light" @click="resetFilterModal">Redefinir</b-button>
                <b-button class="mx-2" variant="danger" style="background-color:#520000" @click="loadMenuFiltered(categoria_selected, legenda_selected)">Pesquisar</b-button>
            </b-row>
        </b-col>
    </b-modal>

  </b-col>
</template>

<script>
import { mapState } from 'vuex';
import FoodItem from '../components/FoodItem.vue';
import FoodItemMobile from '../components/FoodItemMobile.vue';

export default {
    name: "DinnerSession",
    components: {
        FoodItem,
        FoodItemMobile,
    },
    data(){
        return {
            index: 0,
            stepIndex: 8,
            leftList: [],
            rightList: [],
            stepList: [],
            isPassing: false,
            canPassLeft: false,
            canPassRight: false,
            canPassLeftModal: false,
            canPassRightModal: false,
            categorySelected: '',
            isMobile: false,
            isMedium: false,
            hourString: null,
            image: '',
            restaurantId: '',
            legenda_selected: null,
            legenda_options: [],
            categoria_selected: null,
            categoria_options: [],
            menuItem: {},
            listMenuItems: [],
            showAdicional: false,
            listMenuItemsSemAdicional: [],
            listMenuItemsAdicional: [],
        }
    },
    async created(){
        this.$store.commit("loading/STORE_LOADING");
        const intervalId = setInterval(()=>{
            this.getHourString();
            if(this.hourString!=null){
                clearInterval(intervalId);
            }
        }, 100);
        setInterval(() => {
            this.verifyMobile(); 
        }, 0);
        setInterval(() => {
            this.verifyMedium(); 
        }, 0);
        this.restaurantId = JSON.parse(localStorage.getItem('store')).restaurant.id;
        
        await this.loadMenu();

        if(this.categoria_options.length>0){
            await this.loadMenuFiltered(this.categoria_options[0].value, null);
        }

        if(this.listMenuItems.length==0){
            this.$emit('hideSession');
            return;
        }

        setTimeout(()=>{
            this.$store.commit("loading/STORE_LOADED");
        }, 500)
    },
    computed: {
        ...mapState("restaurant", {
            restaurantState: (state) => state.restaurant
        }),
    },
    methods: {
        getSideLists(){
            this.leftList = [];
            this.rightList = [];
            this.stepList.forEach(e => {
                if(this.stepList.indexOf(e)%2==0)
                    this.leftList.push(e);
                else this.rightList.push(e);
            });
        },
        verifyCanPassModal(){
            var indexModal = this.listMenuItems.indexOf(this.menuItem);
            if(indexModal + 1 >= this.listMenuItems.length){
                this.canPassRightModal = false;
            }
            else this.canPassRightModal = true;
            
            if(indexModal - 1 < 0){
                this.canPassLeftModal = false;
            }
            else this.canPassLeftModal = true;
        },
        verifyCanPass(){
            const idx = this.categoria_options.findIndex(e=>e.value==this.categorySelected);
            
            if(this.index + this.stepIndex >= this.listMenuItems.length){
                if(idx==this.categoria_options.length-1)
                    this.canPassRight = false;
                else this.canPassRight = true;
            }
            else this.canPassRight = true;
            
            if(this.index - this.stepIndex < 0){
                if(idx==0)
                    this.canPassLeft = false;
                else this.canPassLeft = true;
            }
            else this.canPassLeft = true;
        },
        nextItem(){
            this.verifyCanPass();
            if(!this.canPassRight) return;
            this.isPassing=true;
            setTimeout(() => {
                if(this.index + this.stepIndex >= this.listMenuItems.length){
                    const idx = this.categoria_options.findIndex(e=>e.value==this.categorySelected);
                    this.loadMenuFiltered(this.categoria_options[idx+1].value, this.legenda_selected || null);
                }
                else{
                    this.index+=this.stepIndex;
                    this.stepList = this.listMenuItems.slice(this.index, this.index+this.stepIndex);
                    this.getSideLists();
                    this.isPassing=false;
                }
                this.verifyCanPass();
            }, 250);
        },
        previousItem(){
            this.verifyCanPass();
            if(!this.canPassLeft) return;
            this.isPassing=true;
            setTimeout(async () => {
                if(this.index - this.stepIndex < 0){
                    const idx = this.categoria_options.findIndex(e=>e.value==this.categorySelected);
                    await this.loadMenuFiltered(this.categoria_options[idx-1].value, this.legenda_selected || null, true);
                }
                else{
                    this.index-=this.stepIndex;
                    this.stepList = this.listMenuItems.slice(this.index, this.index+this.stepIndex);
                    this.getSideLists();
                    this.isPassing=false;
                }
                this.verifyCanPass();
            }, 250);
        },
        getCategories(){
            this.listMenuItems.forEach((item) => {
                const categoryExists = !!this.categoria_options.find(category => category.value == item.categoryId);
                if(!categoryExists){
                    this.categoria_options.push({value:item.categoryId, text: item.categoryName, position: item.categoryPosition});
                }
            });
            this.categoria_options.sort((a,b)=>{
                return a.position - b.position;
            })
        },
        getTags(){
            this.listMenuItems.forEach((item) => {
                if(item.listTags.length==0) return;
                
                item.listTags.forEach(tag => {
                    const tagExists = !!this.legenda_options.find(e => e.value == tag.id);
                    if(!tagExists && tag.active==true){
                        this.legenda_options.push({value:tag.id, text: tag.name, image: tag.image});
                    }
                })
            });
        },
        showModal(value) {
            this.menuItem = value;
            this.image = "'" + this.menuItem.image + "'";
            this.verifyCanPassModal();
            this.$refs.menuCardModalRef.show();
        },
        nextItemModal(){
            this.verifyCanPassModal();
            var indexModal = this.listMenuItems.indexOf(this.menuItem);
            if(indexModal+1 >= this.listMenuItems.length) return;
            else this.menuItem = this.listMenuItems[indexModal+1];
            this.image = "'" + this.menuItem.image + "'";
            this.verifyCanPassModal();
        },
        previousItemModal(){
            this.verifyCanPassModal();
            var indexModal = this.listMenuItems.indexOf(this.menuItem);
            if(indexModal-1 < 0) return;
            else this.menuItem = this.listMenuItems[indexModal-1];
            this.image = "'" + this.menuItem.image + "'";
            this.verifyCanPassModal();
        },
        hideModal() {
            this.$refs.menuCardModalRef.hide();
        },
        showFilterModal() {
            this.$refs.filterCardModalRef.show();
        },
        resetFilterModal(){
            this.legenda_selected = null;
            this.categoria_selected = null;
        },
        hideFilterModal() {
            this.$refs.filterCardModalRef.hide();
        },
        verifyMobile(){
            this.isMobile = window.innerWidth <= 1100;
            return this.isMobile;
        },
        verifyMedium(){
            this.isMedium = (window.innerWidth < 1200) && (window.innerWidth > 1100);
            return this.isMedium;
        },
        showAdicionalItems(){
            this.showAdicional = !this.showAdicional;
            if(this.showAdicional){
                // this.listMenuItems = this.listMenuItemsAdicional;
                this.stepList = this.listMenuItemsAdicional.slice(0, this.stepIndex);
            }
            else{
                // this.listMenuItems = this.listMenuItemsSemAdicional;
                this.stepList = this.listMenuItemsSemAdicional.slice(0, this.stepIndex);
            }
            this.isPassing=true;
            setTimeout(()=>{
                this.getSideLists();
                this.isPassing=false;
            },250)
        },
        async loadMenu(){
            // var restaurantId='243496ae-de60-4889-a25c-1432727f31d2';
            // var restaurantId='c95240be-b496-4310-8e6c-b623a2b1b35a';
            var restaurantId = this.restaurantId;
        
            this.isPassing = true;
            await this.$axios.get('/restaurant/' + restaurantId + '/menu').then((response) => {
                this.listMenuItems = response.data.data;
                this.listMenuItems.sort((a,b)=>a.name.localeCompare(b.name));
                this.listMenuItemsSemAdicional = response.data.data.filter(x => x.additional==false);
                this.listMenuItemsAdicional = response.data.data.filter(x => x.additional==true);
                this.listMenuItems.forEach(element => {
                    // if (element.listCustomPrices.length>0){
                        if (element.listCustomPrices && element.listCustomPrices.length>0){
                            element.prices = element.listCustomPrices;
                    }
                    else element.prices = element.listPrices;
                    element.prices.sort(function(a, b) {return a.value - b.value});
                });
            })
            // .catch(()=>{this.$emit('hideSession'); return;});
            this.getCategories();
            this.getTags();
            this.showAdicional = false;
            this.index = 0;
            this.listMenuItems = this.listMenuItemsSemAdicional;
            this.stepList = this.listMenuItems.slice(0, this.stepIndex);
            this.getSideLists();
            this.verifyCanPass();
            this.isPassing = false;
        },
        async loadMenuFiltered(categoryId, tagId, previous=false){
            // var restaurantId='243496ae-de60-4889-a25c-1432727f31d2';
            // var restaurantId='c95240be-b496-4310-8e6c-b623a2b1b35a';
            var restaurantId = this.restaurantId;

            var basePath = '/restaurant/' + restaurantId + '/menu';
            
            if(!categoryId)
                categoryId = this.categoria_options.find(()=>true).value;

            var complementPath = '';
            if(categoryId!=null && tagId !=null){
                complementPath = '?Id%20da%20Categoria=' + categoryId + '&Id%20da%20Tag=' + tagId;
                this.categorySelected = categoryId;
            }
            else if(categoryId!=null && tagId ==null){
                complementPath = '?Id%20da%20Categoria=' + categoryId;
                this.categorySelected = categoryId;
            }
            else if(categoryId==null && tagId !=null)
                complementPath = '?Id%20da%20Tag=' + tagId;
            else
                this.categorySelected = null;
            
            this.isPassing=true;
            await this.$axios.get(basePath + complementPath).then((response) => {
                this.listMenuItems = response.data.data;
                this.listMenuItems.sort((a,b)=>a.name.localeCompare(b.name));
                this.listMenuItemsSemAdicional = response.data.data.filter(x => x.additional==false);
                this.listMenuItemsAdicional = response.data.data.filter(x => x.additional==true);
                this.listMenuItems.forEach(element => {
                    // if (element.listCustomPrices.length>0){
                        if (element.listCustomPrices && element.listCustomPrices.length>0){
                            element.prices = element.listCustomPrices;
                    }
                    else element.prices = element.listPrices;
                    element.prices.sort(function(a, b) {return a.value - b.value});
                });
            })
            // .catch(()=>{this.$emit('hideSession'); return;});
            this.showAdicional = false;
            this.index = 0;
            this.listMenuItems = this.listMenuItemsSemAdicional;
            if(previous){
                const qtd = this.listMenuItems.length % this.stepIndex;
                this.index = this.listMenuItems.length - (qtd === 0 ? this.stepIndex : qtd);
            }
            this.stepList = this.listMenuItems.slice(this.index, this.index + this.stepIndex);
            this.getSideLists();
            this.verifyCanPass();
            this.isPassing=false;
            this.hideFilterModal();
        },
        getHourString(){
            var daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'HOLIDAY'];
            var now = new Date;
            var dayWeek = daysOfWeek[now.getDay()];
            var todayList = [];
            if(this.restaurantState.times==null || this.restaurantState.times.length==0) return;
            
            this.restaurantState.times.forEach(time => {
                if(time.name.toLowerCase()=="jantar" || time.name.toLowerCase() == "pizzas e calzones"){
                    if(time.sequential){
                        var tempFirstDay = time.firstDay;
                        var tempLastDay = time.lastDay.replace('/HOLIDAY', '');
                        var first = daysOfWeek.indexOf(tempFirstDay);
                        var last = daysOfWeek.indexOf(tempLastDay);
                        if((now.getDay() >= first && now.getDay() <= last) || (first>last)){
                            todayList.push(time);
                        }
                    }
                    else {
                        var firstDays = time.firstDay.split(', ');
                        var lastDay = time.lastDay.replace('/HOLIDAY', '');
                        firstDays.forEach(fd => {
                            if(fd == dayWeek){
                                todayList.push(time);
                            }
                        })
                        if(lastDay == dayWeek){
                            todayList.push(time);
                        }
                    }
                }
            });

            // var str = "";

            // todayList.forEach(today => {
            //     if(today.name.toLowerCase() == "jantar"){
            //         if(today.closed){
            //             str = "Fechado hoje";
            //         }
            //         else{
            //             str = "Horário de jantar das " + today.opensAt + " às " + today.closesAt;
            //         }
            //     }else{
            //         str = "Fechado hoje";
            //     }
            // })

            var str = "Fechado hoje";

            todayList.forEach(today => {
                if(today.name.toLowerCase() == "jantar"){
                    if(!today.closed){
                        str = "Horário de jantar das " + today.opensAt + " às " + today.closesAt;
                    }
                }
                else if(today.name.toLowerCase() == "pizzas e calzones"){
                    if(!today.closed){
                        str = "Horário de " + today.name + " das " + today.opensAt + " às " + today.closesAt;
                    }
                }
            })

            this.hourString = str;
            // return str;
        }
    }
}
</script>

<style scoped>
.opacity-enter-from{
  opacity: 0;
  transform: translateY(-5px);
}
.opacity-enter-active{
    transition: all .25s ease;
}
.opacity-leave-to{
    opacity: 0;
    transform: translateY(-5px);
}
.opacity-leave-active{
  transition: all .25s ease-in-out;
}

.dinner-session{
    background-color: #520000;
    width: 100vw; 
    min-height: 650px;
    padding: 50px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
}
.dinner-session-mobile{
    background-color: #520000;
    width: 100vw; 
    /* height: 400px; */
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
}
.topic-title{
    font-weight: 300;
    border-bottom: 3px solid white;
}
.title{
    font-weight: 400;
}
.menu-item-off{
    font-size: 14px;
}
.menu-item{
    /* transition: .25s; */
    font-size: 14px;
}
.menu-item:hover{
    color: #FFB000;
    cursor: pointer;
    filter: invert(32%) sepia(50%) saturate(2378%) hue-rotate(339deg) brightness(104%) contrast(91%);
}
.menu-icon{
    width: 23px;
    height: 23px;
    margin: 5px;
    object-fit: contain;
    border-radius: 5px;
}
.items-div{
    min-height: 700px;
}

/* MENU ICON COLORS */
/* USAR SITE: https://codepen.io/sosuke/pen/Pjoqqp */
.sem-gluten-color{filter: invert(32%) sepia(50%) saturate(2378%) hue-rotate(339deg) brightness(104%) contrast(91%);}
.vegetarian-color{filter: invert(62%) sepia(16%) saturate(2035%) hue-rotate(105deg) brightness(89%) contrast(86%);}
.lac-free-color{ filter: invert(72%) sepia(1%) saturate(1668%) hue-rotate(179deg) brightness(86%) contrast(83%); }
.apimentado-color{filter: invert(82%) sepia(34%) saturate(5993%) hue-rotate(359deg) brightness(101%) contrast(105%);}
/* ---------------- */

.small-menu-icon{
    width: 15px;
    height: 15px;
    margin: 5px;
    object-fit: contain;
}
.center-row{
    display: flex;
    justify-content: center;
    align-items: center;
}
.start-row{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.types-row-mobile{
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    font-size: 12px;
    padding: 2px 0px;
    margin: 5px 0px;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: auto;
    white-space: nowrap;
}
.types-row{
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    font-size: 16px;
    padding:10px 0px;
    margin: 10px 0px;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}
.types-row p{
    margin: 0px;
}
.types-row-mobile p{
    margin: 10px 10px;
}
.types-row p:hover,
.types-row-mobile p:hover{
    border-bottom: 3px solid #FFB000;
    cursor: pointer;
}
/* .items{

} */
.space-between-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
}
.space-between-row-end{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 95%;
}
.space-between-col{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.modal-row{
    display: flex;
    padding: 0px;
    margin: 0px;
}
.modal-arrow-button{
    transition: 0.25s;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    width: 50px;
    height: 50px;
    padding: 10px;
    color: white;
}
.modal-arrow-button:hover{
    background-color: rgba(0, 0, 0, 1);
    cursor: pointer;
}
.image-col{
    width: 100%;
    /* transform: translateX(14px); */
    min-height: 600px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    scale: 1.0025;
}
.image-col-mobile{
    /* transform: translateX(20px); */
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 20px;
}
.modal-image{
    width: 100%;
    height: 600px;
    object-fit: cover;
}
.align-end-row{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.modal-close-button{
    transition: 0.25s;    
}
.modal-close-button:hover{
    cursor: pointer;
    opacity: 0.5;
}
.modal-price-row{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.modal-price-row-mobile{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.modal-text-mobile{
    font-size: 14px;
}
</style>