<template>
  <div id="footer" :class="isMobile ? 'footer-mobile' : 'footer'" :style="'background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(\'' + modal.imageUrl + '\') no-repeat; background-size: cover; background-position: center;'">
    <b-row :class="(isMobile ? 'content-row-mobile' : 'content-row') + ' m-auto'">

        <b-col :class="isMobile ? 'left-col-mobile font-nunito' : 'left-col font-nunito'">
            <a :class="isMobile ? 'footer-link-mobile' : 'footer-link'" @click="navigate('home')"><p> Início </p></a>
            <a :class="isMobile ? 'footer-link-mobile' : 'footer-link'" @click="navigate('menu')"><p> Cardápio </p></a>
            <a :class="isMobile ? 'footer-link-mobile' : 'footer-link'" @click="navigate('restaurante')"><p> Restaurantes </p></a>
            <a :class="isMobile ? 'footer-link-mobile' : 'footer-link'" @click="showPaymentModal"><p> Formas de pagamento </p></a>
            <a :class="isMobile ? 'footer-link-mobile' : 'footer-link'" @click="navigate('sobre')"><p> Sobre nós </p></a>
            <a :class="isMobile ? 'footer-link-mobile' : 'footer-link'" @click="navigate('contato')"><p> Contato </p></a>
            <a :class="isMobile ? 'footer-link-mobile' : 'footer-link'" href="https://tauste.gupy.io" target="_blank"><p> Trabalhe conosco </p></a>
        </b-col>

        <b-container :class="isMobile ? 'white-container-mobile my-2' : 'white-container mx-5'" style="border-radius:10px">
            <b-col class="container-col font-alice">
                <b-row><img :class="isMobile ? 'logo-mobile' : 'logo'" src="../../assets/images/shared/logo_black.png" alt=""></b-row>
                <b-row class="line"></b-row>
                <b-row :class="isMobile ? 'container-text-mobile' : 'container-text'" style="line-height: 18px">{{ restaurantState.name }}, {{ restaurantState.address.street }}, {{ restaurantState.address.number }} - {{ restaurantState.address.district }}, {{ restaurantState.address.city }} - {{ restaurantState.address.state }}</b-row>
                <b-row :class="isMobile ? 'container-text-mobile' : 'container-text'" style="line-height: 15px;"> 
                    <span style="width: 100%; padding: 2px" v-for="(str, idx) in getHourString()" :key="idx"> {{ str }} </span>
                </b-row>
                <b-row :class="isMobile ? 'container-text-mobile' : 'container-text'">{{ restaurantState.phone | formatPhoneNumber }}</b-row>
                <b-row :class="isMobile ? 'container-text-mobile' : 'container-text'"><a href="mailto:ladirna@ladirna.com.br" style="color:black;text-decoration:underline">ladirna@ladirna.com.br</a></b-row>
                
            </b-col>
        </b-container>

        <b-col :class="isMobile ? 'right-col-mobile font-nunito' : 'right-col font-nunito'">
            <a v-for="(link,idx) in modal.listFooterLinks" :key="idx" :class="link.id==null ? 'font-spacing' : (isMobile ? 'footer-link-mobile' : 'footer-link')" :href="link.textUrl" target="_blank"><p>{{ link.id==null ? 'a' : link.textName }}</p></a>
        </b-col>

    </b-row>
    <b-row class="foot font-nunito">
        <p v-if="isMobile" style="font-size: 13px;" class="foot-text">
            1991 - {{ new Date().getFullYear() }} ©La Dirna Restaurante e Pizzaria. Todos os Direitos Reservados. | Políticas de privacidade | v{{ version }}
        </p>
        <p v-else style="font-size: 15px;" class="foot-text">
            1991 - {{ new Date().getFullYear() }} ©La Dirna Restaurante e Pizzaria. Todos os Direitos Reservados. | Políticas de privacidade | v{{ version }}
        </p>
    </b-row>

    <b-modal ref="paymentCardModalRef" modal-class="modal-class" title-class="modal-title-class font-alice" size="xl" centered hide-footer title="Formas de pagamento">
      <div class="font-nunito">
        <b>A Prazo sem Acréscimo Financeiro</b><br><br>
        - Cheque pré-datado conforme o prazo determinado em nosso folheto de ofertas, ou parcelado em 4 vezes sendo entrada + 3 parcelas (30, 60, 90 dias) a partir da data da compra.<br><br>
        <b>A Prazo com Acréscimo Financeiro</b><br><br>
        - Cheque pré-datado conforme o prazo determinado em nosso folheto de ofertas, sendo que após o término do prazo estipulado sem juros, haverá acréscimo financeiro 0,29% ao dia (8,70% ao mês e 104,40% ao ano) com juros simples.<br><br>
        <b>Observações Importantes</b><br>
        <li>Nos planos de pagamento com cheque não serão aceitos cheques de terceiros.Nas compras com cheque é obrigatório a apresentação do RG, mediante consulta Serasa, SPC e cadastro interno.Valor mínimo cheque pré-datado: R$ 40,00 / parcela.Para as primeiras compras com cheque o valor máximo é de R$ 600,00 à vista.Produtos em promoção somente à vista. Não vendemos no atacado.Reservamo-nos o direito de limitar a quantidade dos produtos ofertados artigo 39, inciso I, do Código de Defesa do Consumidor (Lei nº8.078./90).Fotos meramente ilustrativas.</li><br>
        <b>Cartão Tauste Alimentação</b><br><br>
        Substituição da cesta básica por crédito em cartão; a empresa determina o valor, data e abertura do crédito. <br>
        COMPRAS: Liberdade de escolha dos produtos e marcas que seja de preferência do próprio funcionário. <br>
        SALDO: O funcionário não precisa gastar todo o crédito dentro do mês, podendo o saldo ser acumulado para os meses seguintes.<br>
        NÃO PAGA ANUIDADADE: O Cartão é isento de anuidade e taxa de inscrição.<br><br>
        <b>Cartão Tauste Convênio</b><br><br>
        COMPRAS EM ATÉ 40 DIAS, SEM JUROS: Possibilidade de antecipação da compra em até 40 dias da data de recebimentos do salário.<br>
        LIMITE DE CRÉDITO: Pré-determinado pela própria empresa para cada funcionário.<br>
        NÃO PAGA ANUIDADE: O Cartão é isento de anuidade e taxa de inscrição.<br>
        CONFORTO PARA O PAGAMENTO: Para maior comodidade ao funcionário, o cartão dispensa o uso de dinheiro ou cheque, sendo o débito feito em folha de pagamento.<br>
      </div>
      <b-row :style="isMobile ? 'display:flex; justify-content: center;' : 'display:flex; justify-content: flex-end'" class="mx-2">
        <img class="mx-2 my-3" style="max-width:60px; max-height:50px; object-fit:contain;" src="@/assets/images/shared/pay_flags/visa.png" alt="">
        <img class="mx-2 my-3" style="max-width:60px; max-height:50px; object-fit:contain;" src="@/assets/images/shared/pay_flags/mastercard.png" alt="">
        <img class="mx-2 my-3" style="max-width:60px; max-height:50px; object-fit:contain;" src="@/assets/images/shared/pay_flags/visa_electron.png" alt="">
        <img class="mx-2 my-3" style="max-width:60px; max-height:50px; object-fit:contain;" src="@/assets/images/shared/pay_flags/diners.png" alt="">
        <img class="mx-2 my-3" style="max-width:60px; max-height:50px; object-fit:contain;" src="@/assets/images/shared/pay_flags/american_express.png" alt="">
        <img class="mx-2 my-3" style="max-width:60px; max-height:50px; object-fit:contain;" src="@/assets/images/shared/pay_flags/hipercard.png" alt="">
        <img class="mx-2 my-3" style="max-width:60px; max-height:50px; object-fit:contain;" src="@/assets/images/shared/pay_flags/elo.png" alt="">
        <img class="mx-2 my-3" style="max-width:60px; max-height:50px; object-fit:contain;" src="@/assets/images/shared/pay_flags/cabal.png" alt="">
        <img class="mx-2 my-3" style="max-width:60px; max-height:50px; object-fit:contain;" src="@/assets/images/shared/pay_flags/soro.png" alt="">
        <img class="mx-2 my-3" style="max-width:60px; max-height:50px; object-fit:contain;" src="@/assets/images/shared/pay_flags/sodexo.png" alt="">
        <img class="mx-2 my-3" style="max-width:60px; max-height:50px; object-fit:contain;" src="@/assets/images/shared/pay_flags/vale_card.png" alt="">
        <img class="mx-2 my-3" style="max-width:60px; max-height:50px; object-fit:contain;" src="@/assets/images/shared/pay_flags/ticket.png" alt="">
        <img class="mx-2 my-3" style="max-width:60px; max-height:50px; object-fit:contain;" src="@/assets/images/shared/pay_flags/alelo.png" alt="">
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import packageJson from '@/../package.json'

export default {
    name: "DefaultFooter",
    data(){
        return {
            isMobile: false,
            isMedium: false,
            modal: {
                imageUrl: "",
                listFooterLinks: [],
            },
            version: require('@/../package.json').version,
        }
    },
    async created(){
        await this.getSection();
        setInterval(() => {
            this.verifyMobile(); 
        }, 0);
        setInterval(() => {
            this.verifyMedium(); 
        }, 0);
    },
    computed: {
      ...mapState("restaurant", {
        restaurantState: (state) => state.restaurant
      })
    },
    methods: {
        async getSection(){
            await this.$axios.get('sub_section/footer')
            .then(response=>{
                this.modal = response.data.data;
                this.modal.listFooterLinks = this.modal.listFooterLinks.filter(e=>e.active);
                this.modal.listFooterLinks.reverse();
                if(this.modal.listFooterLinks.length<7){
                    var qnt = 7 - this.modal.listFooterLinks.length;
                    for(var i=0; i<=qnt; i++){
                        this.modal.listFooterLinks.push({
                            id: null,
                        });
                    }
                    this.modal.listFooterLinks.sort((a,b)=>{
                        b;
                        if(a.id==null)
                            return 1;
                        else return -1;
                    })
                }
            })
        },
        navigate(value){
            this.$router.push({name: value}).catch(()=>{});
            window.scrollTo({
            top: 0,
            behavior: 'smooth'
            });
        },
        showPaymentModal(){
            this.$refs.paymentCardModalRef.show();
        },
        hidePaymentModal(){
            this.$refs.paymentCardModalRef.hide();
        },
        verifyMobile(){
            this.isMobile = window.innerWidth <= 1000;
            return this.isMobile;
        },
        verifyMedium(){
            this.isMedium = (window.innerWidth < 1400) && (window.innerWidth > 1000);
            return this.isMedium;
        },
        getHourString(){
            var daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'HOLIDAY'];
            var now = new Date;
            var dayWeek = daysOfWeek[now.getDay()];
            
            var todayList = [];

            if(this.restaurantState.times==null || this.restaurantState.times.length==0) return;
            
            this.restaurantState.times.forEach(time => {
                if(time.special){
                    var firstDate = new Date(time.firstSpecialDate).setHours(0, 0, 0, 0);
                    var lastDate = new Date(time.lastSpecialDate).setHours(0, 0, 0, 0);
                    var todayDate = new Date().setHours(0, 0, 0, 0);
                    
                    if(firstDate <= todayDate && todayDate <= lastDate){
                        todayList.push(time);
                    }
                }
                else{
                    if(time.sequential){
                        var tempFirstDay = time.firstDay;
                        var tempLastDay = time.lastDay.replace('/HOLIDAY', '');
                        var first = daysOfWeek.indexOf(tempFirstDay);
                        var last = daysOfWeek.indexOf(tempLastDay);
                        // if((now.getDay() >= first && now.getDay() <= last) || (first>last)){
                        if((now.getDay() >= first && now.getDay() <= last || (first>last))){
                            todayList.push(time);
                        }
                    } 
                    else{
                        var firstDays = time.firstDay.split(', ');
                        var lastDay = time.lastDay.replace('/HOLIDAY', '');
                        if(lastDay == dayWeek){
                            todayList.push(time);
                        } 
                        else {
                            firstDays.forEach(fd => {
                                if(fd == dayWeek){
                                    todayList.push(time);
                                }
                            })
                        }
                    }
                }
            });

            todayList.forEach(time => {
                if(time.special){
                    todayList = [time];
                }
            });

            var strList = [];
            if(todayList.length>1){
                todayList.forEach(today => {
                    if(!today.closed){
                        strList.push(today.name + " -  Das " + today.opensAt + " às " + today.closesAt);
                    }
                })
            }
            else if(todayList.length==0){
                strList.push("Fechado hoje");
            }
            else{
                todayList.forEach(today => {
                    if(today.closed){
                        strList.push("Fechado hoje");
                    }
                    else{
                        strList.push("Aberto - Das " + today.opensAt + " às " + today.closesAt);
                    }
                })
            }

            return strList;
        }
    },
}
</script>

<style scoped>
.footer{
    height: 100vh;
    width: 100vw;
    /* background-image: url(../../assets/images/shared/footer.png); */
    background-size: cover;
    background-position: center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.footer-mobile{
    /* height: 400px; */
    width: 100vw;
    /* background-image: url(../../assets/images/shared/footer.png); */
    background-size: cover;
    background-position: center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.white-container{
    background-color: white;
    color: #191C1D;
    width: 450px;
    height: 450px;
    padding: 50px;
}
.white-container-mobile{
    background-color: white;
    color: #191C1D;
    /* width: 200px; */
    max-width: 200px;
    width: 50%;
    height: 300px;
    padding: 20px;
}
.container-col{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}
.container-text{
    font-size: 18px;
}
.container-text-mobile{
    font-size: 12px;
}
.logo{
    width: 200px;
    object-fit: cover;
}
.logo-mobile{
    width: 100%;
    object-fit: cover;
}
.line{
    width: 90px;
    border-bottom: 2px dashed #D4D4D4;
}
.content-row{
    width: 100vw;
    height: 450px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}
.content-row-mobile{
    width: 100vw;
    /* height: 300px; */
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.left-col{
    height: 450px;
    text-align: end;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}
.right-col{
    /* height: 100%; */
    height: 450px;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.left-col-mobile{
    /* height: 100%; */
    width: 25%;
    max-width: 200px;
    height: 300px;
    text-align: end;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}
.right-col-mobile{
    width: 25%;
    max-width: 200px;
    /* height: 100%; */
    height: 300px;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.footer-link{
    color: white;
}
.footer-link-mobile{
    color: white;
    font-size: 12px;
}
.footer-link:hover,
.footer-link-mobile:hover{
    cursor: pointer;
}

.foot{
    border-top: 1px solid white;
    /* height: 50px; */
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.foot-text{
    margin: 0px 50px;
}
.font-spacing{
    color: rgba(0, 0, 0, 0)
}
</style>