<template>
    <div :class="isMobile ? 'see-more-session-mobile' : 'see-more-session'">
        <div>
            <b-row class="font-nunito">
                <h6 v-if="isMobile" class="topic-title mx-auto"> {{ modal.sectionName }} </h6>
                <h4 v-else class="topic-title mx-auto"> {{ modal.sectionName }} </h4>
            </b-row>

            <b-row class="font-alice">
                <h1 class="display-6 title mx-auto" style="font-size:25px" v-if="isMobile"> {{ modal.title }} </h1>
                <!-- <h1 class="display-5 title mx-auto" v-else-if="isMedium"> Nossos Restaurantes </h1> -->
                <h1 class="display-4 title mx-auto" v-else> {{ modal.title }} </h1>
            </b-row>
        </div>

        <b-row class="items-row font-nunito my-3">
            <b-col v-if="modalOne" class="item">
                <b-row><img class="item-image" :src="modalOne.image" alt=""></b-row>
                <b-row v-if="isMobile" class="item-title-mobile"><b> {{ modalOne.title }} </b></b-row>
                <b-row v-else class="item-title"><b> {{ modalOne.title }} </b></b-row>
                <b-row v-if="isMobile" class="item-text-mobile">{{ modalOne.subtitle }}</b-row>
                <b-row v-else class="item-text">{{ modalOne.subtitle }}</b-row>
            </b-col>
            
            <b-col v-if="modalTwo" class="item">
                <b-row><img class="item-image" :src="modalTwo.image" alt=""></b-row>
                <b-row v-if="isMobile" class="item-title-mobile"><b> {{ modalTwo.title }} </b></b-row>
                <b-row v-else class="item-title"><b> {{ modalTwo.title }} </b></b-row>
                <b-row v-if="isMobile" class="item-text-mobile">{{ modalTwo.subtitle }}</b-row>
                <b-row v-else class="item-text">{{ modalTwo.subtitle }}</b-row>
            </b-col>
            
            <b-col v-if="modalThree" class="item">
                <b-row><img class="item-image" :src="modalThree.image" alt=""></b-row>
                <b-row v-if="isMobile" class="item-title-mobile"><b> {{ modalThree.title }} </b></b-row>
                <b-row v-else class="item-title"><b> {{ modalThree.title }} </b></b-row>
                <b-row v-if="isMobile" class="item-text-mobile">{{ modalThree.subtitle }}</b-row>
                <b-row v-else class="item-text">{{ modalThree.subtitle }}</b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>

export default {
    name: "SeeMoreSession",
    props: {
        modalProp: Object,
        didRequest: Boolean,
        loading: Boolean,
    },
    data(){
        return {
            isMobile: false,
            isMedium: false,
            modal: null,
            modalOne: {
                image: '',
                title: '',
                subtitle: '',
            },
            modalTwo: {
                image: '',
                title: '',
                subtitle: '',
            },
            modalThree: {
                image: '',
                title: '',
                subtitle: '',
            },
        }
    },
    created(){
        this.modal=this.modalProp;
        const intervalId = setInterval(()=>{
            if(this.didRequest){
                clearInterval(intervalId);
                this.modal=this.modalProp;
                this.modalOne = this.modalProp.listImages[0];
                this.modalTwo = this.modalProp.listImages[1];
                this.modalThree = this.modalProp.listImages[2];
            }
        }, 250)
        setInterval(() => {
            this.verifyMobile(); 
        }, 0);
        setInterval(() => {
            this.verifyMedium(); 
        }, 0);
    },
    methods: {
        verifyMobile(){
            this.isMobile = window.innerWidth <= 1000;
            return this.isMobile;
        },
        verifyMedium(){
            this.isMedium = (window.innerWidth < 1400) && (window.innerWidth > 1000);
            return this.isMedium;
        },
    }
}
</script>

<style scoped>
.see-more-session{
    /* height: 100vh;  */
    width: 100vw;
    background-color: #EEEEEE;
    color: #2E3132;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
}
.see-more-session-mobile{
    /* height: 400px;  */
    width: 100vw;
    background-color: #EEEEEE;
    color: #2E3132;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
}
.topic-title{
    font-weight: 300;
    border-bottom: 3px solid #2E3132;
}
.title{
    font-weight: 400;
}
.items-row{
    height: 80%;
}
.item{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.item-image{
    max-width:275px;
    width:17vw;
    max-height:275px;
    height:17vw;
    object-fit: cover;
    border-radius: 100%;
    box-shadow: 5px 5px 10px #CCCCCC;
    border-radius: 100%;
}
.item-title{
    font-size: 25px;
    margin: 10px 0px;
    width: 25vw;
    line-height: 30px;
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    text-align: center;
}
.item-text{
    font-size: 20px;
    width: 20vw;
    line-height: 25px;
    display: flex;
    justify-content: center;
    text-align: center;
}
.item-title-mobile{
    font-size: 15px;
    width: 25vw;
    line-height: 15px;
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    text-align: center;
}
.item-text-mobile{
    font-size: 12px;
    width: 20vw;
    line-height: 12px;
    display: flex;
    justify-content: center;
    text-align: center;
}
</style>