<template>
  <div :class="isMobile ? 'restaurant-mobile' : 'restaurant'">
    <b-col id="restaurant-home-session" class="content-col">
        <div>
            <b-row class="row font-nunito">
                <h6 v-if="isMobile" class="topic-title mx-auto">{{ modal.sectionName }}</h6>
                <h4 v-else class="topic-title mx-auto">{{ modal.sectionName }}</h4>
            </b-row>

            <b-row class="row font-alice">
                <h1 class="display-6 title" style="font-size:22px" v-if="isMobile">{{ modal.title }}</h1>
                <h1 class="display-4 title" v-else>{{ modal.title }}</h1>
            </b-row>
        </div>

        <div class="images-content">
            <b-col align-self="center" class="mb-4 ml-2">
                <p :class="isMobile ? 'h3' : 'h1'">
                    <b-icon @click="previousItem" icon="arrow-left-circle" class="arrow-button"></b-icon>
                </p>
            </b-col>
            <transition name="opacity"
                        enter-active-class="opacity-enter-active"
                        enter-class="opacity-enter-from"

                        leave-active-class="opacity-leave-active"
                        leave-to-class="opacity-leave-to"
                        >
            <b-row v-show="!isPassing" align-v="start" class="images font-nunito">
                <b-col class="center-col" v-for="(item, idx) in stepList" :key="idx">
                    <b-row>
                        <img v-if="isMobile" class="image-back-mobile" :src="item.image" alt="">
                        <img v-else class="image-back" :src="item.image" alt="">
                    </b-row>
                    <b-row>
                        <h6 v-if="isMobile" class="py-1"> {{ item.name }} </h6>
                        <h4 v-else class="py-2"> {{ item.name }} </h4>
                    </b-row>
                </b-col>
                <!-- <b-col class="center-col">
                    <b-row>
                        <img v-if="isMobile" class="image-back-mobile" :src="stepList[0].image" alt="">
                        <img v-else class="image-back" :src="stepList[0].image" alt="">
                    </b-row>
                    <b-row>
                        <h6 v-if="isMobile" class="py-1"> {{ stepList[0].name }} </h6>
                        <h4 v-else class="py-2"> {{ stepList[0].name }} </h4>
                    </b-row>
                </b-col>
                <b-col class="center-col">
                    <b-row>
                        <img v-if="isMobile" class="image-front-mobile" :src="stepList[1].image" alt="">
                        <img v-else class="image-front" :src="stepList[1].image" alt="">
                    </b-row>
                    <b-row>
                        <h6 v-if="isMobile" class="py-1"> {{ stepList[1].name }} </h6>
                        <h4 v-else class="py-2"> {{ stepList[1].name }} </h4>
                    </b-row>
                </b-col>
                <b-col class="center-col">
                    <b-row>
                        <img v-if="isMobile" class="image-back-mobile" :src="stepList[2].image" alt="">
                        <img v-else class="image-back" :src="stepList[2].image" alt="">
                    </b-row>
                    <b-row>
                        <h6 v-if="isMobile" class="py-1"> {{ stepList[2].name }} </h6>
                        <h4 v-else class="py-2"> {{ stepList[2].name }} </h4>
                    </b-row>
                </b-col> -->
            </b-row>
            </transition>
            <b-col align-self="center" class="mb-4 mr-2">
                <p :class="isMobile ? 'h3' : 'h1'">
                    <b-icon @click="nextItem" icon="arrow-right-circle" class="arrow-button"></b-icon>
                </p>
            </b-col>
        </div>

        <!-- <b-row class="mx-auto">
            <p :class="isMobile ? 'h3' : 'h1'">
                <b-icon @click="previousItem" icon="arrow-left-circle" class="arrow-button"></b-icon>
            </p>
            <div class="mx-5"></div>
            <p :class="isMobile ? 'h3' : 'h1'">
                <b-icon @click="nextItem" icon="arrow-right-circle" class="arrow-button"></b-icon>
            </p>
        </b-row> -->
        
    </b-col>
  </div>
</template>

<script>
export default {
    name: "RestaurantSession",
    props: {
        modalProp: Object,
        didRequest: Boolean,
        loading: Boolean,
    },
    data(){
        return {
            isMobile: false,
            isPassing: false,
            isMedium: false,
            modal: null,
            index: 0,
            stepIndex: 3,
            stepList: [],
            listRestaurants: [],
            dbListRestaurants: [],
            // restaurants:[
            //     {
            //         image: require("@/assets/images/home/restaurants/tiradentes.png"),
            //         name: "La Dirna Tiradentes",
            //     },
            //     {
            //         image: require("@/assets/images/home/restaurants/general.png"),
            //         name: "La Dirna General Carneiro",
            //     },
            //     {
            //         image: require("@/assets/images/home/restaurants/campolim.png"),
            //         name: "La Dirna Campolim",
            //     },
            //     {
            //         image: require("@/assets/images/home/restaurants/campolim.png"),
            //         name: "La Dirna Campolim",
            //     },
            //     {
            //         image: require("@/assets/images/home/restaurants/general.png"),
            //         name: "La Dirna General Carneiro",
            //     },
            //     {
            //         image: require("@/assets/images/home/restaurants/tiradentes.png"),
            //         name: "La Dirna Tiradentes",
            //     },
            // ]
        }
    },
    async created(){
        this.modal=this.modalProp;
        const intervalId = setInterval(()=>{
            if(this.didRequest){
                clearInterval(intervalId);
                this.modal=this.modalProp;
            }
        }, 250)
        setInterval(() => {
            this.verifyMobile();
        }, 0);
        setInterval(() => {
            this.verifyMedium(); 
        }, 0);
        await this.loadRestaurants();
        this.dbListRestaurants = new Array(...this.listRestaurants);

        if (this.isMobile) this.stepIndex=2;
        else this.stepIndex=3;
        
        setTimeout(()=>{
            this.stepList = this.listRestaurants.slice(0, this.stepIndex);
            this.resolveCarousel();
        }, 500)
    },
    methods: {
        resolveCarousel(){
            this.listRestaurants = this.dbListRestaurants;
            if((this.listRestaurants.length-1) % this.stepIndex != 0){
                var mod = (this.listRestaurants.length-1) % this.stepIndex;
                for(var i=0; i<mod; i++){
                    this.listRestaurants.push(this.listRestaurants[i]);
                }
            }
        },
        verifyMobile(){
            this.isMobile = window.innerWidth <= 1000;
            if (this.isMobile) this.stepIndex=2;
            else this.stepIndex=3;
            return this.isMobile;
        },
        verifyMedium(){
            this.isMedium = (window.innerWidth < 1400) && (window.innerWidth > 1000);
            return this.isMedium;
        },
        async loadRestaurants() {
            await this.$axios.get('/restaurant/detailed').then((response) => {
                this.listRestaurants = response.data.data;
            });
        },
        nextItem(){
            this.isPassing=true;
            setTimeout(() => {
                if(this.index+this.stepIndex >= this.listRestaurants.length)
                    this.index = 0;
                else
                    this.index+=this.stepIndex;
                this.stepList = this.listRestaurants.slice(this.index, this.index+this.stepIndex);
                this.isPassing=false;
            }, 250);
        },
        previousItem(){
            this.isPassing=true;
            setTimeout(() => {
                if(this.index-this.stepIndex < 0) 
                    this.index = this.listRestaurants.length-this.stepIndex;
                else this.index-=this.stepIndex;

                this.stepList = this.listRestaurants.slice(this.index, this.index+this.stepIndex);
                this.isPassing=false;
            }, 250);
        }
    },
}
</script>

<style scoped>
.opacity-enter-from{
  opacity: 0;
}
.opacity-enter-active{
  transition: all .25s ease;
}
.opacity-leave-to{
  opacity: 0;
}
.opacity-leave-active{
  transition: all .25s ease-in-out;
}

.restaurant{
    width: 100vw;
    /* height: 100vh; */
    color: #2E3132;
    padding: 50px;
    background-color: #EEEEEE;
}
.restaurant-mobile{
    width: 100vw;
    /* height: 400px; */
    color: #2E3132;
    padding: 30px;
    background-color: #EEEEEE;
}
.content-col{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.center-col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.topic-title{
    font-weight: 300;
    margin: 0px;
    border-bottom: 3px solid #2E3132;
}
.title{
    font-weight: 400;
    margin: 0px;
    width: 70vw;
    padding: 10px 0px 40px 0px;
}
.images-content{
    width: 100vw;
    display: flex;
    justify-content: center;
    min-height: 28vw;
    
}
.images{
    width: 85vw;
}
.image-back{
    /* width: 20vw;
    height: 20vw; */
    width: 25vw;
    height: 25vw;
    border-radius: 20px;
    object-fit: cover;
}
.image-front{
    width: 25vw;
    height: 25vw;
    border-radius: 20px;
    object-fit: cover;
}
.image-back-mobile{
    /* min-width: 80px;
    min-height: 80px;
    width: 16vw;
    height: 16vw;
    max-height: 150px;
    max-width: 150px; */

    min-width: 100px;
    min-height: 100px;
    width: 35vw;
    height: 30vw;
    max-height: 200px;
    max-width: 300px;
    border-radius: 20px;
    object-fit: cover;
}
.image-front-mobile{
    min-width: 100px;
    min-height: 100px;
    width: 35vw;
    height: 30vw;
    max-height: 200px;
    max-width: 300px;
    border-radius: 20px;
    object-fit: cover;
}
</style>