<template>
  <div v-if="foodList.length>0" :class="isMobile ? 'background-discount-mobile' : 'background-discount'">
        <transition name="discount"
          enter-active-class="discount-enter-active"
          leave-active-class="discount-leave-active"

          :enter-class="isToRight ? 'discount-to-right-enter-from' : 'discount-to-left-enter-from'"
          :leave-to-class="isToRight ? 'discount-to-right-leave-to' : 'discount-to-left-leave-to'"
        >
          <div v-show="!isPassing">
  <div id="discount-session" :class="isMobile ? 'discount-session-mobile' : 'discount-session'">
      <div class="discount-left-session" :style="'background-image: url('+ food.image + ')'" style="width: 45vw;"></div>

      <div :class="isMobile ? 'discount-right-session-mobile' : 'discount-right-session'" style="width: 55vw;">
        <div :class="isMobile ? 'content-discount-mobile' : 'content-discount'">
          <!-- <div class="topic-title-mobile" v-if="isMobile"> -->
          <div v-if="isMobile">
            <h6 class="font-nunito" style="width: fit-content;"><p style="border-bottom:3px solid white; font-weight:300">{{ food.sectionName }}</p></h6>
          </div>
          <!-- <div class="topic-title" v-else> -->
          <div v-else>
            <h4 class="font-nunito" style="width: fit-content;"><p style="border-bottom:3px solid white; font-weight:300">{{ food.sectionName }}</p></h4>
          </div>

          <b-row class="title font-alice">
            <h1 class="display-6" style="font-weight:400; font-size:22px" v-if="isMobile"> {{ food.name }} </h1>
            <h1 class="display-4" style="font-weight:400; font-size:42px" v-else-if="isMedium"> {{ food.name }} </h1>
            <h1 class="display-4" style="font-weight:400;" v-else> {{ food.name }} </h1>
          </b-row>

          <p v-if="isMobile" class="description-mobile font-nunito" style="font-weight: 300; font-size:14px"> {{ food.ingredients }} </p>
          <h5 v-else-if="isMedium" class="description-medium font-nunito" style="font-weight: 300;"> {{ food.ingredients }} </h5>
          <h4 v-else class="description font-nunito" style="font-weight: 300;"> {{ food.ingredients }} </h4>

          <b-col v-if="isMobile" class="p-0 d-flex justify-content-end" style="flex-direction:column">
            <b-row class="prices font-alice" style="margin:0px;padding:0px;margin-top:20px;">
              <h6 style="font-weight: 500;"> {{ food.priceName || '------' }} </h6>
            </b-row>
            <b-row  class="prices font-alice" style="margin:0px;padding:0px;">
              <b-col class="price"><h6> R$ {{food.price.toFixed(2).replaceAll(".", ",")}} </h6></b-col>
              <b-col class="price-old"><h6 style="font-weight: 300;"> R$ {{food.oldPrice.toFixed(2).replaceAll(".", ",")}} </h6></b-col>
            </b-row>
            <b-row v-if="food.priceTwo" class="prices font-alice" style="margin:0px;padding:0px;margin-top:20px;">
              <h6 style="font-weight: 500;"> {{ food.priceNameTwo || '------' }} </h6>
            </b-row>
            <b-row v-if="food.priceTwo" class="prices font-alice" style="margin:0px;padding:0px;">
              <b-col class="price"><h6> R$ {{food.priceTwo.toFixed(2).replaceAll(".", ",")}} </h6></b-col>
              <b-col class="price-old"><h6 style="font-weight: 300;"> R$ {{food.oldPriceTwo.toFixed(2).replaceAll(".", ",")}} </h6></b-col>
            </b-row>
          </b-col>
          <b-col v-else-if="isMedium" class="p-0 d-flex justify-content-end" style="flex-direction:column">
            <b-row class="prices font-alice" style="margin:0px;padding:0px;margin-top:20px;">
              <h4 style="font-weight: 500;"> {{ food.priceName || '------' }} </h4>
            </b-row>
            <b-row  class="prices font-alice" style="margin:0px;padding:0px;">
              <b-col class="price"><h2 style="font-size: 38px"> R$ {{food.price.toFixed(2).replaceAll(".", ",")}} </h2></b-col>
              <b-col class="price-old"><h4 style="font-weight: 300; transform: translateY(-4px);"> R$ {{food.oldPrice.toFixed(2).replaceAll(".", ",")}} </h4></b-col>
            </b-row>
            <b-row v-if="food.priceTwo" class="prices font-alice" style="margin:0px;padding:0px;margin-top:20px;">
              <h4 style="font-weight: 500;"> {{ food.priceNameTwo || '------' }} </h4>
            </b-row>
            <b-row v-if="food.priceTwo" class="prices font-alice" style="margin:0px;padding:0px;">
              <b-col class="price"><h2 style="font-size: 38px"> R$ {{food.priceTwo.toFixed(2).replaceAll(".", ",")}} </h2></b-col>
              <b-col class="price-old"><h4 style="font-weight: 300; transform: translateY(-4px);"> R$ {{food.oldPriceTwo.toFixed(2).replaceAll(".", ",")}} </h4></b-col>
            </b-row>
          </b-col>
          <b-col v-else class="p-0 d-flex justify-content-end" style="flex-direction:column">
            <b-row class="prices font-alice" style="margin:0px;padding:0px;margin-top:20px;">
              <h3 style="font-weight: 500;"> {{ food.priceName || '------' }} </h3>
            </b-row>
            <b-row class="prices font-alice" style="margin:0px;padding:0px;">
              <b-col class="price"><h1 style="font-size: 45px"> R$ {{food.price.toFixed(2).replaceAll(".", ",")}} </h1></b-col>
              <b-col class="price-old"><h3 style="font-weight: 300; transform: translateY(-4px);"> R$ {{food.oldPrice.toFixed(2).replaceAll(".", ",")}} </h3></b-col>
            </b-row>
            <b-row v-if="food.priceTwo" class="prices font-alice" style="margin:0px;padding:0px;margin-top:20px;">
              <h3 style="font-weight: 500;"> {{ food.priceNameTwo || '------' }} </h3>
            </b-row>
            <b-row v-if="food.priceTwo" class="prices font-alice" style="margin:0px;padding:0px;">
              <b-col class="price"><h1 style="font-size: 45px"> R$ {{food.priceTwo.toFixed(2).replaceAll(".", ",")}} </h1></b-col>
              <b-col class="price-old"><h3 style="font-weight: 300; transform: translateY(-4px);"> R$ {{food.oldPriceTwo.toFixed(2).replaceAll(".", ",")}} </h3></b-col>
            </b-row>
          </b-col>

          <b-row v-if="food.buttonActive" style="margin-top:30px;">
            <b-button :href="food.buttonUrl" variant="outline-light" :size="isMobile ? 'sm' : 'lg'" class="btn-discount font-alice">{{ food.buttonText }}</b-button>
          </b-row>
        </div>
          
        
        <b-row v-if="foodList.length>1" align-v="center" class="btn-group-nav-pages" :class="!isMedium && !isMobile ? 'mt-5 py-5' : ''">
          <p :class="isMobile ? 'h3' : 'h1'">
            <b-icon v-if="foodList.length>1" icon="arrow-left-circle" @click="previousFood" class="arrow-button"></b-icon>
            <b-icon v-else icon="arrow-left-circle" style="opacity:0.5; cursor:default" class="arrow-button"></b-icon>
          </p>
          <p :class="isMobile ? 'h3' : 'h1'">
            <b-icon v-if="foodList.length>1" icon="arrow-right-circle" @click="nextFood" class="arrow-button"></b-icon>
            <b-icon v-else icon="arrow-right-circle" style="opacity:0.5; cursor:default" class="arrow-button"></b-icon>
          </p>
        </b-row>
      </div>
  </div>
          </div>
        </transition>
  </div>
</template>

<script>
export default {
    name: "DiscountSession",
    props: {
        modalProp: Array,
        didRequest: Boolean,
        loading: Boolean,
    },
    created(){
      this.modal=this.modalProp;
      const intervalId = setInterval(()=>{
          if(this.didRequest){
              clearInterval(intervalId);
              this.modal=this.modalProp;
              this.foodList = [];
              this.modal.forEach(section=>{
                var temp = null;
                if(section.promotion.length==1)
                  temp = {
                    image: section.mainImage,
                    sectionName: section.sectionName,
                    name: section.promotion[0].name,
                    ingredients: section.subtitle,
                    priceName: section.promotion[0].priceName,
                    price: section.promotion[0].finalValue/100,
                    oldPrice: section.promotion[0].originalValue/100,
                    buttonActive: section.buttonActive,
                    buttonText: section.buttonText,
                    buttonUrl: section.buttonUrl,
                  }
                if(section.promotion.length>1)
                  temp = {
                    image: section.mainImage,
                    sectionName: section.sectionName,
                    name: section.promotion[0].name,
                    ingredients: section.subtitle,
                    priceName: section.promotion[0].priceName,
                    price: section.promotion[0].finalValue/100,
                    oldPrice: section.promotion[0].originalValue/100,
                    priceNameTwo: section.promotion[1].priceName,
                    priceTwo: section.promotion[1].finalValue/100,
                    oldPriceTwo: section.promotion[1].originalValue/100,
                    buttonActive: section.buttonActive,
                    buttonText: section.buttonText,
                    buttonUrl: section.buttonUrl,
                  }
                this.foodList.push(temp)
              })
              if(this.foodList.length>0)
                this.food = this.foodList[this.index];
          }
      }, 250)
      setInterval(() => {
        this.verifyMobile(); 
      }, 0);
      setInterval(() => {
        this.verifyMedium(); 
      }, 0);
    },
    methods: {
      navigate(value){
        this.$router.push({name: value}).catch(()=>{});
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      },
      verifyMobile(){
          this.isMobile = window.innerWidth <= 1000;
          return this.isMobile;
      },
      verifyMedium(){
          this.isMedium = (window.innerWidth < 1400) && (window.innerWidth > 1000);
          return this.isMedium;
      },
      nextFood(){
        this.isToRight = true;
        this.isPassing = true;
        setTimeout(() => {
          if(this.index + 1 >= this.foodList.length)
           this.index = 0;
          else
           this.index++;
          this.food = this.foodList[this.index];
          this.isPassing = false;
        }, 250);
      },
      previousFood(){
        this.isToRight = false;
        this.isPassing = true;
        setTimeout(()=>{
          if(this.index - 1 < 0)
           this.index = this.foodList.length-1;
          else
           this.index--;
          this.food = this.foodList[this.index];
          this.isPassing = false;
        }, 250);
      }
    },
    data(){
      return {
        isMobile: false,
        isMedium: false,
        isToRight: true,
        isPassing: false,
        modal: null,
        index: 0,
        food: null,
        foodList: []
      }
    }
}
</script>

<style scoped>
.background-discount{
  background-color: #520000;
  /* height: 100vh; */
  min-height: 600px;
  height: 750px;
  width: 100vw;
}
.background-discount-mobile{
  background-color: #520000;
  /* height: 50vh; */
  min-height: 500px;
  width: 100vw;
}

.discount-to-right-enter-from{
  opacity: 0;
  transform: translateX(100px);
}
.discount-to-right-leave-to{
  opacity: 0;
  transform: translateX(100px);
}
.discount-to-left-enter-from{
  opacity: 0;
  transform: translateX(-100px);
}
.discount-to-left-leave-to{
  opacity: 0;
  transform: translateX(-100px);
}
.discount-enter-active{
  transition: all .25s ease;
}
.discount-leave-active{
  transition: all .25s ease-in-out;
}


.discount-session{
  display: flex;
  /* height: 100vh; */
  height: 750px;
}
.discount-session-mobile{
  display: flex;
  /* height: 50vh; */
  min-height: 500px;
}
.discount-left-session{
  /* background-image: url(../../../assets/images/home/discount_left_session.png); */
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
}
.discount-right-session{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  background-color: #520000;
  color: white;
  padding: 75px 100px;
  text-align: start;
}
.discount-right-session-mobile{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  background-color: #520000;
  color: white;
  padding: 20px 40px;
  text-align: start;
}
.content-discount{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 60vh; */
  min-height: 500px;
  height:100%;
  padding: 0px 0px 40px 0px;
}
.content-discount-mobile{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 35vh; */
  height:100%;
  padding: 0px 0px 20px 0px;
}
.topic-title{
  margin: 0px;
}
.topic-title h3 p{
  border-bottom: 2px solid white;
}
.topic-title-mobile{
  margin: 0px;
}
.topic-title-mobile h6 p{
  border-bottom: 2px solid white;
}
.title{
  display: flex;
  justify-content: flex-start;
  margin: 0px;
}
.description{
  width: 500px;
}
.description-medium{
  width: 400px;
}
.description-mobile{
  min-height: 80px;
  /* height: 95px;
  overflow-y: auto;
  margin: 0px 0px 0px 0px; */
}
.prices{
  display: flex;
  align-items: flex-end;  
  padding: 10px 0px;
  margin: 0px;
  width: 40vw; max-width: 400px;
}
.price{
  padding: 0px;
}
.price-old{
  padding: 0px;
  text-decoration: line-through;
}
.btn-discount{
  margin: 0px 20px 0px 20px;
}
.btn-group-nav-pages{
  display: flex;
  justify-content: space-between;
}
</style>