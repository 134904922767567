<template>
  <b-col :class="(isMobile ? ' header-mobile ' : ' header ') + (scrollPosition > 50 && !isMobile ? ' background scrolled-header ' : '')">

    <b-row class="localization-header" v-if="!isMobile" align-h="around">
      <b-col style="max-width: max-content">
        <b-row class="item-row">
          <CardModal :restaurants="restaurants"/>
        </b-row>
      </b-col>
      <!-- <b-col v-if="isMedium">
        <b-row class="item-row">
           <b-button @click="showHourModal" variant="outline-light" class="toggle-icon px-1 py-0 mx-1" style="border:0px solid white;" @click.prevent>
            <p class="h6 my-auto p-2"><b-icon icon="clock"></b-icon></p>
          </b-button>
          <b-modal ref="hourModal" modal-class="modal-class" title-class="modal-title-class font-alice" size="md" hide-footer title="Horário de funcionamento">
            <div class="d-block text-center font-nunito">
              <h6 v-for="(str, idx) in getHourString()" :key="idx"> {{ str }} </h6>
            </div>
          </b-modal>
        </b-row>
      </b-col> -->
      <b-col v-if="!isMedium" style="display:flex; justify-content:center; max-width: max-content">
          <b-row style="width:30vw" class="item-row">
            <b-row style="width:100%; display:flex; justify-content:center">
              <b-button id="show-btn" size="sm" variant="link" style="color:white;"  @click="showHourModal">
                <b-row style="min-width: max-content">
                  <b-icon class="h5" icon="clock"></b-icon>
                  <h6 class="mx-3 wrapper-overflow font-nunito"> {{ hourStrJoined }} </h6>
                  <b-icon icon="chevron-down"></b-icon>
                </b-row>
              </b-button>
            </b-row>
          </b-row>
        <b-modal ref="hourModal" modal-class="modal-class" title-class="modal-title-class font-alice" size="md" hide-footer title="Horário de funcionamento">
          <div class="d-block text-center font-nunito">
            <h6 v-for="(str, idx) in getHourString()" :key="idx"> {{ str }} </h6>
            <!-- <h6>Almoço: 11:00 às 15:00</h6>
            <h6>Jantar: 18:00 às 22:00</h6> -->
          </div>
        </b-modal>
      </b-col>
      <b-col style="max-width: max-content">
        <b-row class="item-row">
          <b-icon class="h5 mx-2" icon="telephone"></b-icon>
          <h6 class="wrapper-overflow font-nunito">{{ restaurantState.phone | formatPhoneNumber }}</h6>
          <!-- <h6 class="wrapper-overflow">+55 (14) 3985 - 5555</h6> -->
        </b-row>
      </b-col>
    </b-row>

    <b-col :class="scrollPosition > 50 && !isMobile ? 'scrolled-nav-header' : 'nav-header'">

      <b-row v-if="isMobile" class="logo mx-3"><img @click="$router.push('/')" style="cursor:pointer;" class="logo-mobile" src="../../assets/images/shared/logo_white.png" alt=""></b-row>
      <b-row v-else class="logo mx-3">
        <img @click="$router.push('/')" :style="scrollPosition > 50 && !isMobile ? 'height:30px;' : ''"
        style="cursor:pointer;" src="../../assets/images/shared/logo_white.png" alt=""></b-row>

      <b-row v-if="!isMobile" class="mx-3 font-nunito">
        <b-col class="nav-link-col">
          <a @click="$router.push({name:'home'})" class="nav-link-custom" :style="$route.name === 'home' ? 'border-bottom: 2px solid #FFB000; font-weight:500;' : ''" :class="scrollPosition > 50 && !isMobile ? 'scrolled-font' : ''">Início</a>
        </b-col>
        <b-col class="nav-link-col">
          <a @click="$router.push({name:'menu'})" class="nav-link-custom" :style="$route.name === 'menu' ? 'border-bottom: 2px solid #FFB000; font-weight:500;' : ''" :class="scrollPosition > 50 && !isMobile ? 'scrolled-font' : ''">Cardápio</a>
        </b-col>
        <b-col class="nav-link-col">
          <a @click="$router.push({name:'restaurante'})" class="nav-link-custom" :style="$route.name === 'restaurante' ? 'border-bottom: 2px solid #FFB000; font-weight:500;' : ''" :class="scrollPosition > 50 && !isMobile ? 'scrolled-font' : ''">Restaurantes</a>
        </b-col>
        <b-col cols="3" class="nav-link-col">
          <a @click="$router.push({name:'sobre'})" class="nav-link-custom" :style="$route.name === 'sobre' ? 'border-bottom: 2px solid #FFB000; font-weight:500;' : ''" :class="scrollPosition > 50 && !isMobile ? 'scrolled-font' : ''">Sobre nós</a>
        </b-col>
        <b-col class="nav-link-col">
          <a @click="$router.push({name:'contato'})" class="nav-link-custom" :style="$route.name === 'contato' ? 'border-bottom: 2px solid #FFB000; font-weight:500;' : ''" :class="scrollPosition > 50 && !isMobile ? 'scrolled-font' : ''">Contato</a>  
        </b-col>
      </b-row>

      <b-row v-else style="width:60%; display:flex; justify-content:end" class="mx-2">
        <CardModalMobile :restaurants="restaurants"/>
        
        <b-button @click="showHourModal" variant="outline-light" class="toggle-icon px-1 py-0 mx-1" style="border:0px solid white;" @click.prevent>
          <p class="h6 my-auto"><b-icon icon="clock"></b-icon></p>
        </b-button>
        <b-modal ref="hourModal" modal-class="modal-class" title-class="modal-title-class font-alice" size="sm" hide-footer title="Horário de funcionamento">
          <div class="d-block text-center font-nunito">
            <h6 v-for="(str, idx) in getHourString()" :key="idx"> {{ str }} </h6>
            <!-- <h6>Almoço: 11:00 às 15:00</h6>
            <h6>Jantar: 18:00 às 22:00</h6> -->
          </div>
        </b-modal>
        
        <b-button @click="showPhoneModal" variant="outline-light" class="toggle-icon px-1 py-0 mx-1" style="border:0px solid white;" @click.prevent>
          <p class="h6 my-auto"><b-icon icon="telephone"></b-icon></p>
        </b-button>
        <b-modal ref="phoneModal" modal-class="modal-class" title-class="modal-title-class font-alice" size="sm" hide-footer title="Nosso telefone">
          <div class="d-block text-center font-nunito">
            <h6>{{ restaurantState.phone | formatPhoneNumber }}</h6>
            <!-- <h6>+55 (14) 3985 - 5555</h6> -->
          </div>
        </b-modal>
        
        <b-button v-b-toggle href="#sidebar" variant="outline-light" class="toggle-icon px-1 py-0 mx-1" style="border:0px solid white;" @click.prevent>
          <p class="h5 my-auto"><b-icon icon="list"></b-icon></p>
        </b-button>
      </b-row>

      <b-sidebar
      id="sidebar"
      backdrop-variant="dark"
      backdrop
      width="200px"
      right
      shadow>
        <b-col class="px-5 py-0 font-nunito">
          <b-row class="nav-link-col py-2">
            <a @click="$router.push({name:'home'})" class="nav-link-custom-mobile">Início</a>
          </b-row>
          <b-row class="nav-link-col py-2">
            <a @click="$router.push({name:'menu'})" class="nav-link-custom-mobile">Cardápio</a>
          </b-row>
          <b-row class="nav-link-col py-2">
            <a @click="$router.push({name:'restaurante'})" class="nav-link-custom-mobile">Restaurantes</a>
          </b-row>
          <b-row class="nav-link-col py-2">
            <a @click="$router.push({name:'sobre'})" class="nav-link-custom-mobile">Sobre nós</a>
          </b-row>
          <b-row class="nav-link-col py-2">
            <a @click="$router.push({name:'contato'})" class="nav-link-custom-mobile">Contato</a>  
          </b-row>
        </b-col>
      </b-sidebar>

    </b-col>
  </b-col>
</template>

<script>
import CardModal from './CardModal.vue'
import CardModalMobile from './CardModalMobile.vue'
import { mapState } from 'vuex'

export default {
  name: 'DefaultHeader',
  components: {
    CardModal,
    CardModalMobile,
  },
  computed: {
    ...mapState("restaurant", {
      restaurantState: (state) => state.restaurant
    }),
    ...mapState("restaurant", {
      hasSelectedState: (state) => state.hasSelected
    })
  },
  async created(){
    await this.loadRestaurants();
    
    
    this.hourStrJoined = this.getHourString().join(" | ");
    setInterval(() => {
      this.verifyMobile(); 
    }, 0);
    setInterval(() => {
      this.verifyMedium(); 
    }, 0);
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
    this.updateScroll();
  },
  methods: {
    updateScroll() {
       this.scrollPosition = window.scrollY
    },
    verifyMobile(){
      this.isMobile = window.innerWidth <= 1000;
      return this.isMobile;
    },
    verifyMedium(){
      this.isMedium = (window.innerWidth < 1400) && (window.innerWidth > 1000);
      return this.isMedium;
    },
    showHourModal(){
      this.$refs.hourModal.show();
    },
    showPhoneModal(){
      this.$refs.phoneModal.show();
    },
    async loadRestaurants() {
      await this.$axios.get('/restaurant/detailed').then((response) => {
        this.restaurants = response.data.data;
        this.restaurants.sort((a, b) => a.name.localeCompare(b.name));
        this.setDefaultRestaurant();
      });
    },
    setDefaultRestaurant(){
      if(!this.restaurants) return;
      var localState = JSON.parse(localStorage.getItem('store'));
      var finded = null;
      if(localState==null || localState==undefined || localState.restaurant==null || localState.restaurant==undefined || !localState.hasSelected){
        // if(!this.hasSelectedState)
        finded = this.restaurants.find(e => e.name.toLowerCase().includes("tiradentes"));
        if(!finded)
          finded = this.restaurants[0];
        this.$store.commit("restaurant/DEFAULT_STORE_RESTAURANT", finded);
      }
      else{
        finded = this.restaurants.find(e => e.id == localState.restaurant.id);
        if(!finded){
          finded = this.restaurants.find(e => e.name.toLowerCase().includes("tiradentes"));
          if(!finded)
            finded = this.restaurants[0];
          this.$store.commit("restaurant/DEFAULT_STORE_RESTAURANT", finded);
          return;
        }
        this.$store.commit("restaurant/STORE_RESTAURANT", finded);
      }
    },
    convertDate(value){
      var str = value.substring(8, 10) + "/" + value.substring(5, 7);
      return str;
    },
    getHourString(){
      var daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'HOLIDAY'];
      var now = new Date;
      var dayWeek = daysOfWeek[now.getDay()];
      
      var todayList = [];

      if(this.restaurantState.times==null || this.restaurantState.times.length==0) return;
            
      this.restaurantState.times.forEach(time => {
        if(time.special){
            var firstDate = new Date(time.firstSpecialDate).setHours(0, 0, 0, 0);
            var lastDate = new Date(time.lastSpecialDate).setHours(0, 0, 0, 0);
            var todayDate = new Date().setHours(0, 0, 0, 0);
            
            if(firstDate <= todayDate && todayDate <= lastDate){
              todayList.push(time);
            }
        }
        else{
          if(time.sequential){
              var tempFirstDay = time.firstDay;
              var tempLastDay = time.lastDay.replace('/HOLIDAY', '');
              var first = daysOfWeek.indexOf(tempFirstDay);
              var last = daysOfWeek.indexOf(tempLastDay);
              if((now.getDay() >= first && now.getDay() <= last) || (first>last)){
                  todayList.push(time);
              }
          }
          else{
            var firstDays = time.firstDay.split(', ');
            var lastDay = time.lastDay.replace('/HOLIDAY', '');
            if(lastDay == dayWeek){
                todayList.push(time);
            } 
            else{
              firstDays.forEach(fd => {
                  if(fd == dayWeek){
                      todayList.push(time);
                  }
              })
            }
            if(time.special && !todayList.includes(time)){
              todayList.push(time)
            }
          }
        }
      });

      todayList.forEach(time => {
        if(time.special){
          todayList = [time];
        }
      });

      var strList = [];

      if(todayList.length > 1){
        todayList.forEach(today => {
          if(today.special){
            if(today.closed){
              if(this.convertDate(today.firstSpecialDate) == this.convertDate(today.lastSpecialDate))
                strList.push(today.name + ": " + this.convertDate(today.firstSpecialDate) + " - Fechado");
              else
                strList.push(today.name + ": " + this.convertDate(today.firstSpecialDate) + " à " + this.convertDate(today.lastSpecialDate) + " - Fechado");
              }
            else{
              if(this.convertDate(today.firstSpecialDate) == this.convertDate(today.lastSpecialDate))
                strList.push(today.name + ": " + this.convertDate(today.firstSpecialDate) + " - Das " + today.opensAt + " às " + today.closesAt);
              else
                strList.push(today.name + ": " + this.convertDate(today.firstSpecialDate) + " à " + this.convertDate(today.lastSpecialDate) + " - Das " + today.opensAt + " às " + today.closesAt);
            }
          }
          else{
            if(today.closed){
                strList.push(today.name + ": Fechado");
            }
            else{
                strList.push(today.name + ": " + today.opensAt + " às " + today.closesAt);
            }
          }
        })
      }
      else if(todayList.length==0){
        strList.push("Fechado hoje");
      }
      else{
        todayList.forEach(today => {
            if(today.closed){
                strList.push("Fechado hoje");
            }
            else{
                strList.push(today.name + ": " + today.opensAt + " às " + today.closesAt);
            }
        })
      }

      return strList;
    },
  },

  data(){
    return {
      scrollPosition: null,
      isMobile: false,
      isMedium: false,
      hourStrJoined: '',
      restaurant: {
        name: "Tauste",
        phone: "",
        cep: "",
        street: "",
        district: "",
        number: "",
        complement: "",
        city: "",
        state: ""
      },
      restaurants: [],
      // restaurants: [
      //     {
      //       image: require("@/assets/images/restaurant/units/colinas_restaurant.png"),
      //       name: "La Dirna Colinas",
      //       phone: "",
      //       cep: "",
      //       street: "",
      //       district: "",
      //       number: "",
      //       complement: "",
      //       city: "",
      //       state: ""
      //     },
      //     {
      //       image: require("@/assets/images/restaurant/units/general_carneiro_restaurant.png"),
      //       name: "La Dirna General Carneiro",
      //       phone: "",
      //       cep: "",
      //       street: "",
      //       district: "",
      //       number: "",
      //       complement: "",
      //       city: "",
      //       state: ""
      //     },
      //     {
      //       image: require("@/assets/images/restaurant/units/itavuvu_restaurant.png"),
      //       name: "La Dirna Itavuvu",
      //       phone: "",
      //       cep: "",
      //       street: "",
      //       district: "",
      //       number: "",
      //       complement: "",
      //       city: "",
      //       state: ""
      //     },
      //     {
      //       image: require("@/assets/images/restaurant/units/duque_restaurant.png"),
      //       name: "La Dirna Duque",
      //       phone: "",
      //       cep: "",
      //       street: "",
      //       district: "",
      //       number: "",
      //       complement: "",
      //       city: "",
      //       state: ""
      //     },
      //     {
      //       image: require("@/assets/images/restaurant/units/rio_branco_restaurant.png"),
      //       name: "La Dirna Rio Branco",
      //       phone: "",
      //       cep: "",
      //       street: "",
      //       district: "",
      //       number: "",
      //       complement: "",
      //       city: "",
      //       state: ""
      //     },
      //     {
      //       image: require("@/assets/images/restaurant/units/tiradentes_restaurant.png"),
      //       name: "La Dirna Tiradentes",
      //       phone: "",
      //       cep: "",
      //       street: "",
      //       district: "",
      //       number: "",
      //       complement: "",
      //       city: "",
      //       state: ""
      //     },
      //     {
      //       image: require("@/assets/images/restaurant/units/campolim_restaurant.png"),
      //       name: "La Dirna Campolim",
      //       phone: "",
      //       cep: "",
      //       street: "",
      //       district: "",
      //       number: "",
      //       complement: "",
      //       city: "",
      //       state: ""
      //     },
      //     {
      //       image: require("@/assets/images/restaurant/units/ozanan_restaurant.png"),
      //       name: "La Dirna Ozanan",
      //       phone: "",
      //       cep: "",
      //       street: "",
      //       district: "",
      //       number: "",
      //       complement: "",
      //       city: "",
      //       state: ""
      //     },
      //   ]
    }
  }
}
</script>

<style scoped>
.wrapper-overflow{
  max-width: 35vw;
  text-align: start;
  overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
            line-clamp: 1; 
    -webkit-box-orient: vertical;
}
.background{
  background-color: rgba(0, 0, 0, 0.80);
  /* background: linear-gradient(#000000, #00000050); */
}
.header{
  z-index: 2;
  position:fixed;
  top:0px;
  padding: 0px;
  color: white;
  width: 100vw;
  height: 200px;
}
.scrolled-header{
  z-index: 2;
  position:fixed;
  top:0px;
  padding: 0px;
  color: white;
  width: 100vw;
  max-height: 100px;
}
.header-mobile{ 
  /* z-index: 2;
  position:fixed;
  top:0px; */
  padding: 0px;
  color: white;
  width: 100vw;
  height: 125px;
}
.localization-header{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 1px solid white;
  max-height: 50px;
  width: 100vw;
  padding: 10px 0px;
}
.item-row{
  display: flex;
  justify-content: center;
  align-items: center;
}
.restaurant-location{
  max-width: 35vw;
  text-align: start;
  overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
            line-clamp: 1; 
    -webkit-box-orient: vertical;
}
.nav-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 150px;
  width: 100vw;
}
.scrolled-nav-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100vw;
}
.nav-link-col{
  width: 150px;
}
.nav-link-custom{
  font-size: 20px;
  color: white;
  cursor: pointer;
}
.scrolled-font{
  font-size: 15px;
  color: white;
  cursor: pointer;
}
.nav-link-custom-mobile{
  color: black;
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-bottom: 2px solid #FFB000;
  transition: 0.25s;
}
.nav-link-custom:hover{
  text-decoration: none;
  border-bottom: 2px solid #FFB000;
}
.nav-link-custom-mobile:hover{
  text-decoration: none;
  border: 2px solid #FFB000;
  border-radius: 10px;
  box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.25);
  transform: translate(5px, -5px)
}

.logo{
  display: flex;
  justify-content: flex-start;
  width: 40vw;
}
.logo-mobile{
  width: 25vw;
  max-width: 200px;
}


.toggle-icon{
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s;
}
.toggle-icon:hover{
  color: black;
}


ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
