<template>
  <div class="menu">

    <MaintenanceModal ref="maintenance"/>

    <LoadingView v-show="loadingState"/>

    <MenuSession v-if="showFirst" :modalProp="firstSection" :loading="loading" :didRequest="didRequest"/>

    <LunchSession v-if="!secondSection.length==0" :modalProp="secondSection" :imageProp="secondSectionImages" :loading="loading" :didRequest="didRequest"/>

    <DinnerSession v-if="!hideDinnerSession" @hideSession="hideDinner"/>

    <SeeMoreSession v-if="thirdSection.active" :modalProp="thirdSection" :loading="loading" :didRequest="didRequest"/>

    <DefaultFooter/>
    
  </div>

</template>

<script>
// @ is an alias to /src
import MenuSession from '@/views/menu/sessions/MenuSession.vue'
import LunchSession from './sessions/LunchSession.vue'
import DinnerSession from './sessions/DinnerSession.vue'
import SeeMoreSession from './sessions/SeeMoreSession.vue'
import DefaultFooter from '@/components/shared/DefaultFooter.vue'
import LoadingView from '@/components/shared/LoadingView.vue'
import MaintenanceModal from '@/components/shared/MaintenanceModal.vue'
import { mapState } from 'vuex'

export default {
  name: 'MenuView',
  components: {
    MenuSession,
    LunchSession,
    DinnerSession,
    SeeMoreSession,
    DefaultFooter,
    LoadingView,
    MaintenanceModal,
  },
  async mounted(){
    try{
      this.$store.commit("loading/STORE_LOADING");
      window.scrollTop=0;
      document.body.style.overflow = 'hidden'
      await this.loadRestaurants();
      await this.getMainSection();
      await this.getSection();
      this.verifyMaintenance();
      this.showFirst=true;
      setTimeout(()=>{
          document.body.style.overflow = ''
          this.$store.commit("loading/STORE_LOADED");
      }, 500)
    }
    catch(error){
      this.$refs.maintenance.show();
      error;
    }
  },
  data(){
      return {
          showFirst: false,
          hideDinnerSession:false,
          sectionId: null,
          modalSections: [],
          firstSection: {
            mainImage: '',
            sectionName: '',
            title: '',
            subtitle: '',
            active: false,
          },
          secondSectionImages: [],
          secondSection: [{
            mainImage: '',
            sectionName: '',
            title: '',
            subtitle: '',
            active: false,
            listImages: [],
          }],
          thirdSection: {
            mainImage: '',
            sectionName: '',
            title: '',
            subtitle: '',
            active: false,
          },
          loading: false,
          didRequest: false,
      }
  },
  computed: {
    ...mapState("loading", {
        loadingState: (state) => state.loading
    }),
  },
  methods: {
      verifyMaintenance(){
        if(
          !this.firstSection
        ) throw 'First section not found';
      },
      async loadRestaurants() {
        await this.$axios.get('/restaurant/detailed').then((response) => {
          this.restaurants = response.data.data;
          this.restaurants.sort((a, b) => a.name.localeCompare(b.name));
          this.setDefaultRestaurant();
        });
      },
      setDefaultRestaurant(){
        if(!this.restaurants) return;
        var localState = JSON.parse(localStorage.getItem('store'));
        var finded = null;
        if(localState==null || localState==undefined || localState.restaurant==null || localState.restaurant==undefined || !localState.hasSelected){
          // if(!this.hasSelectedState)
          finded = this.restaurants.find(e => e.name.toLowerCase().includes("tiradentes"));
          if(!finded)
            finded = this.restaurants[0];
          this.$store.commit("restaurant/DEFAULT_STORE_RESTAURANT", finded);
        }
        else{
          finded = this.restaurants.find(e => e.id == localState.restaurant.id);
          if(!finded){
            finded = this.restaurants.find(e => e.name.toLowerCase().includes("tiradentes"));
            if(!finded)
              finded = this.restaurants[0];
            this.$store.commit("restaurant/DEFAULT_STORE_RESTAURANT", finded);
            return;
          }
          this.$store.commit("restaurant/STORE_RESTAURANT", finded);
        }
      },
      hideDinner(){this.hideDinnerSession=true;},
      async getMainSection(){
          this.loading=true;
          await this.$axios.get("main_sections")
          .then(response => {
              this.sectionId = response.data.data.find(e=>e.name=="Cardápio").id;
          }).finally(()=>{
              this.loading=false;
          })
      },
      async getSection(){
          await this.$axios.get("main_section/" + this.sectionId + "/sub_section")
          .then(async response => {
              this.modalSections = response.data.data;
              this.firstSection = this.modalSections.find(e=>e.type=="HEADER");
              this.thirdSection = this.modalSections.find(e=>e.type=="BODY" && e.position==1);

              await this.$axios.get("main_section/sub_section/" + this.thirdSection.id + "/images")
              .then(response => {
                  this.thirdSection.listImages = response.data.data;
              });

              var localState = JSON.parse(localStorage.getItem('store'));

              await this.$axios.get("restaurant/"+ localState.restaurant.id +"/lunch/images")
              .then(response => {
                  this.secondSectionImages = response.data.data;
              });

              await this.$axios.get("restaurant/" + localState.restaurant.id + "/menu_price")
              .then(response => {
                  this.secondSection = response.data.data;
              });

          }).finally(()=>{
              this.loading=false;
              this.didRequest=true;
          });
      },
  },
}
</script>

<style>
.menu{color: white;}
</style>