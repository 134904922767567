<template>
  <div id="about-us-home-session" :class="isMobile ? 'about-us-mobile' : 'about-us'">
    <b-row class="row mx-auto">
            <img :class="isMobile ? 'image-mobile' : 'image'" :src="modal.mainImage" alt="">
            <div :class="(isMobile ? 'text-container-mobile' : 'text-container') + ' mx-auto'">
                <b-row class="row font-nunito">
                    <h6 v-if="isMobile" class="topic-title mx-auto">{{ modal.sectionName }}</h6>
                    <h3 v-else class="topic-title mx-auto">{{ modal.sectionName }}</h3>
                </b-row>

                <b-row class="row font-alice">
                    <h1 class="display-6 title" style="font-size:22px" v-if="isMobile"> {{ modal.title }} </h1>
                    <h1 class="display-4 title" v-else-if="isMedium"> {{ modal.title }} </h1>
                    <h1 class="display-3 title" v-else> {{ modal.title }} </h1>
                </b-row>

                <b-row class="text mx-auto font-nunito">
                    <p style="width:35vw" :class="isMobile ? '' : 'h5'">
                        {{ modal.subtitle }}
                    </p>
                </b-row>
                
                <b-row v-if="modal.buttonActive" :class="(isMobile ? 'btn-container-mobile' : 'btn-container') + ' mx-auto font-alice'">
                    <b-button :href="modal.buttonUrl" variant="outline-light" :size="isMobile ? 'sm' : 'lg'" class="mx-auto">{{ modal.buttonText }}</b-button>
                </b-row>
            </div>
            <img :class="isMobile ? 'image-mobile' : 'image'" :src="modal.secondaryImage" alt="">
    </b-row>
  </div>
</template>

<script>
export default {
    name: "AboutUsSession",
    props: {
        modalProp: Object,
        didRequest: Boolean,
        loading: Boolean,
    },
    data(){
        return {
            isMobile: false,
            isMedium: false,
            modal: null,
        }
    },
    created(){
        this.modal=this.modalProp;
        const intervalId = setInterval(()=>{
            if(this.didRequest){
                clearInterval(intervalId);
                this.modal=this.modalProp;
            }
        }, 250)
        setInterval(() => {
            this.verifyMobile(); 
        }, 0);
        setInterval(() => {
            this.verifyMedium(); 
        }, 0);
    },
    methods: {
        navigate(value){
            this.$router.push({name: value}).catch(()=>{});
            window.scrollTo({
            top: 0,
            behavior: 'smooth'
            });
        },
        verifyMobile(){
            this.isMobile = window.innerWidth <= 1000;
            return this.isMobile;
        },
        verifyMedium(){
            this.isMedium = (window.innerWidth < 1400) && (window.innerWidth > 1000);
            return this.isMedium;
        },
    }
}
</script>

<style scoped>
.about-us{
    /* height: 100vh; */
    width: 100vw;
    background-color: #520000;
    padding: 50px;
}
.about-us-mobile{
    min-height: 400px;
    width: 100vw;
    background-color: #520000;
    padding: 20px;
    display: flex;
    align-items: center;
}
.text-container{
    width: 40vw;
    /* height: 90vh; */
    max-height: 550px;
    height: 25vw;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.text-container-mobile{
    width: 40vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.topic-title{
    font-weight: 300;
    border-bottom: 3px solid white;
}
.title {
    font-weight: 400;
}
.text{
    font-size: 11px;
    text-align: center;
}
.text p{
    font-weight: 300;
    letter-spacing: .25px;
}
.btn-container{
    padding: 20px;
}
.btn-container-mobile{
    padding: 10px;
}
.row{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.image{
    width: 20vw;
    height: 70vh;
    max-width: 300px;
    min-width: 200px;
    max-height: 700px;
    min-height: 400px;
    object-fit: cover;
    border-radius: 20px;
}
.image-mobile{
    width: 20vw;
    height: 200px;
    object-fit: cover;
    border-radius: 20px;
}
</style>