export default {
    namespaced: true,

    state: () => ({
        restaurant: {
            id: '',
            image: "",
            name: "",
            phone: "",
            address: {
                zipCode: "",
                street: "",
                district: "",
                number: "",
                complement: "",
                city: "",
                state: ""
            },
        },
        hasSelected: false,
    }),

    mutations: {
        DEFAULT_STORE_RESTAURANT(state, restaurant) {
            // var localState = JSON.parse(localStorage.getItem('store'));
            // if(localState!=null){
            //     state.restaurant = localState.restaurant;
            //     state.hasSelected = localState.hasSelected;
            // }
            // else
            state.restaurant = restaurant;
            state.hasSelected = false;
            localStorage.setItem('store', JSON.stringify(state));
        },
        STORE_RESTAURANT(state, restaurant) {
            state.restaurant = restaurant;
            state.hasSelected = true;
            localStorage.setItem('store', JSON.stringify(state));
        },
    },

    actions: {},
};