<template>
    <div :class="!isMobile && !isMedium ? 'form font-nunito' : 'form-mobile font-nunito'">
        <b-row>
            <b-col :cols="!isMobile && !isMedium ? '8' : '12'" :style="!isMobile && !isMedium ? 'font-size: 25px;' : 'font-size: 20px;'" style="font-weight: 300;">
                <span style="border-bottom: 3px solid #2E3132">
                    {{ modal.sectionName }}
                </span>
            </b-col>
        </b-row>
        <b-row>
            <b-col :cols="!isMobile && !isMedium ? '8' : '12'">
                <b-row class="font-alice">
                    <b-col :style="!isMobile && !isMedium ? 'font-size: 50px;' : 'font-size: 35px;'" style="font-size: 60px; font-weight: 400; margin-top: 15px;">
                        {{ modal.title }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col :style="!isMobile && !isMedium ? 'font-size: 24px;' : 'font-size: 18px'" style="font-weight: 300; margin-bottom: 38px;">
                        <span style="max-width: 950px;">
                            {{ modal.subtitle }}
                        </span>
                    </b-col>
                </b-row>
                <b-row style="display: flex;" :style="!isMobile && !isMedium ? '' : 'flex-direction: column;'">
                    <b-col :class="!isMobile && !isMedium ? 'pl-5 pr-5' : ''">
                        <b-form-input
                            id="inputName"
                            name="inputName"
                            ref="inputName"
                            type="text"
                            v-model="contact.name"
                            placeholder="Nome"
                            style="height: 62px; font-size: 24px; font-weight: 400; border: 0; box-shadow: none;"
                            v-validate="{ required: true }"
                            :state="validateState('inputName')"
                            aria-describedby="inputNameFeedback"
                            data-vv-as="Nome"
                        ></b-form-input>
                        <b-form-invalid-feedback id="inputNameFeedback" style="display: block !important; font-size: 18px; text-align: left;">{{ veeErrors.first('inputName') }}</b-form-invalid-feedback>
                    </b-col>
                        
                    <b-col :class="!isMobile && !isMedium ? 'pl-5 pr-5' : ''">
                        <b-form-input
                            id="inputEmail"
                            name="inputEmail"
                            ref="inputEmail"
                            type="text"
                            v-model="contact.email"
                            placeholder="Email"
                            style="height: 62px; font-size: 24px; font-weight: 400; border: 0; box-shadow: none;"
                            v-validate="{ required: true, email: true }"
                            :state="validateState('inputEmail')"
                            aria-describedby="inputEmailFeedback"
                            data-vv-as="Email"
                        ></b-form-input>
                        <b-form-invalid-feedback id="inputEmailFeedback" style="display: block !important; font-size: 18px; text-align: left;">{{ veeErrors.first('inputEmail') }}</b-form-invalid-feedback>
                    </b-col>
                </b-row>
                <b-row style="display: flex;" :style="!isMobile && !isMedium ? '' : 'flex-direction: column;'">
                    <b-col :class="!isMobile && !isMedium ? 'pl-5 pr-5' : ''" :cols="!isMobile && !isMedium ? '6' : ''">
                        <b-form-input
                            id="inputPhone"
                            name="inputPhone"
                            ref="inputPhone"
                            v-model="contact.phone"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            type="text"
                            placeholder="Telefone"
                            style="height: 62px; font-size: 24px; font-weight: 400; border: 0; box-shadow: none;"
                            v-validate="{ required: true, phone: true }"
                            :state="validateState('inputPhone')"
                            aria-describedby="inputPhoneFeedback"
                            data-vv-as="Phone"
                        ></b-form-input>
                        <b-form-invalid-feedback id="inputPhoneFeedback" style="display: block !important; font-size: 18px; text-align: left;">{{ veeErrors.first('inputPhone') }}</b-form-invalid-feedback>
                    </b-col>

                    <b-col :class="!isMobile && !isMedium ? 'pl-5 pr-5' : ''" :cols="!isMobile && !isMedium ? '3' : ''">
                        <b-form-select
                            id="inputState"
                            name="inputState"
                            ref="inputState"
                            v-model="contact.state"
                            :options="states"
                            @change="loadCities(contact.state)"
                            style="height: 62px; font-size: 24px; font-weight: 400; border: 0; box-shadow: none;"
                            v-validate="{ required: true }"
                            :state="validateState('inputState')"
                            aria-describedby="inputStateFeedback"
                            data-vv-as="Estado"
                        >
                        </b-form-select>
                        <b-form-invalid-feedback id="inputStateFeedback" style="display: block !important; font-size: 18px; text-align: left;">{{ veeErrors.first('inputState') }}</b-form-invalid-feedback>
                    </b-col>

                    <b-col :class="!isMobile && !isMedium ? 'pl-5 pr-5' : ''" :cols="!isMobile && !isMedium ? '3' : ''">
                        <b-form-select
                            id="inputCity"
                            name="inputCity"
                            ref="inputCity"
                            v-model="contact.city"
                            :options="cities"
                            style="height: 62px; font-size: 24px; font-weight: 400; border: 0; box-shadow: none;"
                            v-validate="{ required: true }"
                            :state="validateState('inputCity')"
                            aria-describedby="inputCityFeedback"
                            data-vv-as="Cidade"
                        >
                        </b-form-select>
                        <b-form-invalid-feedback id="inputCityFeedback" style="display: block !important; font-size: 18px; text-align: left;">{{ veeErrors.first('inputCity') }}</b-form-invalid-feedback>
                    </b-col>
                </b-row>

                <b-row style="display: flex;" :style="!isMobile && !isMedium ? '' : 'flex-direction: column;'">
                    <b-col :class="!isMobile && !isMedium ? 'pl-5 pr-5' : ''">
                        <!-- <b-form-input
                            id="inputSubject"
                            name="inputSubject"
                            ref="inputSubject"
                            type="text"
                            placeholder="Assunto"
                            style="height: 62px; font-size: 24px; font-weight: 400; border: 0; box-shadow: none;"
                        ></b-form-input> -->
                        <b-form-select
                            id="inputSubject"
                            name="inputSubject"
                            ref="inputSubject"
                            v-model="contact.subject"
                            :options="subjects"
                            style="height: 62px; font-size: 24px; font-weight: 400; border: 0; box-shadow: none;"
                            v-validate="{ required: true }"
                            :state="validateState('inputSubject')"
                            aria-describedby="inputSubjectFeedback"
                            data-vv-as="Assunto"
                        >
                        </b-form-select>
                        <b-form-invalid-feedback id="inputSubjectFeedback" style="display: block !important; font-size: 18px; text-align: left;">{{ veeErrors.first('inputSubject') }}</b-form-invalid-feedback>
                    </b-col>
                    <b-col :class="!isMobile && !isMedium ? 'pl-5 pr-5' : ''">
                        <!-- <b-form-input
                            id="inputRestaurant"
                            name="inputRestaurant"
                            ref="inputRestaurant"
                            type="text"
                            placeholder="Selecione um restaurante"
                            style="height: 62px; font-size: 24px; font-weight: 400; border: 0; box-shadow: none;"
                        ></b-form-input> -->
                        <b-form-select
                            id="inputRestaurant"
                            name="inputRestaurant"
                            ref="inputRestaurant"
                            v-model="restaurant"
                            :options="restaurants"
                            style="height: 62px; font-size: 24px; font-weight: 400; border: 0; box-shadow: none;"
                            v-validate="{ required: true }"
                            :state="validateState('inputRestaurant')"
                            aria-describedby="inputRestaurantFeedback"
                            data-vv-as="Restaurante"
                        >
                        </b-form-select>
                        <b-form-invalid-feedback id="inputRestaurantFeedback" style="display: block !important; font-size: 18px; text-align: left;">{{ veeErrors.first('inputRestaurant') }}</b-form-invalid-feedback>
                    </b-col>
                </b-row>
                <b-row style="display: flex;" :style="!isMobile && !isMedium ? '' : 'flex-direction: column;'">
                    <b-col :class="!isMobile && !isMedium ? 'pl-5 pr-5' : ''">
                        <b-form-textarea
                            :maxlength="500"
                            id="inputMessage"
                            name="inputMessage"
                            placeholder="Mensagem"
                            v-model="contact.message"
                            rows="3"
                            max-rows="6"
                            style="height: 137px; font-size: 24px; font-weight: 400; border: 0; box-shadow: none;"
                            v-validate="{ required: true }"
                            :state="validateState('inputMessage')"
                            aria-describedby="inputMessageFeedback"
                            data-vv-as="Mensagem"
                        ></b-form-textarea>
                        <b-row align-h="between" class="mx-0">
                            <div>
                                <b-form-invalid-feedback id="inputMessageFeedback" style="display: block !important; font-size: 18px; text-align: left;">{{ veeErrors.first('inputMessage') }}</b-form-invalid-feedback>
                            </div>
                            <span style="font-size:20px;"> {{contact.message.length}} / {{500}} </span>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col :class="!isMobile && !isMedium ? 'pl-5 pr-5 font-alice' : 'font-alice'">
                        <button style="height: 70px; width: 100%; background-color: #520000; color: #FFF; border: 0; box-shadow: none; font-weight: 400; border-radius: 12px;" :style="!isMobile && !isMedium ? 'font-size: 30px;' : 'font-size: 20px;'" @click="submit">
                            <span v-if="!loading">Enviar formulário</span>
                            <span v-else style="display: flex; align-items: center; justify-content: center;">
                                <b-spinner class="mr-2"></b-spinner>
                                <span>Enviando...</span>
                            </span>
                        </button>
                    </b-col>
                </b-row>
            </b-col>
            <b-col v-if="!isMobile && !isMedium">
                <img :src="modal.mainImage" alt="" style="max-height: 790px; min-height: 790px; max-width: 400px; min-width: 400px; object-fit:cover">
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import { mapState } from 'vuex';

export default {
    name: "FormSession",
    props: {
        modalProp: Object,
        didRequest: Boolean,
        loadingProp: Boolean,
    },
    data(){
        return {
            isMobile: false,
            isMedium: false,
            modal: null,
            contact: {
                name: '',
                email: '',
                phone: '',
                city: null,
                state: null,
                subject: null,
                message: '',
            },
            restaurant: null,
            states: [
                {value: null, text: 'UF'},
            ],
            uf: '',
            cities: [
                {value: null, text: 'Cidade'},
            ],
            subjects: [
                {value: null, text: 'Selecione o assunto'},
                {value: 'CRITIQUES', text: 'Crítica'},
                {value: 'DOUBTS', text: 'Dúvida'},
                {value: 'PRAISE', text: 'Elogio'},
            ],
            restaurants: [
                {value: null, text: 'Selecione um restaurante'}
            ],
            formIsValid: false,
            loading: false,
        }
    },
    async created(){
        this.$store.commit("loading/STORE_LOADING");
        this.loading=this.loadingProp;
        this.$validator.localize('en', {
            custom: {
                inputName: {
                    required: 'Informe o nome.',
                },
                inputEmail: {
                    required: 'Informe o email.',
                    email: 'Informe um email válido.',
                },
                inputPhone: {
                    required: 'Informe o telefone.',
                    phone: 'Informe um telefone válido (XX) XXXX-XXXX ou (XX) XXXXX-XXXX.'
                },
                inputState: {
                    required: 'Informe o estado.',
                },
                inputCity: {
                    required: 'Informe a cidade.',
                },
                inputSubject: {
                    required: 'Informe o assunto.',
                },
                inputRestaurant: {
                    required: 'Informe o restaurante.',
                },
                inputMessage: {
                    required: 'Informe a mensagem.',
                },
            },
        });
        this.modal=this.modalProp;
        const intervalId = setInterval(()=>{
            if(this.didRequest){
                clearInterval(intervalId);
                this.modal=this.modalProp;
                this.loading=this.loadingProp;
            }
        }, 250)
        setInterval(() => {
            this.verifyMobile(); 
        }, 0);
        setInterval(() => {
            this.verifyMedium(); 
        }, 0);
        await this.loadRestaurants();
        this.mapRestaurants();
        await this.loadStates();
        
        setTimeout(() => {
            this.$store.commit("loading/STORE_LOADED");
        }, 250);
    },
    computed: {
        ...mapState("loading", {
            loadingState: (state) => state.loading
        }),
    },
    methods: {
        async clearAndLoad(){
            this.contact = {
                name: '',
                email: '',
                phone: '',
                city: null,
                state: null,
                subject: null,
                message: '',
            },
            this.restaurant = null;
            this.$validator.reset();
            await this.loadRestaurants();
            this.mapRestaurants();
            await this.loadStates();
        },
        verifyMobile(){
            this.isMobile = window.innerWidth <= 1000;
            return this.isMobile;
        },
        verifyMedium(){
            this.isMedium = (window.innerWidth < 1400) && (window.innerWidth > 1000);
            return this.isMedium;
        },
        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        async loadRestaurants() {
            await this.$axios.get('/restaurant/detailed').then((response) => {
                this.listRestaurants = response.data.data;
            });
        },
        async loadStates() {
            await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
                .then((response) => {
                    response.data.forEach(
                        (element) => this.states.push({
                                value: element.id,
                                text: element.sigla,
                            }));
                });
        },
        async loadCities(value) {
            this.cities = [{value:null, text:'Cidade'}];
            this.contact.city = null;
            await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
                .then((response) => {
                    response.data.forEach(
                        (element) => {
                            if(element.id == value) this.uf = element.sigla;
                        });
                });
            await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + value + '/municipios?orderBy=nome')
                .then((response) => {
                    response.data.forEach(
                        (element) => this.cities.push({
                                value: element.nome,
                                text: element.nome,
                            }));
                });
        },
        async postForm() {
            var phone = this.contact.phone.replace(/\D/g, '');
            var model = {
                "city": this.contact.city,
                "email": this.contact.email,
                "message": this.contact.message,
                "name": this.contact.name,
                "phone": phone,
                "subject": this.contact.subject,
                "uf": this.uf
            };
            await this.$axios.post('contacts?idRestaurant=' + this.restaurant, model);
        },
        mapRestaurants(){
            this.listRestaurants.forEach((item)=>{
                this.restaurants.push({
                    value: item.id,
                    text: item.name
                });
            });
        },
        async validateForm() {
            let response = await this.$validator.validateAll();
            
            if (response)
                this.formIsValid = true;
            else
                this.formIsValid = false;
        },
        submit() {
            this.$validator.validateAll().then(async result => {
                if (!result) {
                    return;
                } else {
                    this.loading = true;
                    await this.postForm();
                    await this.clearAndLoad();
                    Swal.fire({
                        position: "bottom-right",
                        icon: null,
                        title: "<div style='display:flex; align-items:center;'><img src='" + require('@/assets/images/shared/check_circle.png') + "' style='margin-right: 10px; margin-bottom:5px;'><h6 style:'font-family:'Nunito Sans';'>Formulário enviado com sucesso!</h6></div>",
                        color: "white",
                        background: "linear-gradient(to right, #19B377, #037347)",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                }
            }).catch(() => {
                Swal.fire({
                    position: "bottom-right",
                    icon: null,
                    width:550,
                    title: "<div style='display:flex; align-items:center; margin-bottom:10px'><img src='" + require('@/assets/images/shared/error_circle.png') + "' style='width: 20px; height: 20px; margin-right: 10px;'><h6 style:'font-family:'Nunito Sans'; padding:0px;'>Houve um erro no envio do formulário. Tente novamente</h6></div>",
                    padding: "10px",
                    color: "white",
                    showConfirmButton: false,
                    timer: 3000,
                    background: "linear-gradient(to right, #F4483B, #741F19)",
                });
            }).finally(() => {
                this.loading = false;
            });
        }
    },
    watch: {
        'contact.name': 'validateForm',
        'contact.email': 'validateForm',
        'contact.phone': 'validateForm',
        'contact.state': 'validateForm',
        'contact.city': 'validateForm',
        'contact.subject': 'validateForm',
        'restaurant': 'validateForm',
        'contact.message': 'validateForm',
    }
}
</script>

<style scoped>
.form{
    background-color: #EEEEEE;
    color: #2E3132;
    /* padding: 120px; */
    padding: 20px;
}
.form-mobile{
    background-color: #EEEEEE;
    color: #2E3132;
    padding: 30px;
}
.form-control, .custom-select {
    background-color: #D1D4D4 !important;
    border-radius: 10px;
}
.form-control::-webkit-scrollbar{
    display: none;
}
.invalid-feedback{
    height: 27px;
}
</style>