<template>
    <div class="session font-nunito" :style="isMobile ? 'padding: 20px;' : 'padding: 50px;'">
        <b-row>
            <p class="mx-auto" :class="!isMobile && !isMedium ? 'topic-title' : 'topic-title-mobile'"> {{ modal.sectionName }} </p>
        </b-row>
        
        <b-row>
            <p class="mx-auto font-alice" :class="!isMobile && !isMedium ? 'title' : 'title-mobile'"> {{ modal.title }} </p>
        </b-row>

        <b-col class="content-service">
            <b-row>
                <ServiceItem v-if="modalOne" :image="modalOne.image" :title="modalOne.title" :subtitle="modalOne.subtitle"/>
                <div style="width: 15vw" v-if="!isMobile && !isMedium"></div>
                <ServiceItemReverse v-if="modalTwo" :image="modalTwo.image" :title="modalTwo.title" :subtitle="modalTwo.subtitle"/>
            </b-row>
            <b-row class="central-row"><img class="image-central" :src="modalFive.image" alt="" v-if="!isMobile && !isMedium && modalFive"></b-row>
            <b-row>
                <ServiceItem v-if="modalThree" :image="modalThree.image" :title="modalThree.title" :subtitle="modalThree.subtitle"/>
                <div style="width: 15vw" v-if="!isMobile && !isMedium"></div>
                <ServiceItemReverse v-if="modalFour" :image="modalFour.image" :title="modalFour.title" :subtitle="modalFour.subtitle"/>
            </b-row>
        </b-col>
    </div>
</template>

<script>
import ServiceItem from '../components/ServiceItem.vue'
import ServiceItemReverse from '../components/ServiceItemReverse.vue'
export default {
    name: "ServicesSession",
    components: {
        ServiceItem,
        ServiceItemReverse,
    },
    props: {
        modalProp: Object,
        didRequest: Boolean,
        loading: Boolean,
    },
    data(){
        return {
            isMobile: false,
            isMedium: false,
            modalOne: {
                image: '',
                title: '',
                subtitle: '',
            },
            modalTwo: {
                image: '',
                title: '',
                subtitle: '',
            },
            modalThree: {
                image: '',
                title: '',
                subtitle: '',
            },
            modalFour: {
                image: '',
                title: '',
                subtitle: '',
            },
            modalFive: {
                image: '',
                title: '',
                subtitle: '',
            },
        }
    },
    created(){
        this.modal=this.modalProp;
        const intervalId = setInterval(()=>{
            if(this.didRequest){
                clearInterval(intervalId);
                this.modal=this.modalProp;
                this.modalOne = this.modalProp.listImages[0];
                this.modalTwo = this.modalProp.listImages[1];
                this.modalThree = this.modalProp.listImages[2];
                this.modalFour = this.modalProp.listImages[3];
                this.modalFive = this.modalProp.listImages[4];
            }
        }, 250)
        setInterval(() => {
            this.verifyMobile(); 
        }, 0);
        setInterval(() => {
            this.verifyMedium(); 
        }, 0);
    },
    methods: {
        verifyMobile(){
            this.isMobile = window.innerWidth <= 1000;
            return this.isMobile;
        },
        verifyMedium(){
            this.isMedium = (window.innerWidth < 1400) && (window.innerWidth > 1000);
            return this.isMedium;
        },
    }
}
</script>

<style scoped>
.session{
    background-color: #520000;
    color: white;
    /* padding: 30px; */
}
.topic-title{
    font-size: 25px;
    margin: 0px 0px 10px 0px;
    border-bottom: 3px solid white;
    font-weight: 300;
}
.topic-title-mobile{
    font-size: 1.2rem;
    margin: 0px 0px 10px 0px;
    border-bottom: 3px solid white;
    font-weight: 300;
}
.title{
    font-size: 70px;
    font-weight: 400;
    margin: 0px 0px 20px 0px;
    line-height: 100px;
}
.title-mobile{
    font-size: 30px;
    font-weight: 400;
    margin: 0px 0px 20px 0px;
    /* line-height: 100px; */
}
.content-service{
    text-align: start;
}
.central-row{
    display: flex;
    justify-content: center;
}
.image-central{
    /* width: 16vw; height: 33vh; */
    margin: 0px;
    border: 1px solid #FFB000;
    border-radius: 100%;
    padding: 10px;
    box-shadow: 5px 5px 10px #450000;
    object-fit: cover;
    max-width: 230px;
    max-height: 230px;
    min-width: 230px;
    min-height: 230px;
}
</style>