<template>
  <div>
    <b-button id="show-btn" size="sm" variant="link" style="color:white;"  @click="showModal">
      <b-row class="center">
        <p class="h6"><b-icon icon="geo-alt-fill"></b-icon></p>
        <h6 style="transform: translateY(3px)" class="restaurant-location mx-2 font-nunito"> {{ restaurantState.name }}, {{ restaurantState.address.street }}, {{ restaurantState.address.number }}, {{ restaurantState.address.district }}, {{ restaurantState.address.city }}</h6>
        <b-icon icon="chevron-down"></b-icon>
      </b-row>
    </b-button>

    <b-modal class="card-modal" modal-class="modal-class" title-class="modal-title-class font-alice" centered size="xl" ref="cardModalRef" hide-footer title="Selecione o restaurante desejado">
      <b-row cols="5" class="row-items">
        <b-col :style="'background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url('+restaurant.image+'); background-size: cover; background-position:center; min-width: 175px'" v-for="(restaurant, idx) in restaurants" :key="idx" @click="changeRestaurant(idx)" class="card-item font-nunito">
            <p><b>{{ restaurant.name }}</b></p>
            <p>{{ restaurant.address.city }}, {{ restaurant.address.state }}</p>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: "CardModal",
    props: {
      restaurants: []
    },
    computed: {
      ...mapState("restaurant", {
        restaurantState: (state) => state.restaurant
      })
    },
    methods: {
      showModal() {
        this.$refs.cardModalRef.show()
      },
      changeRestaurant(idx){
        this.$store.commit("restaurant/STORE_RESTAURANT", this.restaurants[idx]); 
        this.hideModal();
        this.$router.go();
      },
      hideModal() {
        this.$refs.cardModalRef.hide()
      },
      toggleModal() {
        this.$refs.cardModalRef.toggle('#show-btn')
      }
    },
}
</script>

<style>

.restaurant-location{
  max-width: 35vw;
  text-align: start;
  overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
            line-clamp: 1; 
    -webkit-box-orient: vertical;
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.row-items{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.card-item{
    color: white;
    font-size: 18px;
    width: 300px;
    height: 200px;
    margin: 10px;
    padding: 20px;
    background-color: black;
    border-radius: 20px;
    transition: 0.2s;
}
.card-item:hover{
    transition: 0.2s;
    cursor: pointer;
    transform: translateX(5px);
    transform: translatey(-5px);
    box-shadow: 5px 5px 10px #AAAAAA;
}
</style>