<template>
    <b-row class="item mx-auto" :style="isMobile ? '' : 'margin-bottom:100px;'">
        <b-col class="content-col" align-self="center" style="height: min-content;">
            <div class="font-alice pb-4">
                <b-row class="item-title" style="font-weight:500">{{title}}</b-row>
                <b-row class="item-local" style="font-weight:400">{{local}}</b-row>
            </div>
            
            <div class="font-nunito" style="width: 100%">
                <b-row class="item-row">
                    <p class="h5"><b-icon icon="geo-alt" class="item-icon"></b-icon></p>

                    <p v-if="isMobile" class="item-text-mobile">{{address}}</p>
                    <p v-else class="item-text">{{address}}</p>
                </b-row>

                <b-row class="item-row item-row-center">
                    <p class="h5"><b-icon icon="clock" class="item-icon"></b-icon></p>
                    <div style="width:80%">
                        <div v-for="(time, idx) in times" :key="idx" style="display:flex; flex-direction:column; width:100%; padding: 0px 0px 10px 0px">
                            <b style="font-size: 20px"> {{ time.name }}: </b>
                            <p v-for="(text, idx) in time.texts" :key="idx" class="item-text" style="text-align:start; width: 100%; padding:0px; margin:0px">
                                {{ text }}
                            </p>
                        </div>
                    </div>
                </b-row>
                
                <b-row class="item-row">
                    <p class="h5"><b-icon icon="telephone" class="item-icon"></b-icon></p>
                    <p class="item-text">{{phone | formatPhoneNumber}}</p>
                </b-row>
            </div>
        </b-col>
        <b-col class="align-image"><img class="item-image" :src="image" alt=""></b-col>
    </b-row>
</template>

<script>
export default {
    name: "RestaurantItemReverse",
    props: {
        image: String,
        title: String,
        local: String,
        address: String,
        times: [],
        phone: String,
    },
    data(){
        return {
            isMobile: false,
            isMedium: false,
        }
    },
    created(){
        setInterval(() => {
            this.verifyMobile(); 
        }, 0);
        setInterval(() => {
            this.verifyMedium(); 
        }, 0);
    },
    methods: {
        verifyMobile(){
            this.isMobile = window.innerWidth <= 1000;
            return this.isMobile;
        },
        verifyMedium(){
            this.isMedium = (window.innerWidth < 1400) && (window.innerWidth > 1000);
            return this.isMedium;
        },
    }
}
</script>

<style scoped>
.item{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    margin: 20px;
    width: 80vw;
    /* max-width: 1200px; */
}
.align-image{
    display: flex;
    justify-content: flex-start;
}
.item-image{
    width: 80%;
    min-width: 300px;
    /* height: 100%; */
    min-height: 300px;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 5px 5px 10px #CCCCCC;
}
.content-col{
    min-width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.content-col-mobile{
    min-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.item-icon{
    color: #520000;
    margin: 5px 10px;
}
.item-title{
    color: #520000;
    font-size: 50px;
    text-align: start;
}
.item-local{
    color: #191C1D;
    font-size: 25px;
    text-align: start;
}
.item-text{
    width: 80%;
    font-size: 20px;
}
.item-title-mobile{
    color: #520000;
    font-size: 28px;
    text-align: start;
}
.item-local-mobile{
    color: #191C1D;
    font-size: 18px;
    text-align: start;
}
.item-text-mobile{
    width: 80%;
}
.item-row{
    width: 100%;
    text-align: start;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.item-row-center{
    border-top: 1px solid #D2BDBD;
    border-bottom: 1px solid #D2BDBD;
}
</style>