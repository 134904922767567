<template>
    <div class="list-restaurant-session">

        <div v-if="!isMobile">
            <b-row v-for="(item, index) in listRestaurants" :key="index">
                <RestaurantItem v-if="index%2==0"
                    :image="item.image"
                    :title="item.name"
                    :local="getLocal(item)"
                    :address="getAddress(item)"
                    :times="item.listHours"
                    :phone="item.phone"
                />
                <RestaurantItemReverse v-else
                    :image="item.image"
                    :title="item.name"
                    :local="getLocal(item)"
                    :address="getAddress(item)"
                    :times="item.listHours"
                    :phone="item.phone"
                />
            </b-row>
        </div>
        <div v-else>
            <b-row v-for="(item, index) in listRestaurants" :key="index" class="py-5" :style="index==listRestaurants.length-1 ? '' : 'border-bottom: 3px solid #520000;'">
                <RestaurantItem
                    :image="item.image"
                    :title="item.name"
                    :local="getLocal(item)"
                    :address="getAddress(item)"
                    :times="item.listHours"
                    :phone="item.phone"
                />
            </b-row>
        </div>
    </div>    
</template>

<script>
import RestaurantItem from '../components/RestaurantItem.vue'
import RestaurantItemReverse from '../components/RestaurantItemReverse.vue'

export default {
    name: "ListRestaurantSession",
    components: {
        RestaurantItem,
        RestaurantItemReverse,
    },
    data(){
        return{
            isMobile: false,
            isMedium: false,
            listRestaurants: [],
            // listRestaurants: [
            //     {
            //         image: require('@/assets/images/restaurant/units/colinas_restaurant.png'),
            //         name: "La Dirna - Colinas",
            //         local: "São José dos Campos, SP",
            //         address: "Av São João, 2555 Jardim das Colinas - Cep 12.242-000",
            //         hours_description: "",
            //         phone: "(12) 3876-1157"
            //     },
            //     {
            //         image: require('@/assets/images/restaurant/units/general_carneiro_restaurant.png'),
            //         name: "La Dirna - General Carneiro",
            //         local: "São José dos Campos, SP",
            //         address: "Avenida General Carneiro, 1.120 Vila Lucy - Cep: 18.043-003",
            //         hours_description: "",
            //         phone: "(15) 3414-1887"
            //     },
            //     {
            //         image: require('@/assets/images/restaurant/units/tiradentes_restaurant.png'),
            //         name: "La Dirna - Tiradentes",
            //         local: "Marília, SP",
            //         address: "Av. Tiradentes - 1131 Fragata - Cep: 17.519-000",
            //         hours_description: "",
            //         phone: "(14) 3402-2607"
            //     },
            //     {
            //         image: require('@/assets/images/restaurant/units/duque_restaurant.png'),
            //         name: "La Dirna - Duque",
            //         local: "Bauru, SP",
            //         address: "Avenida Duque de Caxias, 22-42 Vila Cardia - Cep: 17.011-066",
            //         hours_description: "",
            //         phone: "(14) 2106-5407"
            //     },
            //     {
            //         image: require('@/assets/images/restaurant/units/ozanan_restaurant.png'),
            //         name: "La Dirna - Ozanan",
            //         local: "Jundiaí, SP",
            //         address: "Av. Antônio Segre - 79 Ponte de Campinas - Cep: 13.201-155",
            //         hours_description: "",
            //         phone: "(11) 4583-2117"
            //     },
            //     {
            //         image: require('@/assets/images/restaurant/units/itavuvu_restaurant.png'),
            //         name: "La Dirna - Itavuvu",
            //         local: "Sorocaba, SP",
            //         address: "Avenida Itavuvu - 2005 - Jardim Maria Antonia Prado - Cep: 18.076-005",
            //         hours_description: "",
            //         phone: "(15) 3331-9907"
            //     },
            //     {
            //         image: require('@/assets/images/restaurant/units/campolim_restaurant.png'),
            //         name: "La Dirna - Campolim",
            //         local: "Sorocaba, SP",
            //         address: "Av. Professora Izoraida Marques - 600 Parque Campolim Cep: 18.048-110",
            //         hours_description: "",
            //         phone: "(15) 3333-3836"
            //     },
            //     {
            //         image: require('@/assets/images/restaurant/units/rio_branco_restaurant.png'),
            //         name: "La Dirna - Rio Branco",
            //         local: "Bauru, SP",
            //         address: "Rua Rio Branco - 20-40 Vila América - Cep: 17.014-037",
            //         hours_description: "",
            //         phone: "(14) 3235-3210"
            //     }
            // ]
        }
    },
    async created(){
        await this.loadRestaurants();
        setInterval(() => {
            this.verifyMobile(); 
        }, 0);
        setInterval(() => {
            this.verifyMedium(); 
        }, 0);
    },
    methods: {
        verifyMobile(){
            this.isMobile = window.innerWidth <= 1000;
            return this.isMobile;
        },
        verifyMedium(){
            this.isMedium = (window.innerWidth < 1400) && (window.innerWidth > 1000);
            return this.isMedium;
        },
        getLocal(item){
            var strLocal = item.address.city + ", " + item.address.state;
            return strLocal;
        },
        getAddress(item){
            var zipCodeFiltered = this.$options.filters.formatZipCode(item.address.zipCode);
            var strAddress = item.address.street + ', ' + item.address.number + ' ' + item.address.district + ' - Cep: ' + zipCodeFiltered;
            return strAddress;
        },
        translateDay(day){
            var daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'HOLIDAY'];
            var diasDaSemana = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Feriado'];
            var index = daysOfWeek.indexOf(day);
            return diasDaSemana[index];
        },
        concatenateFirstDay(value){
            var firstDays = value.firstDay.split(', ');
            var primeirosDias = [];
            firstDays.forEach(element => {
                primeirosDias.push(this.translateDay(element));
            });
            var str = primeirosDias.join(', ');
            return str;
        },
        concatenateLastDay(value){
            var lastDays = value.lastDay.split('/');
            var ultimosDias = [];
            lastDays.forEach(element => {
                ultimosDias.push(this.translateDay(element));
            });
            var str = ultimosDias.join(' e ');
            return str;
        },
        convertDate(value){
            var str = value.substring(8, 10) + "/" + value.substring(5, 7) + "/" + value.substring(0, 4);
            return str;
        },
        async loadRestaurants(){
            await this.$axios.get('/restaurant/detailed').then((response) => {
                this.listRestaurants = response.data.data;
                this.listRestaurants.forEach(e => {
                    if(e.times.length>0){

                        var namesList = [];
                        var hoursList = [];

                        e.times.forEach(t => {
                            if(!namesList.includes(t.name))
                                namesList.push(t.name);
                            });

                        namesList.sort((a) => {
                            if(a.toLowerCase == "almoço" || a.toLowerCase == "jantar")
                                return -1;
                            else return 1;
                        })

                        namesList.forEach(value => {
                            hoursList.push({
                                name: value,
                                listTimes: []
                            });
                        });

                        hoursList.forEach(h => {
                            e.times.forEach(t => {
                                if(h.name == t.name){
                                    h.listTimes.push(t);
                                } 
                            })
                        });

                        var listStr = [];

                            //    "id": "0e9bb651-e148-4d96-83f8-ba46b805b1bb",
                            //    "firstDay": "SATURDAY",
                            //    "lastDay": "SUNDAY",
                            //    "name": "Almoço",
                            //    "opensAt": "11:00",
                            //    "closesAt": "15:30",
                            //    "specialDate": null,
                            //    "special": false,
                            //    "closed": false,
                            //    "sequential": true

                        hoursList.forEach(h => {
                            listStr.push({
                                name: h.name,
                                texts: []
                            });

                            h.listTimes.forEach(time => {
                                var found = listStr.find(value => value.name == time.name);

                                if(time.special){



                                    if(time.closed){
                                        if(new Date(time.firstSpecialDate).setHours(0, 0, 0, 0) == new Date(time.lastSpecialDate).setHours(0, 0, 0, 0)){
                                            found.texts.push(
                                                this.convertDate(time.firstSpecialDate) + " - fechados"
                                            );
                                        } else{
                                            // if(time.sequential){
                                            //     found.texts.push(
                                            //         this.convertDate(time.firstSpecialDate) + " à " + this.convertDate(time.lastSpecialDate) + " - fechados"
                                            //     );
                                            // }
                                            // else{
                                                found.texts.push(
                                                    this.convertDate(time.firstSpecialDate) + " à " + this.convertDate(time.lastSpecialDate) + " - fechados"
                                                    // this.convertDate(time.firstSpecialDate) + " e " + this.convertDate(time.lastSpecialDate) + " - fechados"
                                                );  
                                            // }
                                        }
                                    }
                                    else{
                                        if(new Date(time.firstSpecialDate).setHours(0, 0, 0, 0) == new Date(time.lastSpecialDate).setHours(0, 0, 0, 0)){
                                            found.texts.push(
                                                this.convertDate(time.firstSpecialDate) + " das " + time.opensAt +  " às " + time.closesAt
                                            );
                                        } else{
                                            // if(time.sequential){
                                            //     found.texts.push(
                                            //         this.convertDate(time.firstSpecialDate) + " à " + this.convertDate(time.lastSpecialDate) + " das " + time.opensAt +  " às " + time.closesAt
                                            //     );   
                                            // }
                                            // else{
                                                found.texts.push(
                                                    this.convertDate(time.firstSpecialDate) + " à " + this.convertDate(time.lastSpecialDate) + " das " + time.opensAt +  " às " + time.closesAt
                                                    // this.convertDate(time.firstSpecialDate) + " e " + this.convertDate(time.lastSpecialDate) + " das " + time.opensAt +  " às " + time.closesAt
                                                );   
                                            // }
                                        }
                                    }



                                }
                                else{
                                    if(time.closed){
                                        if(time.sequential){
                                            if(time.firstDay == time.lastDay){
                                                found.texts.push(
                                                    this.translateDay(time.firstDay) + " - fechados"
                                                );
                                            }
                                            else{
                                                found.texts.push(
                                                    this.translateDay(time.firstDay) + " à " + this.concatenateLastDay(time) + " - fechados"
                                                );
                                            }
                                        }
                                        else{
                                            if(time.lastDay.includes('HOLIDAY')){
                                                found.texts.push(
                                                    this.concatenateFirstDay(time) + ", " + this.concatenateLastDay(time) + " - fechados"
                                                );  
                                            }else{
                                                found.texts.push(
                                                    this.concatenateFirstDay(time) + " e " + this.concatenateLastDay(time) + " - fechados"
                                                );  
                                            }
                                        }
                                    }
                                    else{
                                        if(time.sequential){
                                            if(time.firstDay == time.lastDay){
                                                found.texts.push(
                                                    this.translateDay(time.firstDay) + " das " + time.opensAt +  " às " + time.closesAt
                                                );   
                                            }
                                            else{
                                                found.texts.push(
                                                    this.translateDay(time.firstDay) + " à " + this.concatenateLastDay(time) + " das " + time.opensAt +  " às " + time.closesAt
                                                );   
                                            }
                                        }
                                        else{
                                            if(time.lastDay.includes('HOLIDAY')){
                                                found.texts.push(
                                                    this.concatenateFirstDay(time) + ", " + this.concatenateLastDay(time) + " das " + time.opensAt +  " às " + time.closesAt
                                                );   
                                            }
                                            else{
                                                found.texts.push(
                                                    this.concatenateFirstDay(time) + " e " + this.concatenateLastDay(time) + " das " + time.opensAt +  " às " + time.closesAt
                                                );   
                                            }
                                        }
                                    }
                                }
                            });
                        })

                        e.listHours = listStr;
                    }
                });
            })
            .catch(()=>{this.$emit('hideSession'); return;})
            .finally(()=>{
                if(this.listRestaurants.length==0){
                    this.$emit('hideSession'); 
                    return;
                }
            });
        },
    }
}
</script>

<style scoped>
.list-restaurant-session{
    width: 100vw;
    background-color: #EEEEEE;
    padding: 50px;
}
</style>