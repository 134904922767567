<template>
  <div :class="isMobile ? 'most-requested-session-mobile' : 'most-requested-session'">
    <b-container id="most-requested" fluid="xl" class="mx-auto container">
        <b-col class="col">

            <b-row class="row font-nunito">
                <h6 v-if="isMobile" class="topic-title mx-auto" style="font-weight:300;">{{ modal.sectionName }}</h6>
                <h4 v-else class="topic-title mx-auto" style="font-weight:300;">{{ modal.sectionName }}</h4>
            </b-row>

            <b-row class="row font-alice">
                <h1 class="display-6 title" style="font-size:22px" v-if="isMobile"> {{ modal.title }} </h1>
                <h1 class="display-4 title" style="font-size:42px" v-else-if="isMedium"> {{ modal.title }} </h1>
                <h1 class="display-4 title" v-else> {{ modal.title }} </h1>
            </b-row>
            
            <b-row :class="(isMobile ? 'btn-group-nav-pages-mobile' : 'btn-group-nav-pages') + ' mx-auto my-4'">
                <p :class="isMobile ? 'h3' : 'h1'">
                    <b-icon v-if="isMobile" icon="arrow-left-circle" @click="previousPage" class="arrow-button"></b-icon>
                </p>

                <div v-if="isMobile">
                    <transition appear name="most-requested"
                    enter-active-class="most-requested-enter-active"
                    enter-class="most-requested-enter-from"
                    enter-to-class="most-requested-enter-to"
                    
                    leave-active-class="most-requested-leave-active"
                    leave-class="most-requested-leave-from"
                    leave-to-class="most-requested-leave-to"
                    >
                        <b-row v-if="!isPassing" class="items-mobile mx-auto'">
                            <b-col @click="showModal(item)" style="cursor:pointer;" v-for="(item, i) in stepMostRequestedList" :key="i" align-self="start" class="item-mobile">
                                <b-row><img class="image-mobile" :src="item.imageUrl"/></b-row>
                                <h6 class="item-title font-alice" style="font-size:15px">{{ item.name }}</h6>
                                <h6 class="item-subtitle-mobile font-nunito" style="font-size:14px">{{ item.description }}</h6>
                                <b-row style="width:100%">
                                    <b-col v-for="(price, idx) in item.listPricesDto" :key="idx" style="width:100%; min-width:80px; display:flex; flex-direction:column; align-items:flex-start; padding:3px 0px 5px 0px">
                                        <h6 class="item-price font-alice" style="padding-right:10px; font-size:12px; line-height:8px">{{ price.name }}</h6>
                                        <h6 class="item-price font-alice" style="padding-right:10px; font-size:14px; line-height:8px">R$ {{ (price.value/100).toFixed(2).replaceAll(".", ",") }}</h6>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </transition>
                </div>

                <div v-else>
                    <transition appear name="most-requested"
                    enter-active-class="most-requested-enter-active"
                    enter-class="most-requested-enter-from"
                    enter-to-class="most-requested-enter-to"
                                        
                    leave-active-class="most-requested-leave-active"
                    leave-class="most-requested-leave-from"
                    leave-to-class="most-requested-leave-to"
                    >
                        <b-row v-if="!isPassing" class="items mx-auto'">
                            <b-col @click="showModal(item)" v-for="(item, i) in stepMostRequestedList" :key="i" :align-self="i%2==0 ? 'start' : 'end'" class="item card-item-request">
                                <b-row><img class="image" :src="item.imageUrl"/></b-row>
                                <h4 v-if="isMedium" class="item-title  font-alice">{{ item.name }}</h4>
                                <h3 v-else class="item-title  font-alice">{{ item.name }}</h3>
                                <h5 v-if="isMedium" style="font-size:18px" class="item-subtitle font-nunito">{{ item.description }}</h5>
                                <h5 v-else class="item-subtitle font-nunito">{{ item.description }}</h5>
                                <b-row style="width:80%">
                                    <b-col v-for="(price, idx) in item.listPricesDto" :key="idx" class="pb-2" style="width:100%; min-width:90px; display:flex; flex-direction:column; align-items:flex-start; padding:0px">
                                        <h6 :style="isMedium ? 'padding-right:10px; line-height:10px' : 'padding-right:15px; font-size:20px; line-height:15px'" class="item-price font-alice">{{ price.name }}</h6>
                                        <h6 :style="isMedium ? 'padding-right:10px; line-height:10px' : 'padding-right:15px; font-size:20px; line-height:15px'" class="item-price font-alice">R$ {{ (price.value/100).toFixed(2).replaceAll(".", ",") }}</h6>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </transition>
                </div>


                <p :class="isMobile ? 'h3' : 'h1'">
                    <b-icon v-if="isMobile" icon="arrow-right-circle" @click="nextPage" class="arrow-button"></b-icon>
                </p>
            </b-row>


        </b-col>
    </b-container>

    <MenuItemModal ref="menuItemModal"
    :idx="modalIndex"
    :isMobile="isMobile"
    :isMedium="isMedium"
    :canPassLeftModal="canPassLeftModal"
    :canPassRightModal="canPassRightModal"
    :image="menuItem.imageUrl"
    :name="menuItem.name"
    :description="menuItem.description"
    :listTags="menuItem.listTags"
    :prices="menuItem.listPricesDto"
    :details="menuItem.details"
    @next="nextModal"
    @previous="previousModal"
    />

  </div>
</template>

<script>
import MenuItemModal from '@/components/shared/MenuItemModal.vue';

export default {
    name: "MostRequestSession",
    components:{
        MenuItemModal,
    },
    props: {
        modalProp: Object,
        didRequest: Boolean,
        loading: Boolean,
    },
    async created(){
        this.modal=this.modalProp;
        const intervalId = setInterval(()=>{
            if(this.didRequest){
                clearInterval(intervalId);
                this.modal=this.modalProp;
            }
        }, 250)
        await this.loadItems();
        setInterval(() => {
            this.verifyMobile(); 
            this.stepIndex = this.isMobile ? 2 : 4;
            this.stepMostRequestedList = this.mostRequestedList.slice(this.index, this.index + this.stepIndex);
        }, 0);
        setInterval(() => {
            this.verifyMedium(); 
        }, 0);
    },
    data(){
        return{
            isMobile: false,
            isMedium: false,
            canPassLeftModal: false,
            canPassRightModal: false,
            modalIndex: 0,
            menuItem: {},
            stepIndex: 2,
            isPassing: false,
            showSession: true,
            modal: null,
            index: 0,
            stepMostRequestedList: [],
            mostRequestedList: [],
        }
    },
    methods: {
        verifyMobile(){
            this.isMobile = window.innerWidth <= 1000;
            return this.isMobile;
        },
        verifyMedium(){
            this.isMedium = (window.innerWidth < 1200) && (window.innerWidth > 1000);
            return this.isMedium;
        },
        showModal(item){
            this.menuItem = item;
            this.modalIndex = this.mostRequestedList.findIndex(e=>e.id==item.id);
            this.verifyCanPassModal();
            this.$refs.menuItemModal.showModal();
        },
        hideModal(){
            this.$refs.menuItemModal.hideModal();
        },
        verifyCanPassModal(){
            var indexModal = this.mostRequestedList.indexOf(this.menuItem);
            if(indexModal + 1 >= this.mostRequestedList.length){
                this.canPassRightModal = false;
            }
            else this.canPassRightModal = true;
            
            if(indexModal - 1 < 0){
                this.canPassLeftModal = false;
            }
            else this.canPassLeftModal = true;
        },
        nextModal(){
            this.verifyCanPassModal();
            var indexModal = this.mostRequestedList.indexOf(this.menuItem);
            if(indexModal+1 >= this.mostRequestedList.length) return;
            else this.menuItem = this.mostRequestedList[indexModal+1];
            this.verifyCanPassModal();
        },
        previousModal(){
            this.verifyCanPassModal();
            var indexModal = this.mostRequestedList.indexOf(this.menuItem);
            if(indexModal-1 < 0) return;
            else this.menuItem = this.mostRequestedList[indexModal-1];
            this.verifyCanPassModal();
        },
        nextPage(){
            this.isPassing=true;
            setTimeout(() => {
                if(this.index+this.stepIndex >= this.mostRequestedList.length) 
                    this.index = 0;
                else this.index+=this.stepIndex;
                this.stepMostRequestedList = this.mostRequestedList.slice(this.index, this.index+this.stepIndex);
                
                this.isPassing=false;
            }, 250);
        },
        previousPage(){
            this.isPassing=true;
            setTimeout(() => {
                if(this.index-this.stepIndex < 0) 
                    this.index = this.mostRequestedList.length-this.stepIndex;
                else this.index-=this.stepIndex;
                this.stepMostRequestedList = this.mostRequestedList.slice(this.index, this.index+this.stepIndex);
                
                this.isPassing=false;
            }, 250);
        },
        async loadItems(){
            this.showSession=false;
            setTimeout(async () => {
                var localState = JSON.parse(localStorage.getItem('store'));
                // if(!localState){this.showSession = false; return;}
                await this.$axios.get("restaurant/" + localState.restaurant.id + "/bestSeller").then(
                    response => {
                        this.mostRequestedList = response.data.data;
                        if(this.mostRequestedList == null || this.mostRequestedList.length==0){
                            this.showSession = false;
                            this.$emit('hideBestSellers');
                        }else{
                            this.showSession = true;
                        }
                    }
                )
                .catch(() => {
                    this.showSession = false;
                })

            }, 250)
        }
    }
}
</script>

<style scoped>
.most-requested-enter-from{
  opacity: 0;
}
.most-requested-enter-active{
  transition: all .25s ease;
}
.most-requested-leave-to{
  opacity: 0;
}
.most-requested-leave-active{
  transition: all .25s ease-in-out;
}

.row{
    margin: 0px;
}
.col{
    width: 100vw;
    max-width: 100vw;
}
.most-requested-session{
    width: 100vw;
    /* height: 100vh; */
    display: flex;
    justify-content:center;
    align-items: center;
    color: #2E3132;
    padding: 50px;
    background-color: #EEEEEE;
}
.most-requested-session-mobile{
    width: 100vw;
    color: #2E3132;
    padding-top: 35px;
    background-color: #EEEEEE;
}
.container{
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.topic-title{
    margin: 0px;
    border-bottom: 3px solid #2E3132;
}
.title{
    margin: 5px 0px 0px 0px;
    width: 100vw;
    font-weight:400;
}

.btn-group-nav-pages{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90vw;
    /* height: 80vh; */
}
.btn-group-nav-pages-mobile{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90vw;
}

.items{
    text-align: start;
    width: 80vw;
    padding: 10px 10px;
    max-height: 550px;
    height: 40vw;
    /* height: 75vh; */
}
.items-mobile{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
    width: 70vw;
    padding: 10px 10px;
}

.item{ padding: 0px; }
.item-mobile{ width: 30vw; padding: 0px; }
.image{
    object-fit: cover;
    width: 18vw;
    max-height: 300px;
    height: 20vw;
    /* height: 40vh; */
    border-radius: 20px;
    box-shadow: 5px 5px 10px #CCCCCC;
    margin: 0px 0px 10px 0px;
}
.image-mobile{
    width: 30vw;
    height: 150px;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 5px 5px 10px #CCCCCC;
    margin: 0px 0px 10px 0px;
}
.item-title{
    font-weight: 500;
    width: 95%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
            line-clamp: 1; 
    -webkit-box-orient: vertical;
}
.item-subtitle{
    width: 90%;
    margin-bottom: 20px;
    font-weight: 400; letter-spacing: 0.25px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}
.item-subtitle-mobile{
    width: 30vw;
    font-weight: 400; letter-spacing: 0.25px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}
.item-price{
    font-weight: 600;
}
.card-item-request{
    cursor: pointer;
    transition: .1s;
}
.card-item-request:hover .item-title{
    text-decoration: underline;
}
</style>