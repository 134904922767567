<template>
    <b-col>
        <b-row v-if="!isMobile && !isMedium">
            <b-col class="image-col"><img class="image" :src="image" alt=""></b-col>
            <b-col class="item-col">
                <b-row class="item-title">{{title}}</b-row>
                <b-row class="item-subtitle">{{subtitle}}</b-row>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col class="item-service">
                <b-col class="image-col"><img class="image" :src="image" alt=""></b-col>
                <b-col class="item-col">
                    <b-row :class="!isMobile && !isMedium ? 'item-title' : 'item-title-mobile'">{{title}}</b-row>
                    <b-row :class="!isMobile && !isMedium ? 'item-subtitle' : 'item-subtitle-mobile'">{{subtitle}}</b-row>
                </b-col>
            </b-col>
        </b-row>
    </b-col>
</template>

<script>
export default {
    name: "ServiceItem",
    props: {
        image: String,
        title: String,
        subtitle: String,
    },
    data(){
        return {
            isMobile: false,
            isMedium: false,
        }
    },
    created(){
        setInterval(() => {
            this.verifyMobile(); 
        }, 0);
        setInterval(() => {
            this.verifyMedium(); 
        }, 0);
    },
    methods: {
        verifyMobile(){
            this.isMobile = window.innerWidth <= 1000;
            return this.isMobile;
        },
        verifyMedium(){
            this.isMedium = (window.innerWidth < 1400) && (window.innerWidth > 1000);
            return this.isMedium;
        },
    }
}
</script>

<style scoped>
.image-col{
    display: flex;
    justify-content: flex-end;
}
.item-col{
    text-align: start;
}
.image{
    /* width: 8vw; height: 17vh; */
    border: 1px solid #FFB000;
    border-radius: 100%;
    padding: 5px;
    box-shadow: 5px 5px 10px #450000;
    max-width: 162px;
    min-width: 162px;
    max-height: 162px;
    min-height: 162px;
    object-fit: cover;
}
.item-title{
    font-size: 30px;
    font-weight: 600;
}
.item-title-mobile{
    font-size: 20px;
    font-weight: 600;
}
.item-subtitle{
    font-size: 19px;
    width: 20vw;
    font-weight: 300;
}
.item-subtitle-mobile{
    font-size: 16px;
    width: 100%;
    font-weight: 300;
}
.item-service{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}
</style>