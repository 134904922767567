<template>
  <div :class="isMobile ? 'menu-session-mobile' : 'menu-session'" :style="'background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(\'' + modal.mainImage + '\') no-repeat; background-size: cover; background-position: center;'">

    <DefaultHeader/>
    <div v-if="!isMobile" :style="{height: isMobile ? '150px' : '200px'}"></div>
    
    <!-- <div v-if="!isMobile" style="height: 10vh"></div> -->

    <transition appear name="fade"
      enter-active-class="fade-enter-active"
      enter-class="fade-enter-from"
      enter-to-class="fade-enter-to"
    >
      <DefaultSession :style="!isMobile ? 'height:50vh' : ''" class="center-item" :topic_title="modalProp.sectionName" :title="modalProp.title">
        <h6 class="home-subtitle" v-if="isMobile"> {{ modalProp.subtitle }} </h6>
        <h4 class="home-subtitle" v-else-if="isMedium"> {{ modalProp.subtitle }} </h4>
        <h3 class="home-subtitle" v-else> {{ modalProp.subtitle }} </h3>
      </DefaultSession>
    </transition>
  </div>
</template>

<script>
import DefaultHeader from '@/components/shared/DefaultHeader.vue'
import DefaultSession from '@/components/shared/DefaultSession.vue'
export default {
    name: "MenuSession",
    components: {
        DefaultHeader,
        DefaultSession,
    },
    props: {
        modalProp: Object,
        didRequest: Boolean,
        loading: Boolean,
    },
    data(){
        return {
            isMobile: false,
            isMedium: false,
            modal: null,
        }
    },
    created(){
        this.modal=this.modalProp;
        const intervalId = setInterval(()=>{
            if(this.didRequest){
                clearInterval(intervalId);
                this.modal=this.modalProp;
            }
        }, 250)
        setInterval(() => {
            this.verifyMobile(); 
        }, 0);
        setInterval(() => {
            this.verifyMedium(); 
        }, 0);
    },
    methods: {
        verifyMobile(){
            this.isMobile = window.innerWidth <= 1000;
            return this.isMobile;
        },
        verifyMedium(){
            this.isMedium = (window.innerWidth < 1400) && (window.innerWidth > 1000);
            return this.isMedium;
        },
    }
}
</script>

<style scoped>
.menu-session{
    color: white;   
    width: 100vw; 
    /* height: 80vh; */
    min-height: 600px;
    background-image: url(@/assets/images/menu/menu_background.png);
    background-size: cover;
    background-position: center;
}
.menu-session-mobile{
    color: white;
    width: 100vw; 
    min-height: 300px;
    background-image: url(@/assets/images/menu/menu_background.png);
    background-size: cover;
    background-position: center;
}
.center-item{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>