<template>
    <div class="about">

        <MaintenanceModal ref="maintenance"/>

        <LoadingView v-show="loadingState"/>

        <AboutSession v-if="showFirst" :modalProp="firstSection" :loading="loading" :didRequest="didRequest" />

        <AboutUsSession v-if="secondSection && secondSection.active" :modalProp="secondSection" :loading="loading" :didRequest="didRequest" />

        <ServicesSession v-if="thirdSection && thirdSection.active" :modalProp="thirdSection" :loading="loading" :didRequest="didRequest" />

        <!-- IRÁ USAR FUTURAMENTE -->
        <!-- <ValuesSession/> -->

        <DefaultFooter />

    </div>
</template>

<script>
import AboutSession from './sessions/AboutSession.vue'
import AboutUsSession from './sessions/AboutUsSession.vue'
import ServicesSession from './sessions/ServicesSession.vue'
// import ValuesSession from './sessions/ValuesSession.vue'
import DefaultFooter from '@/components/shared/DefaultFooter.vue'
import LoadingView from '@/components/shared/LoadingView.vue'
import MaintenanceModal from '@/components/shared/MaintenanceModal.vue'
import { mapState } from 'vuex'

export default {
    name: "AboutView",
    components: {
        AboutSession,
        AboutUsSession,
        ServicesSession,
        // ValuesSession,
        DefaultFooter,
        LoadingView,
        MaintenanceModal,
    },
    async mounted(){
        try{
            this.$store.commit("loading/STORE_LOADING");
            window.scrollTop=0;
            document.body.style.overflow = 'hidden'
            await this.getMainSection();
            await this.getSection();
            this.verifyMaintenance();
            this.showFirst=true;
            setTimeout(()=>{
                document.body.style.overflow = ''
                this.$store.commit("loading/STORE_LOADED");
            }, 500)
        }
        catch(error){
            this.$refs.maintenance.show();
            error;
        }
    },
    computed: {
        ...mapState("loading", {
            loadingState: (state) => state.loading
        }),
    },
    data(){
        return {
            showFirst: false,
            sectionId: null,
            modalSections: null,
            firstSection: {
                mainImage: '',
                sectionName: '',
                title: '',
                subtitle: '',
                active: false,
            },
            secondSection: {
                mainImage: '',
                sectionName: '',
                title: '',
                subtitle: '',
                active: false,
            },
            thirdSection: {
                mainImage: '',
                sectionName: '',
                title: '',
                subtitle: '',
                active: false,
            },
            loading: false,
            didRequest: false,
        }
    },
    methods: {
        verifyMaintenance(){
            if(
                !this.firstSection
            ) throw 'First section not found';
        },
        async getMainSection(){
            this.loading=true;
            await this.$axios.get("main_sections")
            .then(response => {
                this.sectionId = response.data.data.find(e=>e.name=="Sobre nós").id;
            }).finally(()=>{
                this.loading=false;
            })
        },
        async getSection(){
            await this.$axios.get("main_section/" + this.sectionId + "/sub_section")
            .then(async response => {
                this.modalSections = response.data.data;
                this.firstSection = this.modalSections.find(e=>e.type=="HEADER");
                this.secondSection = this.modalSections.find(e=>e.type=="BODY" && e.position==0);
                this.thirdSection = this.modalSections.find(e=>e.type=="BODY" && e.position==1);

                if(this.thirdSection){
                    await this.$axios.get("main_section/sub_section/" + this.thirdSection.id + "/images")
                    .then(response => {
                        this.thirdSection.listImages = response.data.data;
                        this.thirdSection.listImages.sort((a, b) => {
                            b;
                            if((a.title==null || a.title=='') && (a.subtitle==null || a.subtitle==''))
                                return 1;
                            else return -1;
                        });
                    })
                }
            }).finally(()=>{
                this.loading=false;
                this.didRequest=true;
            });
        },
    },
}
</script>

<style scoped>

</style>