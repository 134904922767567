<template>
  <div>
    <div class="home">

      <MaintenanceModal ref="maintenance"/>

      <LoadingView v-show="loadingState"/>
      
      <HomeSession v-if="firstSection && !firstSection.length==0 && showFirst" :goTo="goTo" :modalProp="firstSection" :loading="loading" :didRequest="didRequest"/>

      <DiscountSession v-if="secondSection && !secondSection.length==0" :modalProp="secondSection" :loading="loading" :didRequest="didRequestPromotions"/>

      <MostRequestedSession v-if="thirdSection && thirdSection.active" @hideBestSellers="hideBestSellers" :modalProp="thirdSection" :loading="loading" :didRequest="didRequest"/>

      <AboutUsSession v-if="fourthSection && fourthSection.active" :modalProp="fourthSection" :loading="loading" :didRequest="didRequest"/>

      <RestaurantSession v-if="fifthSection && fifthSection.active" :modalProp="fifthSection" :loading="loading" :didRequest="didRequest"/>

      <DefaultFooter/>

    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import HomeSession from '@/views/home/sessions/HomeSession.vue'
import DiscountSession from './sessions/DiscountSession.vue'
import MostRequestedSession from './sessions/MostRequestedSession.vue'
import AboutUsSession from './sessions/AboutUsSession.vue'
import RestaurantSession from './sessions/RestaurantSession.vue'
import DefaultFooter from '@/components/shared/DefaultFooter.vue'
import LoadingView from '@/components/shared/LoadingView.vue'
import MaintenanceModal from '@/components/shared/MaintenanceModal.vue'
import { mapState } from 'vuex'

export default {
  name: 'HomeView',
  components: {
    HomeSession,
    DiscountSession,
    MostRequestedSession,
    AboutUsSession,
    RestaurantSession,
    DefaultFooter,
    LoadingView,
    MaintenanceModal,
  },
  async mounted(){
    try{
      this.$store.commit("loading/STORE_LOADING");
      window.scrollTop=0;
      document.body.style.overflow = 'hidden'
      await this.loadRestaurants();
      await this.getMainSection();
      await this.getSection();
      const promotionsRequest = await this.getPromotions(); promotionsRequest;
      this.verifyMaintenance();
      this.getGoTo();
      this.showFirst=true;
      setTimeout(()=>{
        document.body.style.overflow = ''
        this.$store.commit("loading/STORE_LOADED");
      }, 500)
    }
    catch(e){
      this.$refs.maintenance.show();
    }
  },
  computed: {
    ...mapState("loading", {
            loadingState: (state) => state.loading
        }),
  },
  data(){
      return {
          goTo: "#",
          showFirst: false,
          sectionId: null,
          modalSections: null,
          firstSection: [{
            mainImage: '',
            sectionName: '',
            title: '',
            subtitle: '',
            active: false,
          }],
          secondSection: [{
            mainImage: '',
            sectionName: '',
            title: '',
            subtitle: '',
            active: false,
          }],
          thirdSection: {
            mainImage: '',
            sectionName: '',
            title: '',
            subtitle: '',
            active: false,
          },
          fourthSection: {
            mainImage: '',
            sectionName: '',
            title: '',
            subtitle: '',
            active: false,
          },
          fifthSection: {
            mainImage: '',
            sectionName: '',
            title: '',
            subtitle: '',
            active: false,
          },
          loading: false,
          didRequest: false,
          didRequestPromotions: false,
      }
  },
  methods: {
      verifyMaintenance(){
        if(
          !this.firstSection
        ) throw 'First section not found';
      },
      async loadRestaurants() {
        await this.$axios.get('/restaurant/detailed').then((response) => {
          this.restaurants = response.data.data;
          this.restaurants.sort((a, b) => a.name.localeCompare(b.name));
          this.setDefaultRestaurant();
        });
      },
      setDefaultRestaurant(){
        if(!this.restaurants) return;
        var localState = JSON.parse(localStorage.getItem('store'));
        var finded = null;
        if(localState==null || localState==undefined || localState.restaurant==null || localState.restaurant==undefined || !localState.hasSelected){
          // if(!this.hasSelectedState)
          finded = this.restaurants.find(e => e.name.toLowerCase().includes("tiradentes"));
          if(!finded)
            finded = this.restaurants[0];
          this.$store.commit("restaurant/DEFAULT_STORE_RESTAURANT", finded);
        }
        else{
          finded = this.restaurants.find(e => e.id == localState.restaurant.id);
          if(!finded){
            finded = this.restaurants.find(e => e.name.toLowerCase().includes("tiradentes"));
            if(!finded)
              finded = this.restaurants[0];
            this.$store.commit("restaurant/DEFAULT_STORE_RESTAURANT", finded);
            return;
          }
          this.$store.commit("restaurant/STORE_RESTAURANT", finded);
        }
      },
      hideBestSellers(){ this.thirdSection.active=false; },
      async getMainSection(){
          this.loading=true;
          await this.$axios.get("main_sections")
          .then(response => {
              this.sectionId = response.data.data.find(e=>e.name=="Início").id;
          }).finally(()=>{
              this.loading=false;
          })
      },
      getGoTo(){
        if(this.secondSection && !this.secondSection.length==0) this.goTo = "#discount-session"
        else if(this.thirdSection && this.thirdSection.active) this.goTo = "#most-requested"
        else if(this.fourthSection && this.fourthSection.active) this.goTo = "#restaurant-home-session"
        else if(this.fifthSection && this.fifthSection.active) this.goTo = "#about-us-home-session"
        else this.goTo = "#footer"
      },
      async getSection(){
          await this.$axios.get("main_section/" + this.sectionId + "/sub_section")
          .then(async response => {
              this.modalSections = response.data.data;
              this.firstSection = this.modalSections.filter(e=>e.type=="HEADER");
              this.firstSection.sort((a,b)=>{
                return a.position - b.position;
              })
              this.secondSection = this.modalSections.filter(e=>e.type=="BODY" && e.position==0 && e.active);
              this.thirdSection = this.modalSections.find(e=>e.type=="BODY" && e.position==1);
              this.fourthSection = this.modalSections.find(e=>e.type=="BODY" && e.position==2);
              this.fifthSection = this.modalSections.find(e=>e.type=="BODY" && e.position==3);
          }).finally(()=>{
              this.loading=false;
              this.didRequest=true;
          });
      },
      async getPromotions(){
        var localState = JSON.parse(localStorage.getItem('store'));
        await this.$axios.get("restaurant/" + localState.restaurant.id + "/promotion")
        .then(response =>{
          this.secondSection = this.secondSection.map(e=>{e.promotion = []; return e;})
          response.data.forEach(promo=>{
            var finded = this.secondSection.find(e=>e.id == promo.subSectionId)
            if(finded){
              finded.promotion.push(promo);
            }
          })
          var prepared = [];
          this.secondSection.forEach(e=>{
            if(e.id!=null && e.id!=undefined && e.promotion.length>0){
              e.promotion.sort((a,b)=>a.finalValue-b.finalValue)
              prepared.push(e);
            }
          })
          this.secondSection = prepared;
          // if(prepared.length==this.secondSection.length)
          //   this.didRequestPromotions=true;
        })
        .finally(()=> this.didRequestPromotions=true)
      }
      // async getPromotions(){
      //   var localState = JSON.parse(localStorage.getItem('store'));
      //   await this.$axios.get("restaurant/" + localState.restaurant.id + "/promotion")
      //   .then(response =>{
      //     response.data.forEach(promo=>{
      //       var finded = this.secondSection.find(e=>e.id == promo.subSectionId)
      //       if(finded!=null && finded!=undefined){
      //         finded.promotion = promo;
      //       }
      //     })
      //     var prepared = [];
      //     this.secondSection.forEach(e=>{
      //       if(e.id!=null && e.id!=undefined && e.promotion!=undefined && e.promotion!=null)
      //         prepared.push(e);
      //     })
      //     this.secondSection = prepared;
      //     // if(prepared.length==this.secondSection.length)
      //     //   this.didRequestPromotions=true;
      //   })
      //   .finally(()=> this.didRequestPromotions=true)
      // }
  },
}
</script>

<style>
.home{color: white;}
</style>