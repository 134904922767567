<template>
    <div class="about">

        <MaintenanceModal ref="maintenance"/>
        
        <LoadingView v-show="loadingState"/>

        <ContactSession v-if="firstSection && firstSection.active && showFirst" :modalProp="firstSection" :loading="loading" :didRequest="didRequest" />

        <MapSession v-if="showFirst"/>

        <FormSession v-if="thirdSection && thirdSection.active" :modalProp="thirdSection" :loading="loading" :didRequest="didRequest" />

        <DefaultFooter/>

    </div>
</template>

<script>
import ContactSession from './sessions/ContactSession.vue'
import MapSession from './sessions/MapSession.vue'
import FormSession from './sessions/FormSession.vue'
import DefaultFooter from '@/components/shared/DefaultFooter.vue'
import LoadingView from '@/components/shared/LoadingView.vue'
import MaintenanceModal from '@/components/shared/MaintenanceModal.vue'
import { mapState } from 'vuex'

export default {
    name: "ContactView",
    components: {
        ContactSession,
        MapSession,
        FormSession,
        DefaultFooter,
        LoadingView,
        MaintenanceModal,
    },
    computed: {
        ...mapState("loading", {
            loadingState: (state) => state.loading
        }),
    },
    async mounted(){
        try{
            this.$store.commit("loading/STORE_LOADING");
            window.scrollTop=0;
            document.body.style.overflow = 'hidden'
            await this.getMainSection();
            await this.getSection();
            this.verifyMaintenance();
            setTimeout(()=>{
                this.showFirst=true;
            }, 250)
            setTimeout(()=>{
                document.body.style.overflow = '';
                this.$store.commit("loading/STORE_LOADED");
            }, 1000)
        }
        catch(error){
            this.$refs.maintenance.show();
            error;
        }
    },
    data(){
        return {
            showFirst: false,
            sectionId: null,
            modalSections: null,
            firstSection: {
                mainImage: '',
                sectionName: '',
                title: '',
                subtitle: '',
                active: false,
            },
            thirdSection: {
                mainImage: '',
                sectionName: '',
                title: '',
                subtitle: '',
                active: false,
            },
            loading: false,
            didRequest: false,
        }
    },
    methods: {
        verifyMaintenance(){
            if(
                !this.firstSection || !this.firstSection.active
            ) throw 'First section not found';
        },
        async getMainSection(){
            this.loading=true;
            await this.$axios.get("main_sections")
            .then(response => {
                this.sectionId = response.data.data.find(e=>e.name=="Contato").id;
            }).finally(()=>{
                this.loading=false;
            })
        },
        async getSection(){
            await this.$axios.get("main_section/" + this.sectionId + "/sub_section")
            .then(response => {
                this.modalSections = response.data.data;
                this.firstSection = this.modalSections.find(e=>e.type=="HEADER");
                this.thirdSection = this.modalSections.find(e=>e.type=="BODY");
            }).finally(()=>{
                this.loading=false;
                this.didRequest=true;
            });
        },
    },
}
</script>

<style scoped>

</style>