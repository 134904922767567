import Vue from 'vue';

Vue.filter('formatPhoneNumber', function (phoneNumber) {
  let cleaned = phoneNumber.replace(/\D/g, '');

  let regex = /^(\d{2})(\d{4,5})(\d{4})$/;
  let match = cleaned.match(regex);

  if (match) {
    if (match[2].length === 4) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    } else {
      return `(${match[1]}) ${match[2].substring(0, match[2].length - 4)}${match[2].substring(match[2].length - 4)}-${match[3]}`;
    }
  } else {
    return phoneNumber;
  }
});

Vue.filter('formatZipCode', function (zipCode) {
    if(zipCode == null || zipCode == "")
        return zipCode;

  let cleaned = zipCode.replace(/\D/g, '');
  let regex = /^(\d{5})(\d{3})$/;
  let match = cleaned.match(regex);

  if (match) {
    return `${match[1]}-${match[2]}`;
  } else {
    return zipCode;
  }
});

Vue.filter('formatDaysWeek', function (daysWeek) {
    if(daysWeek == null || daysWeek == "")
      return daysWeek;

  // Sunday Monday Tuesday Wednesday Thursday Friday Saturday
  
  switch (daysWeek) {
    case "SUNDAY":
      return "Domingo";
    case "MONDAY":
      return "Segunda";
    case "TUESDAY":
      return "Terça";
    case "WEDNESDAY":
      return "Quarta";
    case "THURSDAY":
      return "Quinta";
    case "FRIDAY":
      return "Sexta";
    case "SATURDAY":
      return "Sábado";
  }
});

Vue.filter('formatDateLog', function (value) {
    if (!value) return ''
    
    const date = new Date(value)
    
    const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
    
    const day = date.getDate()
    const month = months[date.getMonth()]
    const year = date.getFullYear()
    
    const hours = date.getHours() - 3
    const minutes = date.getMinutes()
    
    const formattedDate = `${day}, ${month} ${year} - ${hours}:${minutes < 10 ? '0' + minutes : minutes}hrs`
    
    return formattedDate
    });
    